import { loadCountNewCandateInterviews } from '@/client/redux/actions';
import { userOnboardingFinishedVerifiedSelector, userSelector } from '@/client/redux/selectors';
import { UserRole } from '@/enums';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useMainLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isOnboardingFinished = useSelector(userOnboardingFinishedVerifiedSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    if (isOnboardingFinished && user?.role === UserRole.Candidate) {
      dispatch(loadCountNewCandateInterviews());
    }
  }, [dispatch, isOnboardingFinished, router.pathname, user?.role]);
};

export default useMainLayout;
