import { all, call, put, takeLatest, select, delay, take } from 'redux-saga/effects';
import oAuth from '@DevimaSolutions/o-auth';
import Router from 'next/router';

import {
  IActionWithPayload,
  ILoadCitiesData,
  ILoadRegionsData,
  IPostJobData,
  IPostJobResponse,
  IPostJobResult,
  ISavePostJobData,
  IUpdateJobData,
  ILoadJobData,
  ILoadAdminPostJobData,
} from '@/client/redux/types';
import { ICityBase, IRegion } from '@/client/types';
import { locationsService, jobsService } from '@/client/services';
import {
  storePostJobRegions,
  storePostJobCities,
  clearPostJobData,
  storePostJobFetching,
  storePostJobData,
  storePostLoading,
} from '@/client/redux/store/post-job.slice';
import { handleRequestError, parseJobResponse } from '@/client/utils';
import {
  getCompanyProfileData,
  getCompanyProfileDataFinished,
  getEmployerProfileData,
  loadAdminPostJobData,
  loadIndustryExperience,
  loadJobData,
  loadJobFunctions,
  loadPostJobCities,
  loadPostJobData,
  loadPostJobRegions,
  postJob,
  savePostJobData,
  updateJobData,
} from '@/client/redux/actions';
import { industryExperienceSelector, postJobDataSelector } from '@/client/redux/selectors';
import { IJobDetailsDto } from '@/DTO';
import { IIndustryExperienceAttributes } from '@/server/database';

function* loadRegionsSaga(action: IActionWithPayload<ILoadRegionsData>) {
  try {
    const regions: IRegion[] = yield call(
      locationsService.loadRegions,
      action.payload.countryCode,
      action.payload.input,
    );

    yield put(storePostJobRegions(regions));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadCitiesSaga(action: IActionWithPayload<ILoadCitiesData>) {
  try {
    const cities: ICityBase[] = yield call(
      locationsService.loadCities,
      action.payload.countryCode,
      action.payload.regionCode,
      action.payload.input,
    );

    yield put(storePostJobCities(cities));
  } catch (error) {
    handleRequestError(error);
  }
}

function* savePostJobDataSaga({ payload }: IActionWithPayload<ISavePostJobData>) {
  yield put(storePostLoading(true));
  try {
    if (!payload.isDraft && payload.jobId) {
      yield call(jobsService.publishJob, payload.jobId);
    }

    oAuth().updateUser({
      hasPostedJob: true,
    });

    if (payload.isDraft) {
      yield delay(0);
      Router.push('/my-jobs');
    }

    payload?.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storePostLoading(false));
  }
}

function* loadPostJobDataSaga() {
  yield put(storePostJobFetching(true));
  try {
    yield all([
      put(getCompanyProfileData()),
      put(loadJobFunctions()),
      put(loadIndustryExperience()),
    ]);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storePostJobFetching(false));
  }
}

function* loadAdminPostJobDataSaga({ payload }: IActionWithPayload<ILoadAdminPostJobData>) {
  yield put(storePostJobFetching(true));
  try {
    yield all([
      put(getCompanyProfileData(payload.companyId)),
      put(loadJobFunctions()),
      put(loadIndustryExperience()),
    ]);

    yield take(getCompanyProfileDataFinished);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storePostJobFetching(false));
  }
}

function* loadJobDataSaga({ payload }: IActionWithPayload<ILoadJobData>) {
  yield put(storePostJobFetching(true));
  try {
    yield all(
      payload.notLoadProfileData
        ? [put(loadJobFunctions()), put(loadIndustryExperience())]
        : [put(getEmployerProfileData()), put(loadJobFunctions()), put(loadIndustryExperience())],
    );
    const data: IJobDetailsDto = yield call(jobsService.getJob, payload.jobId);

    const industryExperiences: IIndustryExperienceAttributes[] = yield select(
      industryExperienceSelector,
    );
    yield put(storePostJobData(parseJobResponse(data, industryExperiences)));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storePostJobFetching(false));
  }
}

function* updatePostJobDataSaga({ payload }: IActionWithPayload<IUpdateJobData>) {
  yield put(storePostJobFetching(true));

  try {
    if (!payload.isDraft && payload.id) {
      const data: IPostJobResult = yield select(postJobDataSelector);
      yield call(jobsService.updateData, {
        id: payload.id,
        data,
        isDraft: payload.isDraft,
        employerUserId: payload.employerUserId,
      });
    }

    yield put(clearPostJobData());
    payload?.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storePostJobFetching(false));
  }
}

/**
 * Update post job data saga
 */
function* postJobSaga({ payload }: IActionWithPayload<IPostJobData>) {
  yield put(storePostLoading(true));
  try {
    const result: IPostJobResponse = yield call(jobsService.postJobData, payload);
    yield put(storePostJobData(payload.storeData));

    oAuth().updateUser({
      hasPostedJob: true,
    });

    if (!Router.query.jobId) {
      yield Router.push({
        query: { ...Router.query, jobId: result.jobId },
      });
    }

    payload.handleNext?.(result.jobId);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storePostLoading(false));
  }
}

export default function* postJobRootSaga() {
  yield all([
    takeLatest(savePostJobData.type, savePostJobDataSaga),
    takeLatest(loadPostJobRegions.type, loadRegionsSaga),
    takeLatest(loadPostJobCities.type, loadCitiesSaga),
    takeLatest(loadPostJobData.type, loadPostJobDataSaga),
    takeLatest(loadAdminPostJobData.type, loadAdminPostJobDataSaga),
    takeLatest(loadJobData.type, loadJobDataSaga),
    takeLatest(updateJobData.type, updatePostJobDataSaga),
    takeLatest(postJob.type, postJobSaga),
  ]);
}
