import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '@/client/redux/selectors';
import oAuth from '@DevimaSolutions/o-auth';

const useMobileMenu = () => {
  const user = useSelector(userSelector);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const showDrawerHandler = useCallback(
    (open?: boolean) => () =>
      open === undefined ? setIsDrawerOpen((isOpen) => !isOpen) : setIsDrawerOpen(open),
    [],
  );

  const logoutHandler = useCallback(() => {
    setIsDrawerOpen(false);
    oAuth().signOut();
  }, []);

  return {
    isDrawerOpen,
    userName: user ? `${user.firstName} ${user.lastName}` : '',
    showDrawerHandler,
    logoutHandler,
  };
};

export default useMobileMenu;
