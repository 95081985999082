import { createSelector } from 'reselect';
import { IState } from '@/client/redux/types';
import { IBrowseCandidateQuery } from '@/requests';
import { IBaseCandidateInfo } from '@/client/components/Modals/RequestInterviewModal/types';

export const browseCandidateSelector = ({ browseCandidate }: IState) => browseCandidate;

export const browseCandidateListSelector = createSelector(
  browseCandidateSelector,
  ({ candidates, isLoading, hasMore }) => ({
    candidates,
    isLoading,
    hasMore,
  }),
);

export const browseCandidateListQuerySelector = createSelector(
  browseCandidateSelector,
  ({ page, perPage, filters }) => ({ page, perPage, ...filters } as IBrowseCandidateQuery),
);

export const browseCandidateFiltersSelector = createSelector(
  browseCandidateSelector,
  ({ filters }) => filters,
);

export const browseCandidateByIdSelector = (candidateId: string | null) =>
  createSelector(
    browseCandidateSelector,
    ({ candidates }) => candidates.find(({ id }) => id === candidateId) ?? null,
  );

export const detailedCandidateSelector = createSelector(
  browseCandidateSelector,
  ({ detailedCandidate, isLoadingCandidateDetail }) => ({
    detailedCandidate,
    isLoadingCandidateDetail,
  }),
);

export const detailedCandidateBaseINfoForModal = (candidateId: string | null) =>
  createSelector(detailedCandidateSelector, ({ detailedCandidate }): IBaseCandidateInfo | null => {
    if (!detailedCandidate || !candidateId) {
      return null;
    }
    const [lastEducation] = detailedCandidate.educations.slice(-1);

    return {
      id: candidateId,
      education: lastEducation,
      imageUri: detailedCandidate.baseInfo.imageUri,
      name: detailedCandidate.baseInfo.name,
    };
  });
