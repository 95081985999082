import {
  IAdminJobQuery,
  IAdminEmployerQuery,
  IAdminImportQuery,
  IAdminEmailTemplatesQuery,
  IAdminCompanyQuery,
  IMatchDataQuery,
} from '@/requests';
import { createAction } from '@reduxjs/toolkit';
import {
  IAdminAssignToJob,
  IAdminUpdateCompanyTermsUriPayload,
  IAdminUpdateCompanyTermsSignedPayload,
  IAdminUpdateMatchStatusPayload,
  IDeleteEntity,
  IImportData,
  IProcessEntity,
  IUpdateIsVettedStatus,
  IUpdateJobStatus,
  IUpdateOpenToOffersStatus,
  IUpdatePriority,
  IUpdateUserStatus,
  IAdminEmailTemplateUpdate,
  IAdminCandidateLoadMatchesProps,
  IAdminUpdateHubspotId,
  IAdminInviteEmployer,
  IAdminAddNewMatch,
  IUpdateReservedStatus,
  IAdminUpdateMatchStagePayload,
  ILoadInterviewDataAdminPayload,
  IAdminCreateInterviewer,
  IAdminRejectMatchPayload,
  ICreateJobMatchNoteProps,
  IUpdateEmployerFeedbackProps,
  IAdminJobLoadMatchesProps,
  IDeleteJobMatchNoteProps,
  IUpdateInterviewStatusPayload,
  IUpdateAdminMatchMessageProps,
  IAdminAssignToMatchProps,
  IUpdateJobMatchNoteProps,
  IUpdateInterviewDatePayload,
  IUpdateMatchNoteClientVisibilityProps,
  IUpdateInterviewLinkPayload,
  IUpdateScheduleLinkPayload,
  ILoadJobMatchProps,
  IDeleteJobMatchNoteAttachmentProps,
  IUpdateNoteAttachmentsProps,
  ICreateCandidateProps,
  IAdminUpdateRejectedMatchPayload,
  ICreateAdminProps,
} from '../types';

const LOAD_ADMIN_CANDIDATE_MATCHES = 'ADMIN/LOAD_ADMIN_CANDIDATE_MATCHES';
const LOAD_ADMIN_JOB_MATCHES = 'ADMIN/LOAD_ADMIN_JOB_MATCHES';
const LOAD_JOBS = 'ADMIN/LOAD_JOBS';
const LOAD_EMPLOYERS = 'ADMIN/LOAD_EMPLOYERS';
const LOAD_COMPANIES = 'ADMIN/LOAD_COMPANIES';
const LOAD_ADMINS = 'ADMIN/LOAD_ADMINS';
const ASSIGN_ADMIN_TO_JOB = 'ADMIN/ASSIGN_ADMIN_TO_JOB';
const SET_JOB_IS_VETTED_STATUS = 'ADMIN/SET_JOB_IS_VETTED_STATUS';
const SET_JOB_PRIORITY = 'ADMIN/SET_JOB_PRIORITY';
const SET_JOB_STATUS = 'ADMIN/SET_JOB_STATUS';
const SET_CANDIDATE_OPEN_TO_OFFERS = 'ADMIN/SET_CANDIDATE_OPEN_TO_OFFERS';
const SET_CANDIDATE_IS_VETTED_STATUS = 'ADMIN/SET_CANDIDATE_IS_VETTED_STATUS';
const SET_USER_STATUS = 'ADMIN/SET_USER_STATUS';
const SET_MATCH_STATUS = 'ADMIN/SET_MATCH_STATUS';
const IMPORT_DATA = 'ADMIN/IMPORT_DATA';
const CONFIRM_EMAIL = 'ADMIN/CONFIRM_EMAIL';
const DELETE = 'ADMIN/DELETE';
const LOAD_IMPORTS = 'ADMIN/LOAD_IMPORTS';
const UPDATE_EMPLOYER_TERMS_URI = 'ADMIN/UPDATE_EMPLOYER_TERMS_URI';
const UPDATE_EMPLOYER_TERMS_SIGNED = 'ADMIN/UPDATE_EMPLOYER_TERMS_SIGNED';
const LOAD_EMAIL_TEMPLATES = 'ADMIN/LOAD_EMAIL_TEMPLATES';
const SET_HUBSPOT_EMAIL_TEMPLATE = 'ADMIN/SET_HUBSPOT_EMAIL_TEMPLATE';
const UPDATE_EMPLOYER_HUBSPOT_ID = 'ADMIN/UPDATE_EMPLOYER_HUBSPOT_ID';
const UPDATE_CANDIDATE_HUBSPOT_ID = 'ADMIN/UPDATE_CANDIDATE_HUBSPOT_ID';
const LOAD_NEXT_ADMIN_BROWSE_CANDIDATES = 'ADMIN/LOAD_NEXT_ADMIN_BROWSE_CANDIDATES';
const INVITE_EMPLOYER = 'ADMIN/INVITE_EMPLOYER';
const ADD_NEW_MATCH = 'ADMIN/ADD_NEW_MATCH';
const UPDATE_CANDIDATE_RESERVED = 'ADMIN/UPDATE_CANDIDATE_RESERVED';
const UPDATE_MATCH_STAGE = 'ADMIN/UPDATE_MATCH_STAGE';
const LOAD_ADMIN_INTERVIEW_DATA = 'ADMIN/LOAD_ADMIN_INTERVIEW_DATA';
const CREATE_INTERVIEWER = 'ADMIN/CREATE_INTERVIEWER';
const REJECT_MATCH = 'ADMIN/REJECT_MATCH';
const CREATE_JOB_MATCH_NOTE = 'ADMIN/CREATE_JOB_MATCH_NOTE';
const UPDATE_JOB_MATCH_NOTE = 'ADMIN/UPDATE_JOB_MATCH_NOTE';
const DELETE_JOB_MATCH_NOTE = 'ADMIN/DELETE_JOB_MATCH_NOTE';
const EDIT_JOB_MATCH_FEEDBACK = 'ADMIN/EDIT_JOB_MATCH_FEEDBACK';
const UPDATE_INTERVIEW_STATUS = 'ADMIN/UPDATE_INTERVIEW_STATUS';
const UPDATE_INTERVIEW_DATE = 'ADMIN/UPDATE_INTERVIEW_DATE';
const UPDATE_ADMIN_MATCH_MESSAGE = 'ADMIN/UPDATE_ADMIN_MATCH_MESSAGE';
const ASSIGN_ADMIN_TO_MATCH = 'ADMIN/ASSIGN_ADMIN_TO_MATCH';
const CLIENT_VISIBILITY_JOB_MATCH_NOTE = 'ADMIN/CLIENT_VISIBILITY_JOB_MATCH_NOTE';
const LOAD_MATCH_NOTES = 'ADMIN/LOAD_MATCH_NOTES';
const UPDATE_INTERVIEW_LINK = 'ADMIN/UPDATE_INTERVIEW_LINK';
const UPDATE_SCHEDULE_LINK = 'ADMIN/UPDATE_SCHEDULE_LINK';
const LOAD_JOB_MATCH = 'ADMIN/LOAD_JOB_MATCH';
const DELETE_JOB_MATCH_NOTE_ATTACHMENT = 'ADMIN/DELETE_JOB_MATCH_NOTE_ATTACHMENT';
const UPDATE_JOB_MATCH_NOTE_ATTACHMENTS = 'ADMIN/UPDATE_JOB_MATCH_NOTE_ATTACHMENTS';
const CREATE_CANDIDATE = 'ADMIN/CREATE_CANDIDATE';
const UPDATE_REJECTED_MATCH = 'ADMIN/UPDATE_REJECTED_MATCH';
const CREATE_ADMIN = 'ADMIN/CREATE_ADMIN';

export const loadNextAdminBrowseCandidates = createAction(LOAD_NEXT_ADMIN_BROWSE_CANDIDATES);

export const loadAdminCandidateMatches = createAction<
  IAdminCandidateLoadMatchesProps,
  typeof LOAD_ADMIN_CANDIDATE_MATCHES
>(LOAD_ADMIN_CANDIDATE_MATCHES);

export const loadAdminJobMatches = createAction<
  IAdminJobLoadMatchesProps,
  typeof LOAD_ADMIN_JOB_MATCHES
>(LOAD_ADMIN_JOB_MATCHES);

export const loadJobs = createAction<IAdminJobQuery, typeof LOAD_JOBS>(LOAD_JOBS);
export const loadEmployers = createAction<IAdminEmployerQuery, typeof LOAD_EMPLOYERS>(
  LOAD_EMPLOYERS,
);

export const loadCompanies = createAction<IAdminCompanyQuery, typeof LOAD_COMPANIES>(
  LOAD_COMPANIES,
);

export const loadAdmins = createAction(LOAD_ADMINS);

export const assignAdminToJob = createAction<IAdminAssignToJob, typeof ASSIGN_ADMIN_TO_JOB>(
  ASSIGN_ADMIN_TO_JOB,
);

export const setJobIsVettedStatus = createAction<
  IUpdateIsVettedStatus,
  typeof SET_JOB_IS_VETTED_STATUS
>(SET_JOB_IS_VETTED_STATUS);

export const setJobPriority = createAction<IUpdatePriority, typeof SET_JOB_PRIORITY>(
  SET_JOB_PRIORITY,
);

export const setJobStatus = createAction<IUpdateJobStatus, typeof SET_JOB_STATUS>(SET_JOB_STATUS);

export const setCandidateIsVettedStatus = createAction<
  IUpdateIsVettedStatus,
  typeof SET_CANDIDATE_IS_VETTED_STATUS
>(SET_CANDIDATE_IS_VETTED_STATUS);

export const setCandidateOpenToOffersStatus = createAction<
  IUpdateOpenToOffersStatus,
  typeof SET_CANDIDATE_OPEN_TO_OFFERS
>(SET_CANDIDATE_OPEN_TO_OFFERS);

export const setUserStatus = createAction<IUpdateUserStatus, typeof SET_USER_STATUS>(
  SET_USER_STATUS,
);

export const setMatchStatus = createAction<IAdminUpdateMatchStatusPayload, typeof SET_MATCH_STATUS>(
  SET_MATCH_STATUS,
);

export const importData = createAction<IImportData, typeof IMPORT_DATA>(IMPORT_DATA);

export const sendConfirmationEmail = createAction<IProcessEntity, typeof CONFIRM_EMAIL>(
  CONFIRM_EMAIL,
);

export const deleteEntity = createAction<IDeleteEntity, typeof DELETE>(DELETE);

export const loadImports = createAction<IAdminImportQuery, typeof LOAD_IMPORTS>(LOAD_IMPORTS);

export const adminUpdateMatchStage = createAction<
  IAdminUpdateMatchStagePayload,
  typeof UPDATE_MATCH_STAGE
>(UPDATE_MATCH_STAGE);

export const updateCompanyTermsUri = createAction<
  IAdminUpdateCompanyTermsUriPayload,
  typeof UPDATE_EMPLOYER_TERMS_URI
>(UPDATE_EMPLOYER_TERMS_URI);

export const updateCompanyTermsSignedStatus = createAction<
  IAdminUpdateCompanyTermsSignedPayload,
  typeof UPDATE_EMPLOYER_TERMS_SIGNED
>(UPDATE_EMPLOYER_TERMS_SIGNED);

export const loadEmailTemplates = createAction<
  IAdminEmailTemplatesQuery,
  typeof LOAD_EMAIL_TEMPLATES
>(LOAD_EMAIL_TEMPLATES);

export const updateTemplate = createAction<
  IAdminEmailTemplateUpdate,
  typeof SET_HUBSPOT_EMAIL_TEMPLATE
>(SET_HUBSPOT_EMAIL_TEMPLATE);

export const updateEmployerHubspotId = createAction<
  IAdminUpdateHubspotId,
  typeof UPDATE_EMPLOYER_HUBSPOT_ID
>(UPDATE_EMPLOYER_HUBSPOT_ID);

export const updateCandidateHubspotId = createAction<
  IAdminUpdateHubspotId,
  typeof UPDATE_CANDIDATE_HUBSPOT_ID
>(UPDATE_CANDIDATE_HUBSPOT_ID);

export const adminInviteEmployer = createAction<IAdminInviteEmployer, typeof INVITE_EMPLOYER>(
  INVITE_EMPLOYER,
);

export const addNewMatch = createAction<IAdminAddNewMatch, typeof ADD_NEW_MATCH>(ADD_NEW_MATCH);

export const rejectMatch = createAction<IAdminRejectMatchPayload, typeof REJECT_MATCH>(
  REJECT_MATCH,
);

export const updateRejectedMatch = createAction<
  IAdminUpdateRejectedMatchPayload,
  typeof UPDATE_REJECTED_MATCH
>(UPDATE_REJECTED_MATCH);

export const updateCandidateReservedStatus = createAction<
  IUpdateReservedStatus,
  typeof UPDATE_CANDIDATE_RESERVED
>(UPDATE_CANDIDATE_RESERVED);

export const loadAdminInterviewData = createAction<
  ILoadInterviewDataAdminPayload,
  typeof LOAD_ADMIN_INTERVIEW_DATA
>(LOAD_ADMIN_INTERVIEW_DATA);

export const adminCreateInterviewer = createAction<
  IAdminCreateInterviewer,
  typeof CREATE_INTERVIEWER
>(CREATE_INTERVIEWER);

export const adminCreateJobMatchNote = createAction<
  ICreateJobMatchNoteProps,
  typeof CREATE_JOB_MATCH_NOTE
>(CREATE_JOB_MATCH_NOTE);

export const adminUpdateJobMatchNote = createAction<
  IUpdateJobMatchNoteProps,
  typeof UPDATE_JOB_MATCH_NOTE
>(UPDATE_JOB_MATCH_NOTE);

export const adminDeleteJobMatchNote = createAction<
  IDeleteJobMatchNoteProps,
  typeof DELETE_JOB_MATCH_NOTE
>(DELETE_JOB_MATCH_NOTE);

export const editJobMatchFeedback = createAction<
  IUpdateEmployerFeedbackProps,
  typeof EDIT_JOB_MATCH_FEEDBACK
>(EDIT_JOB_MATCH_FEEDBACK);

export const updateInterviewStatus = createAction<
  IUpdateInterviewStatusPayload,
  typeof UPDATE_INTERVIEW_STATUS
>(UPDATE_INTERVIEW_STATUS);

export const updateInterviewDate = createAction<
  IUpdateInterviewDatePayload,
  typeof UPDATE_INTERVIEW_DATE
>(UPDATE_INTERVIEW_DATE);

export const updateAdminMatchMessage = createAction<
  IUpdateAdminMatchMessageProps,
  typeof UPDATE_ADMIN_MATCH_MESSAGE
>(UPDATE_ADMIN_MATCH_MESSAGE);

export const assignAdminToMatch = createAction<
  IAdminAssignToMatchProps,
  typeof ASSIGN_ADMIN_TO_MATCH
>(ASSIGN_ADMIN_TO_MATCH);

export const clientVisibilityJobMatchNote = createAction<
  IUpdateMatchNoteClientVisibilityProps,
  typeof CLIENT_VISIBILITY_JOB_MATCH_NOTE
>(CLIENT_VISIBILITY_JOB_MATCH_NOTE);

export const loadAdminMatchNotes = createAction<IMatchDataQuery, typeof LOAD_MATCH_NOTES>(
  LOAD_MATCH_NOTES,
);

export const updateInterviewLink = createAction<
  IUpdateInterviewLinkPayload,
  typeof UPDATE_INTERVIEW_LINK
>(UPDATE_INTERVIEW_LINK);

export const updateScheduleLink = createAction<
  IUpdateScheduleLinkPayload,
  typeof UPDATE_SCHEDULE_LINK
>(UPDATE_SCHEDULE_LINK);

export const loadJobMatch = createAction<ILoadJobMatchProps, typeof LOAD_JOB_MATCH>(LOAD_JOB_MATCH);

export const deleteJobMatchNoteAttachment = createAction<
  IDeleteJobMatchNoteAttachmentProps,
  typeof DELETE_JOB_MATCH_NOTE_ATTACHMENT
>(DELETE_JOB_MATCH_NOTE_ATTACHMENT);

export const updateJobMatchNoteAttachments = createAction<
  IUpdateNoteAttachmentsProps,
  typeof UPDATE_JOB_MATCH_NOTE_ATTACHMENTS
>(UPDATE_JOB_MATCH_NOTE_ATTACHMENTS);

export const createCandidate = createAction<ICreateCandidateProps, typeof CREATE_CANDIDATE>(
  CREATE_CANDIDATE,
);

export const createAdmin = createAction<ICreateAdminProps, typeof CREATE_ADMIN>(CREATE_ADMIN);
