import { IUserCreationAttributes } from '@/server/database';
import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';

import { UserRole } from '@/enums';
import {
  ILinkedInAuthorizedResponse,
  ILinkedInUser,
  ISuccessResponse,
  IUserExistsResponse,
  IUserRegisterResponse,
} from '@/responses';
import { IScraperProfileData } from '@/server/types';
import { ILoadLinkedInProfileData } from '@/client/redux/types';

const registerCustomer = async (
  user: IUserCreationAttributes,
  linkedInProfileData: IScraperProfileData | null,
  dataToken?: string,
) => {
  const createdUser = await oAuth()
    .axios.post(`/auth/register?role=${user.role}`, { user, linkedInProfileData, dataToken })
    .then((res: AxiosResponse<IUserRegisterResponse>) => res.data);

  return createdUser;
};

const loginWithLinkedIn = async (accessToken: string): Promise<ILinkedInAuthorizedResponse> => {
  const authData = await oAuth()
    .axios.post('/auth/linkedIn-login', { accessToken })
    .then((res: AxiosResponse<ILinkedInAuthorizedResponse>) => res.data);

  return authData;
};

const signupWithLinkedIn = async (
  accessToken: string,
  role: UserRole,
): Promise<ILinkedInAuthorizedResponse> => {
  const authData = await oAuth()
    .axios.post(`/auth/linkedIn-signup?role=${role}`, { accessToken })
    .then((res: AxiosResponse<ILinkedInAuthorizedResponse>) => res.data);

  return authData;
};

const getLinkedInUser = async (code: string, redirect: string): Promise<ILinkedInUser> => {
  const user = await oAuth()
    .axios.post('/auth/linkedIn-user', { code, redirect })
    .then((res: AxiosResponse<ILinkedInUser>) => res.data);

  return user;
};

const getLinkedInProfileData = async (
  payload: ILoadLinkedInProfileData,
): Promise<IScraperProfileData> => {
  const user = await oAuth()
    .axios.post('/auth/linkedIn/profile', payload)
    .then((res: AxiosResponse<IScraperProfileData>) => res.data);

  return user;
};

const sendForgotEmail = async (email: string) => {
  const response = await oAuth()
    .axios.post('/auth/send-forgot', { email })
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const resetPassword = async (verificationToken: string, password: string) => {
  const response = await oAuth()
    .axios.post('/auth/reset-password', { verificationToken, password })
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const checkUserExists = async (email: string) => {
  const response = await oAuth()
    .axios.get('/auth/user-exists', {
      params: {
        email,
      },
    })
    .then((res: AxiosResponse<IUserExistsResponse>) => res.data);

  return response.userExists;
};

export default {
  registerCustomer,
  loginWithLinkedIn,
  signupWithLinkedIn,
  getLinkedInUser,
  getLinkedInProfileData,
  sendForgotEmail,
  resetPassword,
  checkUserExists,
};
