import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 1,
    background: theme.palette.secondary.dark,
    width: '100%',
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },
  text: {
    padding: theme.spacing(7),
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.defaultText,
    background: '#fff',
  },
}));

export default useStyles;
