import { createAction } from '@reduxjs/toolkit';
import {
  IJobAllMatchesLoadProps,
  IJobMatchesCount,
  IJobAlgorithmMatchesLoadProps,
  IUpdateJobMatchStagePayload,
  ICreateJobMatchNoteProps,
  IUpdateJobMatchNoteProps,
  IDeleteJobMatchNoteProps,
  IUpdateJobMatchClientMessagePayload,
  IUpdateInterviewDatePayload,
  IUpdateScheduleLinkPayload,
  IUpdateInterviewLinkPayload,
  IDeleteJobMatchNoteAttachmentProps,
  IUpdateNoteAttachmentsProps,
} from '../types';

const LOAD_JOB_MATCHES_COUNT = 'MATCHES_JOBS/LOAD_JOB_MATCHES_COUNT';
const LOAD_JOB_ALGORITHM_MATCHES = 'MATCHES_JOBS/LOAD_JOB_ALGORITHM_MATCHES';
const LOAD_JOB_ALGORITHM_MATCHES_COUNT = 'MATCHES_JOBS/LOAD_JOB_ALGORITHM_MATCHES_COUNT';
const LOAD_ALL_JOB_MATCHES = 'MATCHES_JOBS/LOAD_ALL_JOB_MATCHES';
const UPDATE_JOB_MATCH_STAGE = 'MATCHES_JOBS/UPDATE_JOB_MATCH_STAGE';
const CREATE_JOB_MATCH_NOTE = 'MATCHES_JOBS/CREATE_JOB_MATCH_NOTE';
const UPDATE_JOB_MATCH_NOTE = 'MATCHES_JOBS/UPDATE_JOB_MATCH_NOTE';
const DELETE_JOB_MATCH_NOTE = 'MATCHES_JOBS/DELETE_JOB_MATCH_NOTE';
const UPDATE_JOB_MATCH_CLIENT_MESSAGE = 'MATCHES_JOBS/UPDATE_JOB_MATCH_CLIENT_MESSAGE';
const UPDATE_JOB_MATCH_INTERVIEW_DATE = 'MATCHES_JOBS/UPDATE_JOB_MATCH_INTERVIEW_DATE';
const CLIENT_UPDATE_JOB_MATCH_SCHEDULE_LINK = 'MATCHES_JOBS/CLIENT_UPDATE_JOB_MATCH_SCHEDULE_LINK';
const CLIENT_UPDATE_JOB_MATCH_INTERVIEW_LINK =
  'MATCHES_JOBS/CLIENT_UPDATE_JOB_MATCH_INTERVIEW_LINK';
const CLIENT_DELETE_JOB_MATCH_NOTE_ATTACHMENT =
  'MATCHES_JOBS/CLIENT_DELETE_JOB_MATCH_NOTE_ATTACHMENT';
const CLIENT_UPDATE_JOB_MATCH_NOTE_ATTACHMENTS =
  'MATCHES_JOBS/CLIENT_UPDATE_JOB_MATCH_NOTE_ATTACHMENTS';

export const loadJobMatchesCount = createAction<IJobMatchesCount, typeof LOAD_JOB_MATCHES_COUNT>(
  LOAD_JOB_MATCHES_COUNT,
);

export const loadJobAlgorithmMatches = createAction<
  IJobAlgorithmMatchesLoadProps,
  typeof LOAD_JOB_ALGORITHM_MATCHES
>(LOAD_JOB_ALGORITHM_MATCHES);

export const loadJobAlgorithmMatchesCount = createAction<
  IJobMatchesCount,
  typeof LOAD_JOB_ALGORITHM_MATCHES_COUNT
>(LOAD_JOB_ALGORITHM_MATCHES_COUNT);

export const loadJobMatchesAll = createAction<IJobAllMatchesLoadProps, typeof LOAD_ALL_JOB_MATCHES>(
  LOAD_ALL_JOB_MATCHES,
);

export const updateJobMatchStage = createAction<
  IUpdateJobMatchStagePayload,
  typeof UPDATE_JOB_MATCH_STAGE
>(UPDATE_JOB_MATCH_STAGE);

export const createJobMatchNote = createAction<
  ICreateJobMatchNoteProps,
  typeof CREATE_JOB_MATCH_NOTE
>(CREATE_JOB_MATCH_NOTE);

export const updateJobMatchNote = createAction<
  IUpdateJobMatchNoteProps,
  typeof UPDATE_JOB_MATCH_NOTE
>(UPDATE_JOB_MATCH_NOTE);

export const deleteJobMatchNote = createAction<
  IDeleteJobMatchNoteProps,
  typeof DELETE_JOB_MATCH_NOTE
>(DELETE_JOB_MATCH_NOTE);

export const updateJobMatchClientMessage = createAction<
  IUpdateJobMatchClientMessagePayload,
  typeof UPDATE_JOB_MATCH_CLIENT_MESSAGE
>(UPDATE_JOB_MATCH_CLIENT_MESSAGE);

export const updateJobMatchInterviewDate = createAction<
  IUpdateInterviewDatePayload,
  typeof UPDATE_JOB_MATCH_INTERVIEW_DATE
>(UPDATE_JOB_MATCH_INTERVIEW_DATE);

export const clientUpdateJobMatchScheduleLink = createAction<
  IUpdateScheduleLinkPayload,
  typeof CLIENT_UPDATE_JOB_MATCH_SCHEDULE_LINK
>(CLIENT_UPDATE_JOB_MATCH_SCHEDULE_LINK);

export const clientUpdateJobMatchInterviewLink = createAction<
  IUpdateInterviewLinkPayload,
  typeof CLIENT_UPDATE_JOB_MATCH_INTERVIEW_LINK
>(CLIENT_UPDATE_JOB_MATCH_INTERVIEW_LINK);

export const clientDeleteJobMatchNoteAttachment = createAction<
  IDeleteJobMatchNoteAttachmentProps,
  typeof CLIENT_DELETE_JOB_MATCH_NOTE_ATTACHMENT
>(CLIENT_DELETE_JOB_MATCH_NOTE_ATTACHMENT);

export const clientUpdateJobMatchNoteAttachments = createAction<
  IUpdateNoteAttachmentsProps,
  typeof CLIENT_UPDATE_JOB_MATCH_NOTE_ATTACHMENTS
>(CLIENT_UPDATE_JOB_MATCH_NOTE_ATTACHMENTS);
