import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';
import {
  ICandidateBaseInfoDto,
  ICandidateDto,
  ICandidateSalesQualificationDto,
  ICandidateSecondarySkillsDto,
  ICandidateSkillsDto,
  ICandidateVerticalDto,
} from '@/DTO';
import {
  IOnboardingCompensationResponse,
  ISuccessResponse,
  IUploadFileResponse,
  ICandidateBasicInfoResponse,
} from '@/responses';
import { ICandidateBasicInfoRequest, IUpdateUserPasswordRequest } from '@/requests';
import { ICandidateSettingsResponse } from '@/client/redux/types';
import { ICandidateAttributes, ICandidateEducationAttributes } from '@/server/database';
import {
  IEducationFormData,
  IUpdateCandidateSalesQualifications,
  IUpdateCandidateVertical,
} from '@/client/types';
import { ISkillsFormData } from '@/client/forms/profile/candidate/SkillsForm/types';
import { ISettingsFormData } from '@/client/forms/profile/candidate/SettingsForm/types';
import { ISettingsFormData as IAdminSettingsFormData } from '@/client/forms/profile/admin/SettingsForm/types';
import {
  candidateProfileEditUrl,
  requestUpdateCandidateUrl,
  requestUpdateSettings,
} from '@/client/utils';
import { ICandidateUrlFormData } from '@/client/forms/profile/candidate/CandidateUrlForm/types';
import { ISecondarySkillsFormData } from '@/client/forms/profile/candidate/SecondarySkillsForm/types';

const loadCandidate = () =>
  oAuth()
    .axios.get('/profile/candidate')
    .then((res: AxiosResponse<ICandidateDto>) => res.data);

const loadCandidateResume = () =>
  oAuth()
    .axios.get('/profile/candidate/resume')
    .then((res: AxiosResponse<IUploadFileResponse>) => res.data);

const uploadProfileImage = (image: File) => {
  const fd = new FormData();
  fd.append('file', image);

  return oAuth()
    .axios.put('/profile/image', fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<IUploadFileResponse>) => res.data.fileUri);
};

const uploadCompanyLogo = (image: File) => {
  const fd = new FormData();
  fd.append('file', image);

  return oAuth()
    .axios.put('/profile/employer/company-logo', fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<IUploadFileResponse>) => res.data.fileUri);
};

const updateCandidateBasicInfo = (payload: ICandidateBasicInfoRequest, candidateId?: string) => {
  const url = candidateProfileEditUrl('basic-info', candidateId);

  return oAuth()
    .axios.put(url, payload)
    .then((res: AxiosResponse<ICandidateBasicInfoResponse>) => res.data);
};

const updateCandidateBio = (bio: string, candidateId?: string) => {
  const url = candidateProfileEditUrl('bio', candidateId);

  return oAuth()
    .axios.put(url, { bio })
    .then((res: AxiosResponse<Pick<ICandidateAttributes, 'bio'>>) => res.data.bio);
};

const updateCandidateCompensation = (annualRate: number, candidateId?: string) => {
  const url = candidateProfileEditUrl('compensation', candidateId);

  return oAuth()
    .axios.put(url, { annualRate })
    .then((res: AxiosResponse<IOnboardingCompensationResponse>) => res.data.annualRate);
};

const updateCandidateEducations = (educations: IEducationFormData[], candidateId?: string) => {
  const url = candidateProfileEditUrl('education', candidateId);
  const data = educations.map((item) => ({ ...item, degree: item.degree?.value }));

  return oAuth()
    .axios.put(url, data)
    .then((res: AxiosResponse<ICandidateEducationAttributes[]>) => res.data);
};

const updateCandidateSecondarySkills = (
  secondarySkills: ISecondarySkillsFormData,
  candidateId?: string,
) => {
  const url = candidateProfileEditUrl('secondary-skills', candidateId);
  const data = {
    jobFunctions: secondarySkills.jobFunctions.map((item) => item.id),
    industryExperience: secondarySkills.industryExperience.map((item) => item.id),
  };

  return oAuth()
    .axios.put(url, data)
    .then((res: AxiosResponse<ICandidateSecondarySkillsDto>) => res.data);
};

const updateCandidateSkills = (skills: ISkillsFormData, candidateId?: string) => {
  const url = candidateProfileEditUrl('skills', candidateId);
  const data = {
    experienceLevel: skills.experienceLevel?.value,
    languages: skills.languages,
    availabilities: skills.availabilities,
  };

  return oAuth()
    .axios.put(url, data)
    .then((res: AxiosResponse<ICandidateSkillsDto>) => res.data);
};

const resetPassword = (data: IUpdateUserPasswordRequest) => {
  const message = oAuth()
    .axios.put('/auth/update-password', {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    })
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return message;
};

const updateCandidateSettings = (settings: ISettingsFormData) => {
  const fd = requestUpdateSettings(settings);
  return oAuth()
    .axios.put('profile/candidate/settings', fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<ICandidateSettingsResponse>) => res.data);
};

const updateCandidateUrl = (
  data: Pick<ICandidateUrlFormData, 'linkedinUri' | 'resumeData' | 'hubspotId'>,
  candidateId: string,
) => {
  const fd = requestUpdateCandidateUrl(data);

  return oAuth()
    .axios.put(`admin/candidates/${candidateId}/edit/candidate-url`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(
      (res: AxiosResponse<Pick<ICandidateBaseInfoDto, 'linkedinUri' | 'resumeUri'>>) => res.data,
    );
};

const updateAdminSettings = (settings: IAdminSettingsFormData) => {
  return oAuth()
    .axios.put('profile/admin/settings', settings)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const updateCandidateVertical = ({ verticals, candidateId }: IUpdateCandidateVertical) => {
  const url = candidateProfileEditUrl('vertical', candidateId);
  const data = {
    verticals: verticals.map((item) => item.id),
  };

  return oAuth()
    .axios.put(url, data)
    .then((res: AxiosResponse<ICandidateVerticalDto>) => res.data);
};

const updateCandidateSalesQualifications = ({
  salesQualifications,
  candidateId,
}: IUpdateCandidateSalesQualifications) => {
  const url = candidateProfileEditUrl('sales-qualifications', candidateId);
  const data = {
    salesQualifications: salesQualifications.map((item) => item.id),
  };

  return oAuth()
    .axios.put(url, data)
    .then((res: AxiosResponse<ICandidateSalesQualificationDto>) => res.data);
};

export default {
  loadCandidate,
  loadCandidateResume,
  uploadProfileImage,
  uploadCompanyLogo,
  updateCandidateBasicInfo,
  updateCandidateBio,
  updateCandidateCompensation,
  updateCandidateEducations,
  updateCandidateSecondarySkills,
  updateCandidateSkills,
  updateCandidateUrl,
  resetPassword,
  updateCandidateSettings,
  updateAdminSettings,
  updateCandidateVertical,
  updateCandidateSalesQualifications,
};
