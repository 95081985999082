import { useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from '@/client/theme';
import GlobalLayout from '@/client/layouts/GlobalLayout';
import GlobalStyles from '@/client/components/GlobalStyles';
import { AuthGates } from '@/client/gates';
import { useAppConfig, useStore } from '@/client/hooks';
import { setYupLocale } from '@/utils';
import { CustomToaster } from '@/client/components';

setYupLocale();

const App = ({ Component, pageProps }: AppProps) => {
  const store = useStore(pageProps.initialReduxState);
  const tabTitle = useAppConfig();

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <title>{tabTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />

        <link rel="manifest" href="/manifest.json" />
        <link rel="icon shortcut" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />

        <link
          rel="preload"
          href="/fonts/Soehne/soehne-buch.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Soehne/soehne-extraleicht.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Soehne/soehne-kraftig.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Soehne/soehne-leicht.woff2"
          as="font"
          crossOrigin="anonymous"
        />
      </Head>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <AuthGates>
            <GlobalLayout>
              <Component {...pageProps} />
              <CustomToaster />
            </GlobalLayout>
          </AuthGates>
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default App;
