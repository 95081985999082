import { IBaseCandidateInfo } from '@/client/components/Modals/RequestInterviewModal/types';
import { IFilteredJobs, IState } from '@/client/redux/types';
import { ApplicantStatus } from '@/enums';
import { publishedStatusJobForEmployer } from '@/constants';
import { getDatePSTFormat } from '@/client/utils';
import { createSelector } from 'reselect';

export const myJobsSelector = ({ myJobs }: IState) => myJobs;
export const employerJobsSelector = createSelector(
  myJobsSelector,
  ({ employerJobs, isLoading }) => ({
    employerJobs,
    isLoading,
  }),
);
export const jobApplicantsSelector = createSelector(
  myJobsSelector,
  ({ jobApplicants, isLoading }) => ({
    jobApplicants,
    isLoading,
  }),
);
export const jobActiveApplicantsSelector = createSelector(
  jobApplicantsSelector,
  ({ jobApplicants, isLoading }) => ({
    jobApplicants: jobApplicants.filter(({ status }) => status === ApplicantStatus.Submitted),
    isLoading,
  }),
);
export const jobApplicantByIdSelector = (applicantId: string | null) =>
  createSelector(
    jobApplicantsSelector,
    ({ jobApplicants }) =>
      jobApplicants.find(({ applicationId }) => applicationId === applicantId) ?? null,
  );
export const jobApplicantRequestInterviewSelector = (applicantId: string | null) =>
  createSelector(jobApplicantByIdSelector(applicantId), (applicant): IBaseCandidateInfo | null =>
    applicant
      ? {
          id: applicant.candidateId,
          name: applicant.personInitials,
          education: applicant.education,
          imageUri: applicant?.imageUri ?? '',
        }
      : null,
  );
export const jobInterviewsSelector = createSelector(
  myJobsSelector,
  ({ jobInterviews, isLoading }) => ({
    jobInterviews,
    isLoading,
  }),
);
export const employerJobPropsSelector = createSelector(
  employerJobsSelector,
  ({ employerJobs, isLoading }) =>
    employerJobs.reduce<IFilteredJobs>(
      (filteredJobs, job) => {
        const jobProps = {
          id: job.id,
          title: job.jobTitle,
          status: job.jobStatus,
          date: job.publishedAt
            ? `Posted ${getDatePSTFormat(job.publishedAt, 'MMM dd, yyyy')}`
            : '',
          matchesCounts: job.matchesCounts,
          newMatchesCount: job.newMatchesCount,
        };

        if (publishedStatusJobForEmployer.includes(job.jobStatus)) {
          filteredJobs.publishedJobsProps.push(jobProps);
        } else {
          filteredJobs.otherJobsProps.push(jobProps);
        }
        return filteredJobs;
      },
      {
        publishedJobsProps: [],
        otherJobsProps: [],
        isLoading,
      },
    ),
);
export const selectedEmployerJobSelector = createSelector(
  myJobsSelector,
  ({ selectedEmployerJob }) => selectedEmployerJob,
);

export const candidateApplicantsSelector = createSelector(
  myJobsSelector,
  ({ candidateApplicants, isLoading }) => {
    return { candidateApplicants, isLoading };
  },
);

export const candidateInterviewSelector = createSelector(
  myJobsSelector,
  ({ candidateInterviews, isLoading }) => {
    return { candidateInterviews, isLoading };
  },
);

export const countNewCandidateInterviewsSelector = createSelector(myJobsSelector, (state) => {
  return state.countNewCandidateInterviews;
});
