import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';

import { IEmployerAttributes } from '@/server/database';
import {
  IEmployerDTO,
  IEmployerShortInfoDto,
  ICompanyTermsDTO,
  IEmployerSettingsDTO,
  IInterviewDto,
} from '@/DTO';
import {
  IEditEmployerInfoRequest,
  IEmployerInterviewerRequest,
  IManageCompanyQuery,
  IRequestInterviewRequest,
} from '@/requests';
import { IWelcomeFormData } from '@/client/forms/onboarding/employer/WelcomeForm/types';
import { requestEmployerOnboarding } from '@/client/utils';
import { IManageCompanyResponse, ISuccessResponse } from '@/responses';

const onboarding = async (data: IWelcomeFormData) => {
  const formData = requestEmployerOnboarding(data);

  const response = await oAuth()
    .axios.post('/onboarding/employer', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<IEmployerAttributes>) => res.data);

  return response;
};

const updateEmployerSettingsProfile = async (data: IEditEmployerInfoRequest) => {
  const updateSettings = await oAuth()
    .axios.put<IEmployerSettingsDTO>('/profile/employer/settings', data)
    .then((res) => res.data);

  return updateSettings;
};

const getEmployerProfileData = async () => {
  const data = await oAuth()
    .axios.get('/profile/employer')
    .then((res: AxiosResponse<IEmployerDTO>) => res.data);

  return data;
};

const getEmployerTermsData = async () => {
  const data = await oAuth()
    .axios.get(`/profile/employer/terms`)
    .then((res: AxiosResponse<ICompanyTermsDTO>) => res.data);

  return data;
};

const getManageCompanyData = async (params: IManageCompanyQuery) => {
  const data = await oAuth()
    .axios.get<IManageCompanyResponse>(`/employer/manage-company`, { params })
    .then((res) => res.data);

  return data;
};

const inviteEmployer = async (email: string) => {
  const response = await oAuth()
    .axios.post<ISuccessResponse>('/employer/invite', { email })
    .then((res) => res.data);

  return response;
};

const requestInterview = async (payload: IRequestInterviewRequest) => {
  const interview = await oAuth()
    .axios.post('/interviews/request', payload)
    .then((res: AxiosResponse<IInterviewDto>) => res.data);

  return interview;
};

const createInterviewer = async (payload: IEmployerInterviewerRequest) =>
  oAuth()
    .axios.post<IEmployerShortInfoDto>('/employer/interviewer', payload)
    .then((res) => res.data);

const getInterviewAvailableEmployers = async () =>
  oAuth()
    .axios.get<IEmployerShortInfoDto[]>('/employer/interview-available')
    .then((res) => res.data);

export default {
  onboarding,
  updateEmployerSettingsProfile,
  getEmployerProfileData,
  getEmployerTermsData,
  getManageCompanyData,
  requestInterview,
  inviteEmployer,
  createInterviewer,
  getInterviewAvailableEmployers,
};
