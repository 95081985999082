import { availableRoutes } from '@/client/routes';
import { IUserDto } from '@/DTO';
import { UserRole } from '@/enums';

const checkUserHasPostedJob = (user: IUserDto, path: string): boolean => {
  // If employed has not post a job yet, allow only routes from untilJobIsPosted list
  return [UserRole.MasterEmployer, UserRole.Employer, UserRole.AdminEmployer].includes(user.role) &&
    !user?.hasPostedJob
    ? availableRoutes.untilJobIsPosted.includes(path)
    : true;
};

export default checkUserHasPostedJob;
