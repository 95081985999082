const socialLinks = [
  {
    iconSrc: '/icons/wechat-icon.svg',
    iconAlt: 'wechat',
    href: 'mailto:contact@alariss.com',
  },
  {
    iconSrc: '/icons/facebook-icon.svg',
    iconAlt: 'facebook',
    href: 'https://www.facebook.com/AlarissGlobal/',
  },
  {
    iconSrc: '/icons/twitter-icon.svg',
    iconAlt: 'twitter',
    href: 'https://twitter.com/alarissglobal',
  },
  {
    iconSrc: '/icons/linkedin-footer-icon.svg',
    iconAlt: 'linkedin',
    href: 'https://www.linkedin.com/company/alariss/',
  },
  {
    iconSrc: '/icons/spotify-icon.svg',
    iconAlt: 'spotify',
    href: 'https://open.spotify.com/show/2gtQVwM11vkP1mkUI756Y2',
  },
  {
    iconSrc: '/icons/instagram-icon.svg',
    iconAlt: 'instagram',
    href: 'https://www.instagram.com/alarissglobal/',
  },
];

export default socialLinks;
