import { WORKING_HOURS_PER_MONTH, WORKING_HOURS_PER_YEAR } from '@/constants';
import { ICompensationPeriodType } from '@/client/types';

const maxSalary = 200;

export const generatePeriod = (period: ICompensationPeriodType = 'month') => {
  return period === 'month' ? WORKING_HOURS_PER_MONTH : WORKING_HOURS_PER_YEAR;
};

export const cutSalary = (salary: number, cut: boolean) => {
  if (!cut) {
    return salary.toLocaleString().toString();
  }

  return salary > 1000
    ? `${(Math.round(salary / 10) / 100).toLocaleString()}k`
    : `${salary.toLocaleString()}`;
};

export const generatePayment = (salary: number, salaryTo?: number) => {
  if (!salaryTo) {
    return salary.toString();
  }

  return salary.toString() + (salaryTo <= maxSalary ? ` - ${salaryTo}` : '+');
};

export const generateGeneralPayment = (
  salary?: number | null,
  salaryTo?: number | null,
  period: ICompensationPeriodType = 'month',
  separator = ' - ',
  cut = true,
) => {
  if (salary == null) {
    return;
  }

  const payment = cutSalary(generatePeriod(period) * salary, cut);

  if (!salaryTo) {
    return payment;
  }

  if (salaryTo > maxSalary) {
    return `${payment}+`;
  }

  return `${payment}${separator}${cutSalary(generatePeriod(period) * salaryTo, cut)}${
    salaryTo === maxSalary ? '+' : ''
  }`;
};

export const hourlyToAnnualRate = (rate: number) =>
  Math.round(rate * WORKING_HOURS_PER_YEAR * 100) / 100;

export const annualToHourlyRate = (rate: number) =>
  Math.round((rate / WORKING_HOURS_PER_YEAR) * 100) / 100;
