type WordsLimitRuleType = 'eq' | 'gt' | 'gte' | 'lt' | 'lte';

const wordsLimitRuleMapping: Record<WordsLimitRuleType, (a: number, b: number) => boolean> = {
  eq: (a: number, b: number) => a === b,
  gt: (a: number, b: number) => a > b,
  gte: (a: number, b: number) => a >= b,
  lt: (a: number, b: number) => a < b,
  lte: (a: number, b: number) => a <= b,
};

export const wordsLimit =
  (limit: number, type: WordsLimitRuleType, required = true) =>
  (val: string | null | undefined) => {
    if (!required && !val) {
      return true;
    }

    const values = val?.replace(/<\/?[^>]+(>|$)/g, ' ').trim();
    const wordsCount = (values?.split(/\s+/)?.filter((word) => word.trim().length > 0) ?? [])
      .length;

    return wordsLimitRuleMapping[type](wordsCount, limit);
  };

export const hasNumberValue = <T extends number>(value?: T | string | null): boolean => {
  if (value === null || value === undefined) {
    return false;
  }

  return +value >= 0;
};
