import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';
import useStyles from './styles';

export const Divider = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>): JSX.Element => {
  const { divider, text } = useStyles();

  return (
    <div className={clsx(divider, className)} {...props}>
      {children && <span className={text}>{children}</span>}
    </div>
  );
};

export default Divider;
