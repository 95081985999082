import toast, { ToastBar, Toaster } from 'react-hot-toast';
import Image from 'next/image';
import clsx from 'clsx';
import { ButtonBase, Typography } from '@material-ui/core';
import useStyles from './styles';
import { toastIconMapping, toastTypes } from './constants';
import { IToastType } from './types';

export const CustomToaster = () => {
  const classes = useStyles();

  return (
    <Toaster
      position="top-center"
      toastOptions={{
        duration: 7000,
        className: 'toaster-container',
      }}
      containerClassName={classes.toaster}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ message }) =>
            toastTypes.includes(t.type) ? (
              <div className={clsx(classes.container, classes[t.type as IToastType])}>
                <div className={classes.toastBarHeader}>
                  <div className={classes.icon}>
                    <Image src={toastIconMapping[t.type as IToastType]} width={34} height={34} />
                  </div>
                  <Typography component="div" className={classes.message}>
                    {message}
                  </Typography>
                </div>
                <ButtonBase
                  onClick={() => toast.dismiss(t.id)}
                  className={classes.button}
                  disableRipple
                >
                  Dismiss
                </ButtonBase>
              </div>
            ) : (
              <Typography component="div" className={classes.message}>
                {message}
              </Typography>
            )
          }
        </ToastBar>
      )}
    </Toaster>
  );
};

export default CustomToaster;
