import { loadDetailedJob, loadNextJobs } from '@/client/redux/actions';
import { browseJobListQuerySelector } from '@/client/redux/selectors';
import {
  addJobs,
  loadJobsFailed,
  startLoading,
  startLoadingDetailedJob,
  stopLoadingDetailedJob,
  storeDetailedJob,
} from '@/client/redux/store/browse-job.slice';
import { jobsService } from '@/client/services';
import { handleRequestError } from '@/client/utils';
import { IJobDetailsDto } from '@/DTO';
import { IBrowseJobQuery } from '@/requests';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { IActionWithPayload, IBrowseJobResult } from '../types';

function* loadNextJobsSaga() {
  try {
    yield put(startLoading());
    const payload: IBrowseJobQuery = yield select(browseJobListQuerySelector);
    const result: IBrowseJobResult = yield call(jobsService.browseJobs, payload);

    yield put(addJobs(result));
  } catch (error) {
    yield put(loadJobsFailed());
    handleRequestError(error);
  }
}

function* loadDetailedJobSaga({ payload }: IActionWithPayload<string>) {
  try {
    yield put(startLoadingDetailedJob());
    const detailedJob: IJobDetailsDto = yield call(jobsService.getJob, payload);

    yield put(storeDetailedJob(detailedJob));
  } catch (error) {
    yield put(stopLoadingDetailedJob());
    handleRequestError(error);
  }
}

export default function* browseJobRootSaga() {
  yield all([
    takeLatest(loadNextJobs.type, loadNextJobsSaga),
    takeLatest(loadDetailedJob.type, loadDetailedJobSaga),
  ]);
}
