import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  adminService,
  companiesService,
  locationsService,
  profileService,
} from '@/client/services';
import { ICompanyDto } from '@/DTO';
import {
  IActionWithPayload,
  ILoadCitiesData,
  IUpdateCompanyProfileData,
} from '@/client/redux/types';
import {
  getCompanyProfileData,
  getCompanyProfileDataFinished,
  loadCompanyCities,
  updateCompanyProfile,
  uploadCompanyProfileLogo,
} from '@/client/redux/actions';
import { handleRequestError } from '@/client/utils';
import {
  storeCompanyProfileLoading,
  storeCompanyProfileCities,
  storeCompanyProfileData,
  storeCompanyProfileLogo,
} from '@/client/redux/store/companies.slice';
import { ICityBase } from '@/client/types';
import toast from 'react-hot-toast';
import { messages } from '@/client/constants';
import { ICompanyProfileFormData } from '@/client/forms/profile/employer/CompanyProfileForm/types';

function* loadCitiesSaga(action: IActionWithPayload<ILoadCitiesData>) {
  try {
    const cities: ICityBase[] = yield call(
      locationsService.loadCities,
      action.payload.countryCode,
      action.payload.regionCode,
      action.payload.input,
    );

    yield put(storeCompanyProfileCities(cities));
  } catch (error) {
    handleRequestError(error);
  }
}

function* getCompanyProfileDataSaga({ payload }: IActionWithPayload<string | undefined>) {
  yield put(storeCompanyProfileLoading(true));
  try {
    const data: ICompanyDto = yield call(companiesService.getCompanyProfileData, payload);

    yield put(storeCompanyProfileData(data));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeCompanyProfileLoading(false));
    yield put(getCompanyProfileDataFinished());
  }
}

function* uploadCompanyLogoSaga({ payload }: IActionWithPayload<IUpdateCompanyProfileData<File>>) {
  try {
    const imageUri: string = payload.companyId
      ? yield call(adminService.uploadCompanyLogo, payload.companyId, payload.data)
      : yield call(profileService.uploadCompanyLogo, payload.data);

    yield put(storeCompanyProfileLogo(imageUri));

    toast.success(messages.companyLogoUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateCompanyProfileSaga({
  payload,
}: IActionWithPayload<IUpdateCompanyProfileData<ICompanyProfileFormData>>) {
  yield put(storeCompanyProfileLoading(true));
  try {
    const updatedCompanyProfile: ICompanyDto = payload.companyId
      ? yield call(adminService.updateCompanyProfile, payload.companyId, payload.data)
      : yield call(companiesService.updateCompanyProfile, payload.data);

    yield put(storeCompanyProfileData(updatedCompanyProfile));
    toast.success(messages.profileUpdated);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeCompanyProfileLoading(false));
  }
}

export default function* companiesRootSaga() {
  yield all([takeLatest(loadCompanyCities.type, loadCitiesSaga)]);
  yield all([takeLatest(uploadCompanyProfileLogo.type, uploadCompanyLogoSaga)]);
  yield all([takeLatest(getCompanyProfileData.type, getCompanyProfileDataSaga)]);
  yield all([takeLatest(updateCompanyProfile.type, updateCompanyProfileSaga)]);
}
