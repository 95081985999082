import oAuth from '@DevimaSolutions/o-auth';
import {
  IJobMatchesDataResponse,
  ITotalMatchesCountResponse,
  IAllJobMatchesDataResponse,
} from '@/responses';
import { IBasePaginationQuery } from '@/requests';
import { IJobMatchesCountDto } from '@/DTO';

const getJobMatchesCount = async (jobId: string) => {
  const response = await oAuth()
    .axios.get<IJobMatchesCountDto[]>(`/admin/jobs/${jobId}/matches/count`)
    .then((res) => res.data);

  return response;
};

const getJobAlgorithmMatches = async (jobId: string, params: IBasePaginationQuery) => {
  const matchesForJob = await oAuth()
    .axios.get<IJobMatchesDataResponse>(`/admin/jobs/${jobId}/matches/algorithm`, {
      params,
    })
    .then((res) => res.data);

  return matchesForJob;
};

const getJobAlgorithmMatchesCount = async (jobId: string) => {
  const total = await oAuth()
    .axios.get<ITotalMatchesCountResponse>(`/admin/jobs/${jobId}/matches/algorithm/count`)
    .then((res) => res.data);

  return total;
};

const getJobAllMatches = async (jobId: string) => {
  const matches = await oAuth()
    .axios.get<IAllJobMatchesDataResponse>(`/admin/jobs/${jobId}/matches/all-matches`)
    .then((res) => res.data);

  return matches;
};

export default {
  getJobMatchesCount,
  getJobAlgorithmMatches,
  getJobAlgorithmMatchesCount,
  getJobAllMatches,
};
