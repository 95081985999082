import { ICandidateUrlFormData } from '@/client/forms/profile/candidate/CandidateUrlForm/types';
import { ISettingsFormData } from '@/client/forms/profile/candidate/SettingsForm/types';

export const requestUpdateSettings = (settings: ISettingsFormData): FormData => {
  const fd = new FormData();
  const { resume, ...rest } = settings;

  if (resume) {
    fd.append('file', resume);
  }

  Object.entries(rest).forEach(([key, value]) => {
    fd.append(key, value);
  });

  return fd;
};

export const requestUpdateCandidateUrl = (data: ICandidateUrlFormData): FormData => {
  const fd = new FormData();
  const { resumeData, ...rest } = data;

  if (resumeData?.resume) {
    fd.append('file', resumeData.resume);
  }

  if (resumeData?.resumeUri) {
    fd.append('resumeUri', resumeData.resumeUri);
  }

  Object.entries(rest).forEach(([key, value]) => {
    if (value) {
      fd.append(key, value);
    }
  });

  return fd;
};
