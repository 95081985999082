import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { handleRequestError } from '@/client/utils';
import {
  setLoading,
  setJobMatchesCount,
  setJobMatches,
  setJobAlgorithmMatchesCount,
  setJobMatchesAll,
  setJobMatchStage,
  setJobMatchNote,
  removeJobMatchNote,
  setUpdatedJobMatchNote,
  setClientMatchMessage,
  setJobMatchInterviewDate,
  setDeleting,
  setJobMatchesScheduleLink,
  setJobMatchesInterviewLink,
  removeJobMatchNoteAttachment,
  updateMatchNote,
} from '@/client/redux/store/matches-jobs.slice';
import { matchesJobsPageSelector, userRoleSelector } from '@/client/redux/selectors';
import { IJobMatchesDataResponse, ITotalMatchesCountResponse } from '@/responses';
import { matchesConstants } from '@/constants';
import {
  IInterviewDateDto,
  IJobMatchesCountDto,
  IMatchNoteDto,
  IClientMatchMessageDto,
  IJobMatchDto,
  IScheduleLinkDto,
  IInterviewLinkDto,
} from '@/DTO';
import { UserRole } from '@/enums';
import {
  adminMatchesJobService,
  adminService,
  employerMatchesJobService,
  interviewsService,
  matchesService,
} from '@/client/services';
import { messages } from '@/client/constants';
import toast from 'react-hot-toast';
import {
  loadJobMatchesCount,
  loadJobAlgorithmMatches,
  loadJobAlgorithmMatchesCount,
  loadJobMatchesAll,
  updateJobMatchStage,
  updateJobMatchClientMessage,
  updateJobMatchInterviewDate,
  clientUpdateJobMatchScheduleLink,
  clientUpdateJobMatchInterviewLink,
  clientDeleteJobMatchNoteAttachment,
  clientUpdateJobMatchNoteAttachments,
} from '../actions/matches-jobs.actions';
import {
  IActionWithPayload,
  IJobAllMatchesLoadProps,
  IJobMatchesCount,
  IJobAlgorithmMatchesLoadProps,
  IUpdateJobMatchStagePayload,
  ICreateJobMatchNoteProps,
  IUpdateJobMatchNoteProps,
  IDeleteJobMatchNoteProps,
  IUpdateJobMatchClientMessagePayload,
  IUpdateInterviewDatePayload,
  IUpdateScheduleLinkPayload,
  IUpdateInterviewLinkPayload,
  IDeleteJobMatchNoteAttachmentProps,
  IUpdateNoteAttachmentsProps,
} from '../types';
import { createJobMatchNote, deleteJobMatchNote, updateJobMatchNote } from '../actions';

function* loadJobMatchesCountSaga({ payload }: IActionWithPayload<IJobMatchesCount>) {
  try {
    yield put(setLoading(true));
    const userRole: UserRole = yield select(userRoleSelector);

    const response: IJobMatchesCountDto[] =
      userRole === UserRole.Admin
        ? yield call(adminMatchesJobService.getJobMatchesCount, payload.jobId)
        : yield call(employerMatchesJobService.getJobMatchesCount, payload.jobId);

    yield put(setJobMatchesCount(response));
    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
    yield put(setJobMatchesCount([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* loadJobMatchesAllSaga({
  payload: { jobId, onSuccess, onError },
}: IActionWithPayload<IJobAllMatchesLoadProps>) {
  try {
    yield put(setLoading(true));

    const jobMathes: IJobMatchDto[] = yield call(matchesService.getJobAllMatches, jobId);

    yield put(setJobMatchesAll(jobMathes));
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadJobAlgorithmMatchesSaga({
  payload,
}: IActionWithPayload<IJobAlgorithmMatchesLoadProps>) {
  try {
    yield put(setLoading(true));

    const { page } = yield select(matchesJobsPageSelector);

    const matches: IJobMatchesDataResponse = yield call(
      adminMatchesJobService.getJobAlgorithmMatches,
      payload.jobId,
      {
        perPage: matchesConstants.defaultJobMatchesPerPage,
        page,
      },
    );

    yield put(setJobMatches(matches));
    payload?.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadJobAlgorithmMatchesCountSaga({ payload }: IActionWithPayload<IJobMatchesCount>) {
  try {
    yield put(setLoading(true));

    const total: ITotalMatchesCountResponse = yield call(
      adminMatchesJobService.getJobAlgorithmMatchesCount,
      payload.jobId,
    );

    yield put(setJobAlgorithmMatchesCount(total));
    payload?.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateJobMatchStageSaga({
  payload: { onSuccess, onError, matchId, ...rest },
}: IActionWithPayload<IUpdateJobMatchStagePayload>) {
  try {
    yield call(employerMatchesJobService.updateMatchStage, matchId, rest);
    yield put(setJobMatchStage({ matchId, stage: rest.stage }));
    onSuccess?.();
    toast.success(messages.matchStageUpdated);
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* createJobMatchNoteSaga({ payload }: IActionWithPayload<ICreateJobMatchNoteProps>) {
  try {
    const createdNote: IMatchNoteDto = yield call(
      employerMatchesJobService.createJobMatchNote,
      payload,
    );

    yield put(setJobMatchNote(createdNote));
    payload.onSuccess?.();
    toast.success(messages.jobMatchNoteCreated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateJobMatchNoteSaga({
  payload: { onSuccess, onError, ...rest },
}: IActionWithPayload<IUpdateJobMatchNoteProps>) {
  try {
    const updatedNote: IMatchNoteDto = yield call(
      employerMatchesJobService.updateJobMatchNote,
      rest,
    );

    yield put(setUpdatedJobMatchNote(updatedNote));
    onSuccess?.();
    toast.success(messages.jobMatchNoteUpdated);
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* deleteJobMatchNoteSaga({ payload }: IActionWithPayload<IDeleteJobMatchNoteProps>) {
  try {
    yield put(setDeleting(payload.matchNoteId));

    yield call(employerMatchesJobService.deleteJobMatchNote, payload.matchNoteId);
    yield put(removeJobMatchNote(payload));
    toast.success(messages.jobMatchNoteDeleted);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setDeleting(null));
  }
}

function* updateJobMatchClientMessageSaga({
  payload: { matchId, message, stage, onSuccess, onError },
}: IActionWithPayload<IUpdateJobMatchClientMessagePayload>) {
  try {
    const updatedMessage: IClientMatchMessageDto = yield call(
      employerMatchesJobService.updateJobMatchClientMessage,
      matchId,
      message,
      stage,
    );

    yield put(setClientMatchMessage(updatedMessage));
    onSuccess?.();
    toast.success(messages.jobMatchClientFeedbackEdited);
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* updateJobMatchInterviewDateSaga({
  payload: { interviewId, date, onSuccess, onError },
}: IActionWithPayload<IUpdateInterviewDatePayload>) {
  try {
    const result: IInterviewDateDto = yield call(
      interviewsService.updateInterviewDate,
      interviewId,
      date,
    );

    yield put(setJobMatchInterviewDate(result));
    toast.success(messages.interviewDateUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* clientUpdateJobMatchInterviewLinkSaga({
  payload: { interviewId, interviewLink, onSuccess, onError },
}: IActionWithPayload<IUpdateInterviewLinkPayload>) {
  try {
    const result: IInterviewLinkDto = yield call(
      adminService.updateInterviewLink,
      interviewId,
      interviewLink,
    );

    yield put(setJobMatchesInterviewLink(result));
    toast.success(messages.interviewLinkUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* clientUpdateJobMatchScheduleLinkSaga({
  payload: { interviewId, scheduleLink, onSuccess, onError },
}: IActionWithPayload<IUpdateScheduleLinkPayload>) {
  try {
    const result: IScheduleLinkDto = yield call(
      adminService.updateScheduleLink,
      interviewId,
      scheduleLink,
    );

    yield put(setJobMatchesScheduleLink(result));
    toast.success(messages.scheduleLinkUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* clientDeleteJobMatchNoteAttachmentSaga({
  payload: { attachmentId, matchNoteId, matchId, onSuccess, onError },
}: IActionWithPayload<IDeleteJobMatchNoteAttachmentProps>) {
  try {
    yield call(employerMatchesJobService.deleteJobMatchNoteAttachment, {
      attachmentId,
      matchNoteId,
    });
    yield put(removeJobMatchNoteAttachment({ attachmentId, matchNoteId, matchId }));
    toast.success(messages.jobMatchNoteAttachmentDeleted);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* clientUpdateJobMatchNoteAttachmentsSaga({
  payload: { onSuccess, onError, ...rest },
}: IActionWithPayload<IUpdateNoteAttachmentsProps>) {
  try {
    const updatedNote: IMatchNoteDto = yield call(
      employerMatchesJobService.updateJobMatchNoteAttachments,
      rest,
    );

    yield put(updateMatchNote(updatedNote));

    toast.success(messages.jobMatchNoteAttachmentsUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

export default function* browseMatchesJobsRootSaga() {
  yield all([
    takeLatest(loadJobMatchesCount.type, loadJobMatchesCountSaga),
    takeLatest(loadJobMatchesAll.type, loadJobMatchesAllSaga),
    takeLatest(loadJobAlgorithmMatches.type, loadJobAlgorithmMatchesSaga),
    takeLatest(loadJobAlgorithmMatchesCount.type, loadJobAlgorithmMatchesCountSaga),
    takeLatest(updateJobMatchStage.type, updateJobMatchStageSaga),
    takeLatest(createJobMatchNote.type, createJobMatchNoteSaga),
    takeLatest(updateJobMatchNote.type, updateJobMatchNoteSaga),
    takeLatest(deleteJobMatchNote.type, deleteJobMatchNoteSaga),
    takeLatest(updateJobMatchClientMessage.type, updateJobMatchClientMessageSaga),
    takeLatest(updateJobMatchInterviewDate.type, updateJobMatchInterviewDateSaga),
    takeLatest(clientUpdateJobMatchScheduleLink.type, clientUpdateJobMatchScheduleLinkSaga),
    takeLatest(clientUpdateJobMatchInterviewLink.type, clientUpdateJobMatchInterviewLinkSaga),
    takeLatest(clientDeleteJobMatchNoteAttachment.type, clientDeleteJobMatchNoteAttachmentSaga),
    takeLatest(clientUpdateJobMatchNoteAttachments.type, clientUpdateJobMatchNoteAttachmentsSaga),
  ]);
}
