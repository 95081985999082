import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { authorizationsStateSelector } from '@/client/redux/selectors';
import routes from '@/client/routes';
import { isRouteAvailable, getAuthRedirect } from '@/client/utils';
import { setLoginRedirect } from '@/client/redux/store/authorization.slice';

const useAuthGates = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isSignedIn, user, loginRedirect, jobIdRedirect } = useSelector(
    authorizationsStateSelector,
  );
  const [isLoading, setLoading] = useState(false);

  const { isPageAvailable, isNotFoundPage } = useMemo(() => {
    const routeConfig = routes[router.pathname];

    if (!routeConfig) {
      // Render 404 Not Found page
      return { isPageAvailable: true, isNotFoundPage: true };
    }

    if (isSignedIn === null) {
      // Wait for oAuth initialization
      return { isPageAvailable: !routeConfig.isAuthorized };
    }

    const isAvailable = isRouteAvailable(isSignedIn, user, routeConfig, router.pathname);

    if (!isAvailable) {
      if (!isSignedIn && routeConfig.isAuthorized) {
        dispatch(
          setLoginRedirect({ path: router.asPath, pathName: router.pathname, route: routeConfig }),
        );
      }

      // Perform redirect on a same render cycle to prevent UI flickering
      router.replace(getAuthRedirect(isSignedIn, user, loginRedirect, jobIdRedirect));
    }

    return { isPageAvailable: isAvailable };
  }, [router, isSignedIn, user, jobIdRedirect, loginRedirect, dispatch]);

  useEffect(() => {
    const routeConfig = routes[router.pathname];
    if (isSignedIn === null && !routeConfig.isAuthorized) {
      setLoading(true);
    }

    if (isSignedIn !== null) {
      setLoading(false);
    }
  }, [isSignedIn, router]);

  return { isPageAvailable, isNotFoundPage, isLoading };
};

export default useAuthGates;
