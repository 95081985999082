import routes from '@/client/routes';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export default function useSAppConfig() {
  const router = useRouter();

  const tabTitle = useMemo(() => routes[router.pathname]?.tabTitle || 'Alariss', [router.pathname]);

  return tabTitle;
}
