import { AxiosResponse } from 'axios';
import oAuth from '@DevimaSolutions/o-auth';
import {
  IAdminCandidatesResponse,
  IAdminEmployersResponse,
  IAdminEmailTemplatesResponse,
  IAdminImportsResponse,
  IAdminJobsResponse,
  IAdminResponse,
  ISuccessResponse,
  IUploadFileResponse,
  IAdminCompaniesResponse,
} from '@/responses';
import {
  IAdminEmployerQuery,
  IAdminJobQuery,
  IAdminUpdateVettedStatusRequest,
  IAdminUpdateMatchStatusRequest,
  IAdminImportQuery,
  IAdminUpdatePriorityStatusRequest,
  IAdminUpdateOpenToOffersStatusRequest,
  IAdminServiceUpdateJobStatusRequest,
  IAdminEmailTemplatesQuery,
  IBaseSearchQuery,
  IAdminCompanyQuery,
  IAdminInviteEmployerRequest,
  IAdminInterviewerRequest,
  IAdminRejectMatchRequest,
  IAdminAddMatchRequest,
  IEditEmployerInfoRequest,
  IAdminSetEmailTemplateRequest,
  ILoadInterviewDataAdminQuery,
  ICreateMatchTextNoteRequest,
  IUpdateEmployerFeedbackRequest,
  IMatchTextNoteAttachmentDataQuery,
  IAutoOnboardingCandidateRequest,
  IAdminUpdateRejectedMatchRequest,
} from '@/requests';
import {
  IUpdateUserStatus,
  IImportData,
  IProcessEntity,
  AdminControlEntity,
  IDeleteEntity,
  IAdminAssignToJob,
  IAdminUpdateCompanyTermsUriPayload,
  IAdminUpdateCompanyTermsSignedPayload,
  IAdminUpdateHubspotId,
  IAdminBrowseCandidatesRequest,
  IAdminUpdateReservedStatusRequest,
  IEmployerFeedback,
  IAdminUpdateMatchStagePayload,
  IUpdateMatchNoteClientVisibilityProps,
  IUpdateMatchTextNoteProps,
  IUpdateNoteAttachmentsProps,
} from '@/client/redux/types';
import { IImportAttributes } from '@/server/database';
import {
  IAdminCandidateMatchesDto,
  IAdminRequestInterviewDto,
  ICompanyDto,
  IEmployerDTO,
  IEmployerSettingsDTO,
  IEmployerShortInfoDto,
  IInterviewDateDto,
  IMatchNoteDto,
  IRejectedMatchDto,
  IInterviewLinkDto,
  IAdminMatchMessageDto,
  IScheduleLinkDto,
  IJobMatchDto,
  IJobAttributesDto,
  ICompanyTermsDTO,
} from '@/DTO';
import { ICompanyProfileFormData } from '@/client/forms/profile/employer/CompanyProfileForm/types';
import { ICreateAdminFormData } from '@/client/components/Modals/CreateAdminModal/types';
import { requestCompanyProfileData, requestCreateAdmin } from '@/client/utils';
import { InterviewStatus } from '@/enums';

const getAdminCandidateMatches = async (candidateId: string) => {
  const matchesForJob = await oAuth()
    .axios.get<IAdminCandidateMatchesDto>(`/admin/candidates/${candidateId}/matches`)
    .then((res) => res.data);

  return matchesForJob;
};

const getAdmins = async () => {
  const response = await oAuth()
    .axios.get('/admin/admins')
    .then((res: AxiosResponse<IAdminResponse>) => res.data);

  return response;
};

const getAdminCompanies = async (params: IAdminCompanyQuery) => {
  const response = await oAuth()
    .axios.get<IAdminCompaniesResponse>('/admin/companies/browse', { params })
    .then((res) => res.data);

  return response;
};

const getJobs = async (params: IAdminJobQuery) => {
  const response = await oAuth()
    .axios.get('/admin/jobs', { params })
    .then((res: AxiosResponse<IAdminJobsResponse>) => res.data);

  return response;
};

const getEmployers = async (params: IAdminEmployerQuery) => {
  const response = await oAuth()
    .axios.get('/admin/employers', { params })
    .then((res: AxiosResponse<IAdminEmployersResponse>) => res.data);

  return response;
};

const getCompanies = async (params: IBaseSearchQuery) => {
  const response = await oAuth()
    .axios.get<ICompanyDto[]>('/admin/companies', { params })
    .then((res) => res.data);

  return response;
};

const getMatchStatus = async (body: IAdminUpdateMatchStatusRequest) => {
  const response = await oAuth()
    .axios.put('/admin/matches/status', body)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const assignAdminToJob = async ({ adminId, jobId }: IAdminAssignToJob) => {
  const response = await oAuth()
    .axios.put(`/admin/jobs/${jobId}/assign-admin`, { adminId })
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const setJobIsVettedStatus = async (body: IAdminUpdateVettedStatusRequest) => {
  const response = await oAuth()
    .axios.put(`/admin/jobs/${body.id}/vetted`, body)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const setJobPriority = async (body: IAdminUpdatePriorityStatusRequest) => {
  const response = await oAuth()
    .axios.put(`/admin/jobs/${body.id}/priority`, body)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const setJobStatus = async ({ id, status }: IAdminServiceUpdateJobStatusRequest) => {
  const response = await oAuth()
    .axios.put<IJobAttributesDto>(`/admin/jobs/${id}/status`, { status })
    .then((res) => res.data);

  return response;
};

const setCandidateIsVettedStatus = async (body: IAdminUpdateVettedStatusRequest) => {
  const response = await oAuth()
    .axios.put(`/admin/candidates/${body.id}/vetted`, body)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const setCandidateOpenToOffersStatus = async ({
  id,
  ...body
}: IAdminUpdateOpenToOffersStatusRequest) => {
  const response = await oAuth()
    .axios.put(`/admin/candidates/${id}/open-to-offers`, body)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const setUserStatus = async ({ type, id, status }: IUpdateUserStatus) => {
  const response = await oAuth()
    .axios.put(`/admin/${type}/${id}/status`, { status })
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const importData = async ({ type, file }: IImportData): Promise<IImportAttributes> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await oAuth()
    .axios.post(`/admin/import/file/${type}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }: AxiosResponse<IImportAttributes>) => data);

  return response;
};

const sendCandidateConfirmationEmail = (candidateId: string) => {
  return oAuth()
    .axios.post(`/admin/candidates/${candidateId}/send-confirm`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const sendEmployerConfirmationEmail = (employerId: string) => {
  return oAuth()
    .axios.post(`/admin/employers/${employerId}/send-confirm`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const sendJobConfirmationEmail = (jobId: string) => {
  return oAuth()
    .axios.post(`/admin/jobs/${jobId}/send-confirm`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const confirmationEmailsMapping: Partial<
  Record<AdminControlEntity, (entityId: string) => Promise<ISuccessResponse>>
> = {
  [AdminControlEntity.Candidate]: sendCandidateConfirmationEmail,
  [AdminControlEntity.Employer]: sendEmployerConfirmationEmail,
  [AdminControlEntity.Job]: sendJobConfirmationEmail,
} as const;

const sendConfirmationEmail = ({ type, id }: IProcessEntity) =>
  confirmationEmailsMapping[type]?.(id);

const deleteCandidate = (candidateId: string) => {
  return oAuth()
    .axios.delete(`/admin/candidates/${candidateId}`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const uploadCandidateImage = (image: File, candidateId: string) => {
  const fd = new FormData();
  fd.append('file', image);

  return oAuth()
    .axios.put(`/admin/candidates/${candidateId}/edit/image`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<IUploadFileResponse>) => res.data.fileUri);
};

const uploadEmployerImage = (employerId: string, image: File) => {
  const fd = new FormData();
  fd.append('file', image);

  return oAuth()
    .axios.put(`/admin/employers/${employerId}/image`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<IUploadFileResponse>) => res.data.fileUri);
};

const uploadCompanyLogo = (companyId: string, image: File) => {
  const fd = new FormData();
  fd.append('file', image);

  return oAuth()
    .axios.put(`/admin/companies/${companyId}/logo`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<IUploadFileResponse>) => res.data.fileUri);
};

const deleteEmployer = (employerId: string) => {
  return oAuth()
    .axios.delete(`/admin/employers/${employerId}`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const deleteJob = (jobId: string) => {
  return oAuth()
    .axios.delete(`/admin/jobs/${jobId}`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const deleteCompany = (companyId: string) => {
  return oAuth()
    .axios.delete(`/admin/companies/${companyId}`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);
};

const deleteMapping: Record<AdminControlEntity, (entityId: string) => Promise<ISuccessResponse>> = {
  [AdminControlEntity.Candidate]: deleteCandidate,
  [AdminControlEntity.Employer]: deleteEmployer,
  [AdminControlEntity.Job]: deleteJob,
  [AdminControlEntity.Company]: deleteCompany,
} as const;

const deleteEntity = ({ type, id }: IDeleteEntity) => deleteMapping[type](id);

const getImports = async (params: IAdminImportQuery) => {
  const response = await oAuth()
    .axios.get('/admin/import', { params })
    .then((res: AxiosResponse<IAdminImportsResponse>) => res.data);

  return response;
};

const getEmployerProfileData = async (employerId: string) => {
  const data = await oAuth()
    .axios.get(`/admin/employers/${employerId}`)
    .then((res: AxiosResponse<IEmployerDTO>) => res.data);

  return data;
};

const updateEmployerSettings = async (employerId: string, data: IEditEmployerInfoRequest) => {
  const updateSettings = await oAuth()
    .axios.put<IEmployerSettingsDTO>(`/admin/employers/${employerId}`, data)
    .then((res) => res.data);

  return updateSettings;
};

const updateCompanyProfile = async (companyId: string, data: ICompanyProfileFormData) => {
  const result = await oAuth()
    .axios.put<ICompanyDto>(`/admin/companies/${companyId}`, requestCompanyProfileData(data))
    .then((res) => res.data);

  return result;
};

const updateCompanyTermsSignedStatus = async ({
  companyId,
  termsSigned,
}: IAdminUpdateCompanyTermsSignedPayload) => {
  const response = await oAuth()
    .axios.put(`/admin/companies/${companyId}/terms/signed-status`, { termsSigned })
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const updateCompanyTermsUri = async ({
  companyId,
  termsUri,
}: IAdminUpdateCompanyTermsUriPayload) => {
  const response = await oAuth()
    .axios.put(`/admin/companies/${companyId}/terms/uri`, { termsUri })
    .then((res: AxiosResponse<ICompanyTermsDTO>) => res.data);

  return response;
};

const getEmailTemplates = async (params: IAdminEmailTemplatesQuery) => {
  const response = await oAuth()
    .axios.get('/admin/email-templates', { params })
    .then((res: AxiosResponse<IAdminEmailTemplatesResponse>) => res.data);

  return response;
};

const updateEmailTemplateId = async ({ type, templateId }: IAdminSetEmailTemplateRequest) => {
  const response = await oAuth()
    .axios.put('/admin/email-templates/set-template', { type, templateId })
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const updateEmployerHubspotId = async ({ entityId, hubspotId }: IAdminUpdateHubspotId) => {
  const response = await oAuth()
    .axios.put<ISuccessResponse>(`/admin/employers/${entityId}/hubspot-id`, { hubspotId })
    .then((res) => res.data);

  return response;
};

const updateCandidateHubspotId = async ({ entityId, hubspotId }: IAdminUpdateHubspotId) => {
  const response = await oAuth()
    .axios.put<ISuccessResponse>(`/admin/candidates/${entityId}/hubspot-id`, { hubspotId })
    .then((res) => res.data);

  return response;
};

const adminBrowseCandidates = (params: IAdminBrowseCandidatesRequest) =>
  oAuth()
    .axios.get('/admin/candidates', { params })
    .then((res: AxiosResponse<IAdminCandidatesResponse>) => res.data);

const inviteEmployer = async (payload: IAdminInviteEmployerRequest) => {
  const response = await oAuth()
    .axios.post<ISuccessResponse>('/admin/employers/invite', payload)
    .then((res) => res.data);

  return response;
};

const addNewMatch = async (payload: IAdminAddMatchRequest) => {
  const response = await oAuth()
    .axios.put<ISuccessResponse>('/admin/matches/add-new', payload)
    .then((res) => res.data);

  return response;
};

const updateCandidateReservedStatus = async ({
  id,
  ...body
}: IAdminUpdateReservedStatusRequest) => {
  const response = await oAuth()
    .axios.put(`/admin/candidates/${id}/reserved`, body)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const updateMatchStage = async ({ matchId, ...rest }: IAdminUpdateMatchStagePayload) => {
  const response = await oAuth()
    .axios.put<ISuccessResponse>(`/admin/matches/${matchId}/stage`, rest)
    .then((res) => res.data);

  return response;
};

const getInterviewData = async (data: ILoadInterviewDataAdminQuery) => {
  const result = await oAuth()
    .axios.get<IAdminRequestInterviewDto>('/admin/interview', { params: data })
    .then((res) => res.data);

  return result;
};

const createInterviewer = async (payload: IAdminInterviewerRequest) =>
  oAuth()
    .axios.post<IEmployerShortInfoDto>('/admin/interviewer', payload)
    .then((res) => res.data);

const rejectMatch = async (payload: IAdminRejectMatchRequest) => {
  const response = await oAuth()
    .axios.post<IRejectedMatchDto>('/admin/matches/reject', payload)
    .then((res) => res.data);

  return response;
};

const createJobMatchNote = async ({ matchId, ...rest }: ICreateMatchTextNoteRequest) => {
  const formData = new FormData();

  rest.attachments?.map((attachment) => formData.append('files', attachment));

  formData.append('noteText', rest.noteText);

  const createdMatchNote = await oAuth()
    .axios.post<IMatchNoteDto>(`/admin/matches/${matchId}/notes/text-note`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data);

  return createdMatchNote;
};

const updateJobMatchNote = async ({ matchNoteId, text }: IUpdateMatchTextNoteProps) => {
  const updatedMatchNote = await oAuth()
    .axios.put<IMatchNoteDto>(`/admin/matches/notes/${matchNoteId}/text-note`, { text })
    .then((res) => res.data);
  return updatedMatchNote;
};

const deleteJobMatchNote = async (matchNoteId: string) => {
  const deletedMatchNote = await oAuth()
    .axios.delete<ISuccessResponse>(`/admin/matches/notes/${matchNoteId}/text-note`)
    .then((res) => res.data);

  return deletedMatchNote;
};

const editJobMatchFeedback = async (
  matchId: string,
  employerFeedback: IUpdateEmployerFeedbackRequest,
) => {
  const editedFeedback = await oAuth()
    .axios.put<IEmployerFeedback>('/admin/matches/client-feedback', { matchId, employerFeedback })
    .then((res) => res.data);

  return editedFeedback;
};

const updateInterviewStatus = async (interviewId: string, status: InterviewStatus) => {
  const response = await oAuth()
    .axios.put<ISuccessResponse>(`/admin/interview/${interviewId}/status`, { status })
    .then((res) => res.data);

  return response;
};

const updateInterviewDate = async (interviewId: string, date: Date) => {
  const response = await oAuth()
    .axios.put<IInterviewDateDto>(`/admin/interview/${interviewId}/date`, { date })
    .then((res) => res.data);

  return response;
};

const updateAdminMatchMessage = async (matchId: string, message: string) => {
  const response = await oAuth()
    .axios.put<IAdminMatchMessageDto>('/admin/matches/message', { matchId, message })
    .then((res) => res.data);

  return response;
};

const assignAdminToMatch = async (matchId: string, adminId: string | null) =>
  oAuth()
    .axios.put<ISuccessResponse>(`/admin/matches/${matchId}/assign-admin`, { adminId })
    .then((res) => res.data);

const updateJobMatchNoteClientVisibility = async ({
  clientVisibility,
  matchNoteId,
}: IUpdateMatchNoteClientVisibilityProps) => {
  const updatedMatchNote = await oAuth()
    .axios.put<IMatchNoteDto>(`/admin/matches/notes/${matchNoteId}/client-visibility`, {
      clientVisibility,
    })
    .then((res) => res.data);

  return updatedMatchNote;
};

const loadMatchNotes = async (matchId: string) => {
  const matchNotes = await oAuth()
    .axios.get<IMatchNoteDto[]>(`/admin/matches/${matchId}/notes`)
    .then((res) => res.data);

  return matchNotes;
};

const updateInterviewLink = async (interviewId: string, interviewLink: string | null) => {
  const response = await oAuth()
    .axios.put<IInterviewLinkDto>(`/interviews/request/${interviewId}/interviewLink`, {
      interviewLink,
    })
    .then((res) => res.data);

  return response;
};

const updateScheduleLink = async (interviewId: string, scheduleLink: string | null) => {
  const response = await oAuth()
    .axios.put<IScheduleLinkDto>(`/interviews/request/${interviewId}/scheduleLink`, {
      scheduleLink,
    })
    .then((res) => res.data);

  return response;
};

const loadJobMatch = async (matchId: string) => {
  const jobMatch = await oAuth()
    .axios.get<IJobMatchDto>(`/admin/matches/${matchId}`)
    .then((res) => res.data);

  return jobMatch;
};

const deleteJobMatchNoteAttachment = async ({
  attachmentId,
  matchNoteId,
}: IMatchTextNoteAttachmentDataQuery) =>
  oAuth()
    .axios.delete<ISuccessResponse>(`/admin/matches/notes/${matchNoteId}/${attachmentId}`)
    .then((res) => res.data);

const updateJobMatchNoteAttachments = async ({
  matchNoteId,
  attachments,
}: IUpdateNoteAttachmentsProps) => {
  const formData = new FormData();

  attachments.map((attachment) => formData.append('files', attachment));

  formData.append('matchNoteId', matchNoteId);

  const response = oAuth()
    .axios.post<IMatchNoteDto>(`/admin/matches/notes/${matchNoteId}/attachments`, formData)
    .then((res) => res.data);
  return response;
};

const createCandidate = (data: IAutoOnboardingCandidateRequest) => {
  return oAuth()
    .axios.post<ISuccessResponse>(`/admin/candidates/create`, data)
    .then((res) => res.data);
};

const updateRejectedMatch = async (payload: IAdminUpdateRejectedMatchRequest) => {
  const response = await oAuth()
    .axios.put<IRejectedMatchDto>('/admin/matches/reject', payload)
    .then((res) => res.data);

  return response;
};

const createAdmin = (data: ICreateAdminFormData) => {
  const fd = requestCreateAdmin(data);

  return oAuth()
    .axios.post<ISuccessResponse>('/admin/create', fd)
    .then((res) => res.data);
};

export default {
  getAdminCandidateMatches,
  getAdminCompanies,
  getAdmins,
  getJobs,
  getEmployers,
  getCompanies,
  getMatchStatus,
  getEmployerProfileData,
  getImports,
  assignAdminToJob,
  setJobIsVettedStatus,
  setJobPriority,
  setCandidateIsVettedStatus,
  setUserStatus,
  importData,
  sendConfirmationEmail,
  deleteEntity,
  uploadCandidateImage,
  uploadEmployerImage,
  uploadCompanyLogo,
  setCandidateOpenToOffersStatus,
  updateEmployerSettings,
  updateCompanyProfile,
  updateCompanyTermsSignedStatus,
  updateCompanyTermsUri,
  setJobStatus,
  getEmailTemplates,
  updateEmailTemplateId,
  updateEmployerHubspotId,
  updateCandidateHubspotId,
  adminBrowseCandidates,
  inviteEmployer,
  addNewMatch,
  updateCandidateReservedStatus,
  updateMatchStage,
  getInterviewData,
  createInterviewer,
  rejectMatch,
  createJobMatchNote,
  updateJobMatchNote,
  deleteJobMatchNote,
  editJobMatchFeedback,
  updateInterviewStatus,
  updateInterviewDate,
  updateAdminMatchMessage,
  assignAdminToMatch,
  updateJobMatchNoteClientVisibility,
  loadMatchNotes,
  updateInterviewLink,
  updateScheduleLink,
  loadJobMatch,
  deleteJobMatchNoteAttachment,
  updateJobMatchNoteAttachments,
  createCandidate,
  updateRejectedMatch,
  createAdmin,
};
