import { messages } from '@/client/constants';
import {
  loadCandidateAvailableJobs,
  loadInterviewAvailableEmployers,
  requestDefaultInterview,
  requestInterview,
  resendInterviewEmail,
} from '@/client/redux/actions';
import {
  setEmployers,
  setJobs,
  setLoading,
  setSubmitted,
} from '@/client/redux/store/request-interview.slice';
import { employerService, jobsService, interviewsService } from '@/client/services';
import { handleRequestError } from '@/client/utils';
import { IInterviewDto, IEmployerShortInfoDto, IJobShortInfoDto } from '@/DTO';
import { ICandidateDataQuery } from '@/requests';
import oAuth from '@DevimaSolutions/o-auth';
import toast from 'react-hot-toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  updateCandidateInterviewStatus,
  updateJobInterviewStatus,
} from '@/client/redux/store/my-jobs.slice';
import {
  IActionWithPayload,
  IRequestDefaultInterviewPayload,
  IRequestInterviewPayload,
} from '../types';

function* loadCandidateAvailableJobsSaga({ payload }: IActionWithPayload<ICandidateDataQuery>) {
  try {
    const jobs: IJobShortInfoDto[] = yield call(jobsService.getCandidateAvailableJobs, payload);

    yield put(setJobs(jobs));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadInterviewAvailableEmployersSaga() {
  try {
    const employers: IEmployerShortInfoDto[] = yield call(
      employerService.getInterviewAvailableEmployers,
    );

    yield put(setEmployers(employers));
  } catch (error) {
    handleRequestError(error);
  }
}

function* requestInterviewSaga({ payload }: IActionWithPayload<IRequestInterviewPayload>) {
  try {
    yield put(setLoading(true));

    const { id, status, timezone, ...rest }: IInterviewDto = yield call(
      employerService.requestInterview,
      {
        candidateId: payload.candidateId,
        message: payload.message,
        jobId: payload.job.id,
        employerId: payload.employer.id,
        timezone: payload.timezone.tzName,
        scheduleOption: payload.scheduleOption,
        scheduleLink: payload.scheduleLink,
        matchStage: payload.matchStage,
      },
    );

    yield put(
      updateCandidateInterviewStatus({
        interviewId: id,
        status,
      }),
    );

    yield put(
      updateJobInterviewStatus({
        interviewId: id,
        status,
      }),
    );

    yield put(setSubmitted(true));

    oAuth().updateUser({ timezone });
    toast.success(messages.interviewRequested);
    payload.onSuccess?.({ id, status, timezone, ...rest });
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* resendInterviewEmailSaga({ payload }: IActionWithPayload<string>) {
  try {
    yield put(setLoading(true));

    yield call(interviewsService.resendInterviewEmail, payload);

    toast.success(messages.interviewEmailHasBeenSent);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* requestDefaultInterviewSaga({
  payload: { candidateId, jobId, matchStage, onSuccess, onError },
}: IActionWithPayload<IRequestDefaultInterviewPayload>) {
  try {
    const createdInterview: IInterviewDto = yield call(interviewsService.requestDefaultInterview, {
      candidateId,
      jobId,
      matchStage,
    });

    onSuccess?.(createdInterview);
  } catch (error) {
    handleRequestError(error);
    onError?.();
  }
}

export default function* requestInterviewRootSaga() {
  yield all([
    takeLatest(loadCandidateAvailableJobs.type, loadCandidateAvailableJobsSaga),
    takeLatest(loadInterviewAvailableEmployers.type, loadInterviewAvailableEmployersSaga),
    takeLatest(requestInterview.type, requestInterviewSaga),
    takeLatest(resendInterviewEmail.type, resendInterviewEmailSaga),
    takeLatest(requestDefaultInterview.type, requestDefaultInterviewSaga),
  ]);
}
