import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toaster: {
    '& .toaster-container': {
      maxWidth: theme.spacing(164),
      borderRadius: theme.spacing(10.5),
      padding: 0,
      background: 'transparent',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'auto',
        minWidth: theme.spacing(516.5),
        borderRadius: theme.spacing(0),
      },
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10, 10, 11),
    borderRadius: theme.spacing(10.5),
    transition: 'opacity 2s',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(9, 20),
      borderRadius: theme.spacing(0),
    },
  },
  toastBarHeader: { display: 'flex', alignItems: 'center' },
  info: {
    backgroundColor: theme.palette.primary.light,
    '& > button': { backgroundColor: theme.palette.lightBlue },
  },
  success: {
    backgroundColor: theme.palette.success.light,
    '& > button': { backgroundColor: theme.palette.success.main },
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    '& > button': { backgroundColor: theme.palette.warning.main },
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    '& > button': { backgroundColor: theme.palette.error.main },
  },
  message: {
    whiteSpace: 'pre-line',
    marginLeft: theme.spacing(7.5),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.md,
    color: theme.palette.midnight,
    [theme.breakpoints.up('md')]: { marginLeft: theme.spacing(9.5) },
    '& > div': {
      margin: theme.spacing(0),
    },
  },
  icon: { minWidth: theme.spacing(17), maxHeight: theme.spacing(17) },
  button: {
    width: 'fit-content',
    marginTop: theme.spacing(7.5),
    padding: theme.spacing(3.5, 22.5),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: '20px',
    borderRadius: theme.spacing(2),
    color: theme.palette.white,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(3.5, 11),
    },
  },
}));

export default useStyles;
