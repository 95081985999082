enum CandidateDegree {
  Associate,
  Bachelor,
  Master,
  MBA,
  Doctorate,
  Certificate,
  HighSchool,
  Other,
}

export default CandidateDegree;
