import { CandidateExperienceLevel } from '@/enums';

export const experienceLevelOptions = [
  {
    label: 'Entry-level',
    value: CandidateExperienceLevel.Entry,
    caption: '(0-3 years of relevant experience)',
  },
  {
    label: 'Intermediate',
    value: CandidateExperienceLevel.Intermediate,
    caption: '(4-9 years of relevant experience)',
  },
  {
    label: 'Expert',
    value: CandidateExperienceLevel.Expert,
    caption: '(10+ years of relevant experience)',
  },
];
