import { WithoutNever } from '@/common-types';
import { UserRole } from '@/enums';

export enum ButtonType {
  ControlButton,
  ActionButton,
}

export interface IBaseButton<T extends ButtonType> {
  label: string;
  type: T;
  redirect: T extends ButtonType.ControlButton ? string : never;
  onClick: T extends ButtonType.ActionButton ? () => void : never;
  isCustomButton?: boolean;
  isPopoverButton?: boolean;
  hideOnActiveRoute?: boolean;
  showNotification?: boolean;
  isOutsideRedirect?: boolean;
  shouldBeShown?: (props: IShouldBeShownProps) => boolean;
  isCurrentPage?: boolean;
}

export type IControlButton = WithoutNever<IBaseButton<ButtonType.ControlButton>>;
export type IActionButton = WithoutNever<IBaseButton<ButtonType.ActionButton>>;

export type IPageButton = IControlButton | IActionButton;

export interface IShouldBeShownProps {
  role: UserRole;
  isOnboardingFinished: boolean;
  path: string;
  hasPostedJob?: boolean;
}
