import { createSelector } from 'reselect';
import { IState } from '@/client/redux/types';

export const postJobSelector = ({ postJob }: IState) => postJob;

export const postJobDataSelector = createSelector(postJobSelector, (state) => state.data);

export const postJobInternalInfoSelector = createSelector(
  postJobDataSelector,
  (state) => state?.internalInfo,
);

export const postJobCitiesSelector = createSelector(postJobSelector, (state) => state.cities);

export const postJobRegionsSelector = createSelector(postJobSelector, (state) => state.regions);

export const postJobIsFetching = createSelector(postJobSelector, (state) => state.isFetching);

export const postJobLoadingSelector = createSelector(postJobSelector, (state) => state.isLoading);
