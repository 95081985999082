import { IButtonProps } from '@/client/components/Button/types';

export enum LinkButtons {
  Base,
  Default,
  Custom,
}

export interface ILinkButtonProps extends IButtonProps {
  href: string;
  buttonType?: LinkButtons;
  target?: string;
}
