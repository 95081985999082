import { Link } from '@material-ui/core';
import { urlWithSchema } from '@/client/utils';
import clsx from 'clsx';
import useStyles from '../styles';
import { BaseLinkProps } from '../types';
import useCustomLinks from '../useCustomLinks';

export const CustomLink = ({
  href,
  children,
  className,
  stopPropagation,
  disabled,
  target = '_blank',
  rel = 'noopener noreferrer',
  ...props
}: BaseLinkProps): JSX.Element => {
  const { customLink } = useStyles();
  const { handlePropagation } = useCustomLinks(stopPropagation, disabled);

  return (
    <Link
      href={urlWithSchema(href)}
      onClick={handlePropagation}
      className={clsx({ [customLink]: !className }, className)}
      target={target}
      rel={rel}
      {...props}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
