import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    minWidth: theme.spacing(10),
    textTransform: 'capitalize',
    height: 48,
    fontSize: theme.typography.fonts.size.md,
    lineHeight: theme.typography.fonts.lineHeight.md,
    borderRadius: theme.spacing(2),
    fontWeight: 500,
    boxShadow: 'none !important',
    '&.MuiButton-outlined': {
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: '#175D9C',
      },
      '&:disabled': {
        borderColor: '#B5B7C4',
        color: '#B5B7C4',
      },
    },
    '&.MuiButton-contained': {
      background: theme.palette.primary.main,
      '&:hover': {
        background: '#175D9C',
      },
      '&:active': {
        background: '#328FE1',
      },
      '&:disabled': {
        background: '#B5B7C4',
        color: '#FFF',
      },
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  loading: {
    position: 'absolute',
    right: 10,
    color: '#fff',
  },
}));

export default useStyles;
