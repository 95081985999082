import GlobalLayout from '@/client/layouts/GlobalLayout';
import NotFound from '@/client/pages-content/NotFound';
import { FC } from 'react';
import { IBaseGateProps } from '../types';
import useAuthGates from './useAuthGates';

export const AuthGates: FC<IBaseGateProps> = ({ children }) => {
  const { isPageAvailable, isNotFoundPage, isLoading } = useAuthGates();

  if (isLoading || !isPageAvailable) {
    return null;
  }

  if (isNotFoundPage) {
    return (
      <GlobalLayout>
        <NotFound />
      </GlobalLayout>
    );
  }

  return <>{children}</>;
};

export default AuthGates;
