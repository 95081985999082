import { EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import { put, all, call, take, takeLatest, select } from 'redux-saga/effects';
import oAuth from '@DevimaSolutions/o-auth';
import Router from 'next/router';
import toast from 'react-hot-toast';

import { IUserDto } from '@/DTO';
import { UserRole } from '@/enums';
import {
  ILinkedInAuthorizedResponse,
  ILinkedInUser,
  ISuccessResponse,
  IUserRegisterResponse,
} from '@/responses';
import {
  loadLinkedInUser,
  loginWithLinkedIn,
  registerUser,
  resetPassword,
  sendForgotEmail,
  signupWithLinkedIn,
  checkUserExists,
  loadLinkedInProfileData,
} from '@/client/redux/actions';
import {
  setIsForgotEmailSent,
  setjobIdRedirect,
  setLoading,
  setLoginRedirect,
  setUserExistsError,
  storeAuthorization,
} from '@/client/redux/store/authorization.slice';
import { authorizationService, createAuthOptions } from '@/client/services';
import { getAuthRedirect, handleRequestError, heapActions } from '@/client/utils';
import { messages } from '@/client/constants';
import {
  isSignedInSelector,
  jobIdRedirectSelector,
  loginRedirectSelector,
} from '@/client/redux/selectors';
import { IScraperProfileData } from '@/server/types';
import {
  IActionWithPayload,
  IAuthChannelData,
  ICheckUserExists,
  ILinkedInAuthorization,
  ILoadLinkedInProfileData,
  ILoadLinkedInUser,
  ILoginRedirect,
  IRegisterUserData,
} from '../types';

function authorizationEventsChannel() {
  return eventChannel<IAuthChannelData>((emit) => {
    const unsubscribeAuthStateChanged = oAuth().onAuthStateChanged(async (authCallback) => {
      const user = await authCallback.getUser<IUserDto>();
      emit({ isSignedIn: authCallback.isSignedIn(), user, needRedirect: true });
    });

    const unsubscribeUserChanged = oAuth().onUserChanged(async (authCallback) => {
      const user = await authCallback.getUser<IUserDto>();
      emit({ isSignedIn: authCallback.isSignedIn(), user, needRedirect: false });
    });

    return () => {
      unsubscribeAuthStateChanged();
      unsubscribeUserChanged();
    };
  });
}

function* authorizationSaga() {
  // Wait until auth is initialized
  yield new Promise((resolve) => oAuth(createAuthOptions()).oncePendingActionComplete(resolve));

  // Synchronize app storage with oAuth storage on refresh page
  const user: IUserDto = yield oAuth().getUser();
  yield put(storeAuthorization({ isSignedIn: oAuth().isSignedIn(), user }));

  // Then create channels for store syncronization
  const authorizationChannel: EventChannel<IAuthChannelData> = yield call(
    authorizationEventsChannel,
  );

  while (true) {
    try {
      const authData: IAuthChannelData = yield take(authorizationChannel);
      const isSignedIn: boolean | null = yield select(isSignedInSelector);
      const jobIdRedirect: string | null = yield select(jobIdRedirectSelector);

      yield put(storeAuthorization(authData));

      // Not process redirect on refresh token
      if (authData.needRedirect && authData.isSignedIn !== isSignedIn) {
        heapActions.handleAuthChange(authData);
        const loginRedirect: ILoginRedirect | null = yield select(loginRedirectSelector);
        Router.replace(
          getAuthRedirect(authData.isSignedIn, authData.user, loginRedirect, jobIdRedirect),
        );
      }

      if (!authData.isSignedIn) {
        yield put(setLoginRedirect(null));
        yield put(setjobIdRedirect(null));
      }
    } catch (error) {
      handleRequestError(error);
    }
  }
}

function* registerUserSaga({ payload }: IActionWithPayload<IRegisterUserData>): SagaIterator {
  try {
    const authData: IUserRegisterResponse = yield call(
      authorizationService.registerCustomer,
      payload.data,
      payload.linkedInProfileData,
      payload.dataToken,
    );

    oAuth().setAuth(authData.user, { ...authData.tokens });
    payload.onSuccess?.();
  } catch (error) {
    payload.onError?.();
    handleRequestError(error);
  }
}

function* loginWithLinkedInSaga(action: IActionWithPayload<ILinkedInAuthorization>) {
  try {
    const authData: ILinkedInAuthorizedResponse = yield call(
      authorizationService.loginWithLinkedIn,
      action.payload.accessToken,
    );

    oAuth().setAuth(authData.user, { ...authData.tokens });
  } catch (error) {
    handleRequestError(error);
  }
}

function* signupWithLinkedInSaga(
  action: IActionWithPayload<{ role: UserRole; accessToken: string }>,
) {
  try {
    const authData: ILinkedInAuthorizedResponse = yield call(
      authorizationService.signupWithLinkedIn,
      action.payload.accessToken,
      action.payload.role,
    );

    oAuth().setAuth(authData.user, { ...authData.tokens });
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadLinkedInUserSaga({ payload }: IActionWithPayload<ILoadLinkedInUser>) {
  try {
    const user: ILinkedInUser = yield call(
      authorizationService.getLinkedInUser,
      payload.code,
      payload.redirect,
    );

    if (user.userExists) {
      yield put(loginWithLinkedIn({ accessToken: user.accessToken }));
      return;
    }

    yield Router.push({
      query: {
        ...user,
      },
      pathname: '/signup',
    });
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadLinkedInProfileDataSaga({ payload }: IActionWithPayload<ILoadLinkedInProfileData>) {
  try {
    yield put(setLoading(true));

    const user: IScraperProfileData = yield call(
      authorizationService.getLinkedInProfileData,
      payload,
    );

    payload.onSuccess?.(user ?? null);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* sendForgotEmailSaga(action: IActionWithPayload<{ email: string }>) {
  try {
    yield call(authorizationService.sendForgotEmail, action.payload.email);
    yield put(setIsForgotEmailSent({ isSent: true }));
  } catch (error) {
    handleRequestError(error);
  }
}

function* resetPasswordSaga(action: IActionWithPayload<{ token: string; password: string }>) {
  try {
    const response: ISuccessResponse = yield call(
      authorizationService.resetPassword,
      action.payload.token,
      action.payload.password,
    );
    Router.push('/login');
    toast.success(response.message);
  } catch (error) {
    handleRequestError(error);
  }
}

function* checkUserExistsSaga({ payload }: IActionWithPayload<ICheckUserExists>) {
  try {
    const userExists: boolean = yield call(authorizationService.checkUserExists, payload.email);

    if (!userExists) {
      yield put(setUserExistsError(null));
      payload.onSuccess?.();
      return;
    }

    yield put(setUserExistsError(messages.userAlreadyExists));
    payload.onError?.();
  } catch (error) {
    handleRequestError(error);
    payload.onError?.();
  }
}

export default function* authorizationRootSaga() {
  yield all([
    call(authorizationSaga),
    takeLatest(loginWithLinkedIn.type, loginWithLinkedInSaga),
    takeLatest(registerUser.type, registerUserSaga),
    takeLatest(signupWithLinkedIn.type, signupWithLinkedInSaga),
    takeLatest(loadLinkedInUser.type, loadLinkedInUserSaga),
    takeLatest(loadLinkedInProfileData.type, loadLinkedInProfileDataSaga),
    takeLatest(sendForgotEmail.type, sendForgotEmailSaga),
    takeLatest(resetPassword.type, resetPasswordSaga),
    takeLatest(checkUserExists.type, checkUserExistsSaga),
  ]);
}
