import { ICompanyDto } from '@/DTO';
import oAuth from '@DevimaSolutions/o-auth';
import { requestCompanyProfileData } from '@/client/utils';
import { ICompanyProfileFormData } from '@/client/forms/profile/employer/CompanyProfileForm/types';

const getCompanyProfileData = async (companyId?: string) => {
  const url = companyId ? `/admin/companies/${companyId}` : '/profile/employer/company-profile';

  const data = await oAuth()
    .axios.get<ICompanyDto>(url)
    .then((res) => res.data);

  return data;
};

const updateCompanyProfile = (data: ICompanyProfileFormData) => {
  const result = oAuth()
    .axios.put<ICompanyDto>('/profile/employer/company-profile', requestCompanyProfileData(data))
    .then((res) => res.data);

  return result;
};

export default {
  getCompanyProfileData,
  updateCompanyProfile,
};
