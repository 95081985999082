import { MainContentWrapper, LinkButton, LinkButtons } from '@/client/components';
import useStyles from './styles';

const NotFound = (): JSX.Element => {
  const classes = useStyles();

  return (
    <MainContentWrapper className={classes.container}>
      <span className={classes.code}>404</span>
      <p className={classes.title}>This page could not be found!</p>
      <LinkButton href="/" buttonType={LinkButtons.Custom} className={classes.button}>
        Go Home
      </LinkButton>
    </MainContentWrapper>
  );
};

export default NotFound;
