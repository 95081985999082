import { IBaseApplicantDto } from '@/DTO';
import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, IApplyToJobState } from '../types';

const initialState: IApplyToJobState = {
  isLoading: false,
  isSubmited: false,
  applicant: null,
};

export const applyToJobSlice = createSlice({
  name: 'applyToJob',
  initialState,
  reducers: {
    setLoading: (state, action: IActionWithPayload<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitted: (state, action: IActionWithPayload<boolean>) => {
      state.isSubmited = action.payload;
    },
    setApplicant: (state, action: IActionWithPayload<IBaseApplicantDto | null>) => {
      state.applicant = action.payload;
    },
  },
});

export const { setLoading, setSubmitted, setApplicant } = applyToJobSlice.actions;

export default applyToJobSlice.reducer;
