import rootSaga from '@/client/redux/saga';
import env from '@/utils/env/env';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {
  importData,
  loadLinkedInProfileData,
  loadLinkedInUser,
  postJob,
  registerUser,
  saveCustomerOnboardingData,
  setCandidateIsVettedStatus,
  updateCandidateProfileSettings,
  uploadCandidateProfileAvatar,
  uploadEmployerProfileAvatar,
  employerOnboarding,
  checkUserExists,
  setMatchStatus,
  uploadCompanyProfileLogo,
  rejectMatch,
  updateJobMatchInterviewDate,
  createJobMatchNote,
  adminCreateJobMatchNote,
  updateCandidateProfileUrl,
  updateRejectedMatch,
  createAdmin,
} from '@/client/redux/actions';
import { IState } from '@/client/redux/types';
import adminSlice from './admin.slice';
import applyToJobSlice from './apply-to-job.slice';
import authSlice from './authorization.slice';
import browseCandidateSlice from './browse-candidate.slice';
import browseJobSlice from './browse-job.slice';
import employerSlice from './employer.slice';
import matchesSlice from './matches.slice';
import myJobsSlice from './my-jobs.slice';
import onboardingSlice from './onboarding.slice';
import postJobSlice, { postJobIgnoredPaths } from './post-job.slice';
import profileSlice from './profile.slice';
import requestInterviewSlice from './request-interview.slice';
import settingsSlice, { settingsIgnoredPaths } from './settings.slice';
import companiesSlice from './companies.slice';
import matchesJobsSlice from './matches-jobs.slice';

const sagaMiddleware = createSagaMiddleware();
let store: ReturnType<typeof configureStoreWithPreloadedState> | null = null;

const configureStoreWithPreloadedState = (preloadedState?: IState) => {
  const result = configureStore({
    reducer: {
      auth: authSlice,
      onboarding: onboardingSlice,
      postJob: postJobSlice,
      profile: profileSlice,
      settings: settingsSlice,
      myJobs: myJobsSlice,
      browseJob: browseJobSlice,
      browseCandidate: browseCandidateSlice,
      employer: employerSlice,
      applyToJob: applyToJobSlice,
      requestInterview: requestInterviewSlice,
      admin: adminSlice,
      matches: matchesSlice,
      companies: companiesSlice,
      matchesJobs: matchesJobsSlice,
    },
    devTools: !env.isProdEnv(),
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        // Disable redux-thunk
        thunk: false,
        serializableCheck: {
          ignoredActions: [
            registerUser.type,
            loadLinkedInUser.type,
            loadLinkedInProfileData.type,
            saveCustomerOnboardingData.type,
            employerOnboarding.type,
            uploadEmployerProfileAvatar.type,
            uploadCompanyProfileLogo.type,
            uploadCandidateProfileAvatar.type,
            updateCandidateProfileSettings.type,
            importData.type,
            postJob.type,
            setCandidateIsVettedStatus.type,
            checkUserExists.type,
            setMatchStatus.type,
            rejectMatch.type,
            updateJobMatchInterviewDate.type,
            createJobMatchNote.type,
            adminCreateJobMatchNote.type,
            updateCandidateProfileUrl.type,
            updateRejectedMatch.type,
            createAdmin.type,
          ],
          ignoredActionPaths: [
            ...postJobIgnoredPaths,
            ...settingsIgnoredPaths,
            'payload.onSuccess',
            'payload.onError',
          ],
          ignoredPaths: [
            ...postJobIgnoredPaths,
            ...settingsIgnoredPaths,
            'payload.onSuccess',
            'payload.onError',
          ],
        },
        // Add redux-saga
      }).concat(sagaMiddleware);

      return middleware;
    },
  });

  sagaMiddleware.run(rootSaga);

  return result;
};

// This method can be used to cratea new store on Server-Side
export const initializeStore = (preloadedState?: IState) => {
  let reinitializedStore = store ?? configureStoreWithPreloadedState(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    reinitializedStore = configureStoreWithPreloadedState({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = null;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return reinitializedStore;
  }
  // Create the store once in the client
  if (!store) {
    store = reinitializedStore;
  }

  return reinitializedStore;
};
