import { IUserDto } from '@/DTO';
import { UserRole } from '@/enums';
import { isRouteAvailable } from '@/client/utils';
import { ILoginRedirect } from '@/client/redux/types';
import Router from 'next/router';

const onboardingRedirects: Partial<Record<UserRole, string>> = {
  [UserRole.Candidate]: '/onboarding/candidate',
  [UserRole.MasterEmployer]: '/onboarding/employer',
  [UserRole.AdminEmployer]: '/post-job',
  [UserRole.Employer]: '/post-job',
};

const getBaseRedirect = (
  isSignedIn: boolean,
  user: IUserDto | null,
  loginRedirect?: ILoginRedirect | null,
) => {
  if (
    loginRedirect &&
    isRouteAvailable(isSignedIn, user, loginRedirect.route, loginRedirect.pathName)
  ) {
    return loginRedirect.path;
  }

  return '/';
};

const getAuthRedirect = (
  isSignedIn: boolean,
  user: IUserDto | null,
  loginRedirect?: ILoginRedirect | null,
  jobIdRedirect?: string | null,
) => {
  if (typeof window !== 'undefined' && Router.pathname.startsWith('/r/')) {
    return Router.asPath;
  }

  if (!isSignedIn) {
    return '/login';
  }

  if (user?.role === UserRole.Admin) {
    return getBaseRedirect(isSignedIn, user, loginRedirect);
  }

  if (user && !user.isOnboardingFinished) {
    return onboardingRedirects[user.role] ?? '/';
  }

  if (
    user &&
    !user.hasPostedJob &&
    [UserRole.MasterEmployer, UserRole.Employer, UserRole.AdminEmployer].includes(user.role)
  ) {
    return '/post-job';
  }

  if (jobIdRedirect && user?.role === UserRole.Candidate) {
    return `/jobs/${jobIdRedirect}`;
  }

  return getBaseRedirect(isSignedIn, user, loginRedirect);
};

export default getAuthRedirect;
