import { IBaseJobInfo } from '@/client/components/Modals/ApplyToJobModal/types';
import { IState } from '@/client/redux/types';
import { IBrowseJobQuery } from '@/requests';
import { createSelector } from 'reselect';

export const browseJobSelector = ({ browseJob }: IState) => browseJob;

export const browseJobListSelector = createSelector(
  browseJobSelector,
  ({ jobs, isLoading, hasMore }) => ({
    jobs,
    isLoading,
    hasMore,
  }),
);

export const browseJobListQuerySelector = createSelector(
  browseJobSelector,
  ({ page, perPage, filters }) => ({ page, perPage, ...filters } as IBrowseJobQuery),
);

export const browseJobFiltersSelector = createSelector(browseJobSelector, ({ filters }) => filters);

export const browseJobByIdSelector = (jobId: string | null) =>
  createSelector(browseJobSelector, ({ jobs }) => jobs.find(({ id }) => id === jobId) ?? null);

export const detailedJobSelector = createSelector(
  browseJobSelector,
  ({ detailedJob, isLoadingJobDetail }) => ({
    detailedJob,
    isLoadingJobDetail,
  }),
);
export const baseInfoAboutJobForApply = (jobId: string | null) =>
  createSelector(detailedJobSelector, ({ detailedJob }): IBaseJobInfo | null => {
    if (!detailedJob || !jobId) {
      return null;
    }
    return {
      id: jobId,
      jobTitle: detailedJob.title,
      companyInfoLogo: detailedJob.companyInfo.logoUri,
      companyInfoName: detailedJob.companyInfo.name,
    };
  });
