import React, { forwardRef } from 'react';
import { Button as CoreButton, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import { IButtonProps } from './types';
import useButton from './useButton';

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      disabled,
      loading = false,
      className,
      icon,
      color = 'primary',
      variant = 'contained',
      onClick,
      stopPropagation,
      ...props
    }: IButtonProps,
    ref,
  ): JSX.Element => {
    const classes = useStyles();
    const { handleClick } = useButton({
      stopPropagation,
      onClick,
    });

    return (
      <CoreButton
        className={clsx(classes.wrapper, className)}
        disabled={disabled || loading}
        ref={ref}
        color={color}
        variant={variant}
        onClick={handleClick}
        {...props}
      >
        {children}
        {icon ?? null}
        {loading && <CircularProgress className={classes.loading} size={30} />}
      </CoreButton>
    );
  },
);

export default Button;
