import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  defaultImageWrapper: {
    position: 'relative',
    display: 'flex',
  },
  userInitials: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '20px',
    },
  },
  profileImg: {
    borderRadius: '50%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    height: 'fit-content',
    boxShadow: `0 0 0 1px ${theme.palette.white}, 0 0 0 2px ${theme.palette.border}`,
  },
}));

export default useStyles;
