enum PostJobRequests {
  BasicInfo,
  UpdateBasicInfo,
  AdminBasicInfo,
  AdminUpdateBasicInfo,
  DesiredSkills,
  JobDescription,
  Compensation,
  InternalInfo,
}

export default PostJobRequests;
