import { IVettedFilterLiteralValues } from '@/client/components/Filters/types';
import { ICreateAdminFormData } from '@/client/components/Modals/CreateAdminModal/types';
import { IEmployerFeedbackDTO, IUserShortInfoDto, IMatchNoteDto } from '@/DTO';
import { InterviewStatus, MatchStage } from '@/enums';
import {
  IAdminCandidatesFilters,
  IAdminUpdateHubspotIdRequest,
  ICreateMatchTextNoteRequest,
  IMatchNoteDataQuery,
  IImportQuery,
  IAdminUpdateVettedRequest,
  IAdminUpdateJobStatusRequest,
  IAdminMatchStageRequest,
  IAdminRejectMatchRequest,
  IAdminUpdateMatchStatusRequest,
  IAdminUpdateOpenToOffersRequest,
  IAdminUpdatePriorityRequest,
  IAdminUpdateUserStatusRequest,
  IAdminUpdateTermsUrlRequest,
  IAdminUpdateTermsSignedRequest,
  IAdminSetEmailTemplateRequest,
  IBasePaginationQuery,
  IUpdateMatchBaseRequest,
  IAdminUpdateReservedRequest,
  IMatchDataQuery,
  ICompanyDataQuery,
  ICandidateDataQuery,
  IJobDataQuery,
  IInterviewActionQuery,
  IUpdateInterviewDateRequest,
  IUpdateMatchTextNoteRequest,
  IUpdateMatchNoteClientVisibilityRequest,
  IUpdateInterviewLinkRequest,
  IUpdateScheduleLinkRequest,
  IMatchTextNoteAttachmentDataQuery,
  IAutoOnboardingCandidateRequest,
  IAdminUpdateRejectedMatchRequest,
} from '@/requests';
import { IBasicRequest } from './basic-request';

export interface IUpdateIsVettedStatus extends IBasicRequest, IAdminUpdateVettedRequest {
  id: string;
}

export interface IUpdateJobStatus extends IBasicRequest, IAdminUpdateJobStatusRequest {
  id: string;
}

export interface IAdminUpdateMatchStagePayload extends IAdminMatchStageRequest, IBasicRequest {
  matchId: string;
}

export interface IAdminRejectMatchPayload extends IAdminRejectMatchRequest, IBasicRequest {
  matchId: string;
}

export interface IAdminUpdateRejectedMatchPayload
  extends IAdminUpdateRejectedMatchRequest,
    IBasicRequest {}

export interface IAdminUpdateMatchStatusPayload
  extends IBasicRequest,
    IAdminUpdateMatchStatusRequest {}

export interface IUpdateOpenToOffersStatus extends IBasicRequest, IAdminUpdateOpenToOffersRequest {
  id: string;
}

export interface IUpdatePriority extends IAdminUpdatePriorityRequest {
  id: string;
}

export interface IUpdateUserStatus extends IBasicRequest, IAdminUpdateUserStatusRequest {
  id: string;
  type: 'candidates' | 'employers';
}

export interface IImportData extends IImportQuery {
  file: File;
}

export enum AdminControlEntity {
  Candidate,
  Employer,
  Job,
  Company,
}

export interface IProcessEntity {
  type: AdminControlEntity;
  id: string;
}

export interface IDeleteEntity extends IBasicRequest, IProcessEntity {}

export interface IAdminAssignToJob {
  adminId: string | null;
  jobId: string;
}

export interface IAdminUpdateCompanyTermsUriPayload
  extends IBasicRequest,
    IAdminUpdateTermsUrlRequest,
    ICompanyDataQuery {}

export interface IAdminUpdateCompanyTermsSignedPayload
  extends IAdminUpdateTermsSignedRequest,
    ICompanyDataQuery {}

export interface IAdminEmailTemplateUpdate extends IBasicRequest, IAdminSetEmailTemplateRequest {}

export interface IAdminCandidateLoadMatchesProps extends IBasicRequest, ICandidateDataQuery {}

export interface IAdminJobLoadMatchesProps extends IBasicRequest, IJobDataQuery {}

export interface IAdminUpdateHubspotId extends IBasicRequest, IAdminUpdateHubspotIdRequest {
  entityId: string;
}

export interface IBuildAdminCandidateFiltersQuery
  extends Omit<IAdminCandidatesFilters, 'isVetted'> {
  isVetted?: IVettedFilterLiteralValues;
}

export interface IAdminBrowseCandidatesRequest
  extends IBuildAdminCandidateFiltersQuery,
    IBasePaginationQuery {}

export interface IAdminAddNewMatch extends IUpdateMatchBaseRequest, IBasicRequest {
  message: string;
  stage: MatchStage;
  sendEmail?: boolean;
}

export interface IUpdateReservedStatus extends IAdminUpdateReservedRequest, IBasicRequest {
  id: string;
}

export interface IAdminUpdateReservedStatusRequest extends IAdminUpdateReservedRequest {
  id: string;
}

export interface ICreateJobMatchNoteProps extends IBasicRequest, ICreateMatchTextNoteRequest {}

export interface IDeleteJobMatchNoteProps extends IMatchNoteDataQuery, IMatchDataQuery {}

export interface IUpdateMatchTextNoteProps
  extends IUpdateMatchTextNoteRequest,
    IMatchNoteDataQuery {}

export interface IDeleteJobMatchNoteAttachmentProps
  extends IBasicRequest,
    IMatchDataQuery,
    IMatchTextNoteAttachmentDataQuery {}

export interface IUpdateNoteAttachmentsProps extends IBasicRequest, IMatchNoteDataQuery {
  attachments: File[];
}

export interface IUpdateJobMatchNoteProps extends IBasicRequest, IUpdateMatchTextNoteProps {}
export interface IUpdateEmployerFeedbackProps extends IBasicRequest, IMatchDataQuery {
  employerFeedback: IEmployerFeedbackDTO;
}

export interface IUpdateInterviewStatusPayload extends IBasicRequest, IInterviewActionQuery {
  status: InterviewStatus;
}

export interface IUpdateInterviewDatePayload
  extends IBasicRequest,
    IInterviewActionQuery,
    IUpdateInterviewDateRequest {}

export interface IUpdateAdminMatchMessageProps extends IBasicRequest, IMatchDataQuery {
  message: string;
}

export interface IAdminAssignToMatchProps extends IBasicRequest, IAdminAssignToMatchPayload {}

export interface IAdminAssignToMatchPayload extends IMatchDataQuery {
  assignedAdmin: IUserShortInfoDto | null;
}

export interface IAdminUpdateMatchNotesPayload extends IMatchDataQuery {
  matchNotes: IMatchNoteDto[];
}

export interface IUpdateMatchNoteClientVisibilityProps
  extends IMatchNoteDataQuery,
    IUpdateMatchNoteClientVisibilityRequest {}

export interface IUpdateInterviewLinkPayload
  extends IBasicRequest,
    IInterviewActionQuery,
    IUpdateInterviewLinkRequest {}

export interface IUpdateScheduleLinkPayload
  extends IBasicRequest,
    IInterviewActionQuery,
    IUpdateScheduleLinkRequest {}

export interface ILoadJobMatchProps extends IBasicRequest, IMatchDataQuery {}

export interface ICreateCandidateProps extends IBasicRequest, IAutoOnboardingCandidateRequest {}

export interface ICreateAdminProps extends IBasicRequest, ICreateAdminFormData {}
