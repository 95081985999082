import { createSelector } from 'reselect';
import { IState } from '@/client/redux/types';
import { IMatchCandidateDto } from '@/DTO';
import { IBaseCandidateInfo } from '@/client/components/Modals/RequestInterviewModal/types';

export const matchesJobsSelector = ({ matchesJobs }: IState) => matchesJobs;

export const matchesJobsLoadingSelector = createSelector(matchesJobsSelector, ({ isLoading }) => ({
  isLoading,
}));

export const matchesJobsMatchesCountSelector = createSelector(
  matchesJobsSelector,
  ({ jobMatchesCount, isLoading }) => ({ jobMatchesCount, isLoading }),
);

export const jobMatchesAllSelector = createSelector(
  matchesJobsSelector,
  ({ jobMatchesAll, isLoading }) => ({
    jobMatchesAll,
    isLoading,
  }),
);

export const jobMatchesDeletingNoteIdSelector = createSelector(
  matchesJobsSelector,
  ({ deletingNoteId }) => deletingNoteId,
);

export const matchesJobsPageSelector = createSelector(matchesJobsSelector, ({ page }) => ({
  page,
}));

export const jobModalMatchSelector = createSelector(
  matchesJobsSelector,
  ({ modalMatch }) => modalMatch,
);

export const jobMatchByCandidateIdSelector = (candidateId: string | null) =>
  createSelector(jobMatchesAllSelector, ({ jobMatchesAll }) =>
    jobMatchesAll.reduce<IMatchCandidateDto | null>((acc, match) => {
      return match.candidate.id === candidateId ? match.candidate : acc;
    }, null),
  );

export const jobMatchRequestInterviewSelector = (candidateId: string | null) =>
  createSelector(
    jobMatchByCandidateIdSelector(candidateId),
    (candidate): IBaseCandidateInfo | null =>
      candidate
        ? {
            id: candidate.id,
            name: candidate.name,
            education: candidate.education,
            imageUri: candidate?.imageUri ?? '',
          }
        : null,
  );

export const getJobAlgorithmMatchesSelector = createSelector(
  matchesJobsSelector,
  ({ isLoading, hasMore, total, jobMatches }) => ({
    isLoading,
    hasMore,
    total,
    jobMatches,
  }),
);

export const getJobAlgorithmMatchesTotalSelector = createSelector(
  matchesJobsSelector,
  ({ total }) => ({
    total,
  }),
);
