import { ICityBase } from '@/client/types';
import { ICompanyDto } from '@/DTO';
import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, ICompaniesState } from '../types';

const initialState: ICompaniesState = {
  companyProfile: null,
  cities: [],
  isLoading: false,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    storeCompanyProfileData: (state, { payload }: IActionWithPayload<ICompanyDto>) => {
      state.companyProfile = payload;
    },
    storeCompanyProfileLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.isLoading = payload;
    },
    storeCompanyProfileCities: (state, { payload }: IActionWithPayload<ICityBase[]>) => {
      state.cities = payload;
    },
    storeCompanyProfileLogo: (state, action: IActionWithPayload<string>) => {
      if (!state.companyProfile) {
        return;
      }

      state.companyProfile.logoUri = action.payload;
    },
    clearCompaniesProfileData: (state) => {
      state.companyProfile = null;
      state.cities = [];
      state.isLoading = false;
    },
  },
});

export const {
  storeCompanyProfileData,
  storeCompanyProfileLoading,
  storeCompanyProfileCities,
  storeCompanyProfileLogo,
  clearCompaniesProfileData,
} = companiesSlice.actions;

export default companiesSlice.reducer;
