enum JobCardType {
  All = 'all',
  Applications = 'applications',
  Algorithm = 'algorithm',
  SentToCandidate = 'senttocandidate',
  SentToEmployer = 'senttoemployer',
  Rejected = 'rejected',
  RoundFirst = 'roundfirst',
  RoundSecond = 'roundsecond',
  RoundThird = 'roundthird',
  Finalize = 'finalize',
  Reserved = 'reserved',
}

export default JobCardType;
