export enum JobDBStatus {
  Published,
  Draft,
  Paused,
  Demo,
  Archived,
  Deleted,
  Hired,
}

export enum JobStatus {
  Published,
  UnderReview,
  Draft,
  Paused,
  Demo,
  Archived,
  Deleted,
  Hired,
}
