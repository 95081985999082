import { MouseEvent, useCallback } from 'react';
import { IButtonHookProps } from './types';

const useButton = ({ stopPropagation, onClick }: IButtonHookProps) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      onClick?.(e);
    },
    [onClick, stopPropagation],
  );

  return {
    handleClick,
  };
};

export default useButton;
