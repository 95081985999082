import clsx from 'clsx';
import { IMainContentWrapperProps } from './types';
import useStyles from './styles';

export const MainContentWrapper = ({
  children,
  className,
  fullWidth,
}: IMainContentWrapperProps) => {
  const { content, fullWidthContent } = useStyles();

  return (
    <main className={clsx(content, className, fullWidth && fullWidthContent)}>{children}</main>
  );
};

export default MainContentWrapper;
