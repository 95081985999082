import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { adminService, employerService, profileService } from '@/client/services';
import { IWelcomeFormData } from '@/client/forms/onboarding/employer/WelcomeForm/types';
import {
  IEmployerDTO,
  ICompanyTermsDTO,
  IUserDto,
  IEmployerShortInfoDto,
  IEmployerSettingsDTO,
} from '@/DTO';
import oAuth from '@DevimaSolutions/o-auth';
import { handleRequestError } from '@/client/utils';
import { ISettingsFormData } from '@/client/forms/profile/employer/SettingsForm/types';
import toast from 'react-hot-toast';
import { messages } from '@/client/constants';
import { IManageCompanyResponse } from '@/responses';
import {
  IUpdateEmployerData,
  IActionWithPayload,
  ICreateEmployerInterviewer,
  IInviteEmployer,
} from '@/client/redux/types';

import {
  createEmployerInterviewer,
  employerOnboarding,
  getEmployerManageCompanyData,
  getEmployerProfileData,
  getEmployerProfileDataFinished,
  getEmployerTermsData,
  inviteEmployer,
  updateEmployerSettingsProfile,
  uploadEmployerProfileAvatar,
} from '@/client/redux/actions';
import { userSelector } from '@/client/redux/selectors';
import {
  storeEmployerManageCompanyData,
  storeEmployerFetching,
  storeEmployerLoading,
  storeEmployerProfileData,
  storeEmployerSettingsProfileData,
  storeEmployerTermsData,
  storeEmployerProfileAvatar,
} from '@/client/redux/store/employer.slice';
import { IManageCompanyQuery } from '@/requests';

function* employerOnboardingSaga(action: IActionWithPayload<IWelcomeFormData>) {
  try {
    yield call(employerService.onboarding, action.payload);

    const user: IUserDto = yield select(userSelector);
    yield oAuth().updateUser({ ...user, isOnboardingFinished: true });
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateEmployerSettingsProfileSaga({
  payload,
}: IActionWithPayload<IUpdateEmployerData<ISettingsFormData>>) {
  yield put(storeEmployerLoading(true));
  try {
    const employer: IEmployerSettingsDTO = payload.employerId
      ? yield call(adminService.updateEmployerSettings, payload.employerId, payload.data)
      : yield call(employerService.updateEmployerSettingsProfile, payload.data);

    if (!payload.employerId) {
      oAuth().updateUser({ ...employer });
    }

    yield put(storeEmployerSettingsProfileData(employer));

    toast.success(messages.settingsUpdated);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeEmployerLoading(false));
  }
}

function* uploadEmployerAvatarSaga({ payload }: IActionWithPayload<IUpdateEmployerData<File>>) {
  try {
    const imageUri: string = payload.employerId
      ? yield call(adminService.uploadEmployerImage, payload.employerId, payload.data)
      : yield call(profileService.uploadProfileImage, payload.data);

    if (!payload.employerId) {
      oAuth().updateUser({
        imageUri,
      });
    }

    yield put(storeEmployerProfileAvatar(imageUri));
    toast.success(messages.profileImageUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* getEmployerProfileDataSaga({ payload }: IActionWithPayload<string | undefined>) {
  yield put(storeEmployerFetching(true));
  try {
    const data: IEmployerDTO = payload
      ? yield call(adminService.getEmployerProfileData, payload)
      : yield call(employerService.getEmployerProfileData);

    yield put(storeEmployerProfileData(data));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeEmployerFetching(false));
    yield put(getEmployerProfileDataFinished());
  }
}

function* getEmployerTermsDataSaga() {
  try {
    yield put(storeEmployerLoading(true));
    const termsData: ICompanyTermsDTO = yield call(employerService.getEmployerTermsData);

    yield put(storeEmployerTermsData(termsData));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeEmployerLoading(false));
  }
}

function* inviteEmployerSaga({ payload }: IActionWithPayload<IInviteEmployer>) {
  try {
    yield call(employerService.inviteEmployer, payload.email);

    payload.onSuccess?.();
    toast.success(messages.invitationEmailSent);
  } catch (error) {
    handleRequestError(error);
  }
}

function* createEmployerInterviewerSaga({
  payload,
}: IActionWithPayload<ICreateEmployerInterviewer>) {
  try {
    yield put(storeEmployerLoading(true));

    const { onSuccess, ...rest } = payload;
    const employer: IEmployerShortInfoDto = yield call(employerService.createInterviewer, rest);

    onSuccess(employer);
    toast.success(messages.newColleagueAdded);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeEmployerLoading(false));
  }
}

function* getEmployerManageCompanyDataSaga({ payload }: IActionWithPayload<IManageCompanyQuery>) {
  try {
    yield put(storeEmployerLoading(true));
    const companyManageData: IManageCompanyResponse = yield call(
      employerService.getManageCompanyData,
      payload,
    );

    yield put(storeEmployerManageCompanyData(companyManageData));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeEmployerLoading(false));
  }
}

export default function* employerRootSaga() {
  yield all([
    takeLatest(employerOnboarding.type, employerOnboardingSaga),
    takeLatest(updateEmployerSettingsProfile.type, updateEmployerSettingsProfileSaga),
    takeLatest(uploadEmployerProfileAvatar.type, uploadEmployerAvatarSaga),
    takeLatest(getEmployerProfileData.type, getEmployerProfileDataSaga),
    takeLatest(getEmployerTermsData.type, getEmployerTermsDataSaga),
    takeLatest(inviteEmployer.type, inviteEmployerSaga),
    takeLatest(getEmployerManageCompanyData.type, getEmployerManageCompanyDataSaga),
    takeLatest(createEmployerInterviewer.type, createEmployerInterviewerSaga),
  ]);
}
