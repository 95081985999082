import {
  loadMatchesForCandidate,
  rejectMatchForCandidate,
  rejectMatchForJob,
  approveMatchForCandidate,
  loadMatchScoreParams,
  loadMatchChanges,
  loadNextCandidateMatchesShortInfo,
} from '@/client/redux/actions';
import {
  storeMatchScoreParams,
  startLoading,
  stopLoading,
  storeMatchesForCandidate,
  storeMatchChanges,
  storeCandidateMatchesShortInfo,
  loadCandidateMatchesShortInfoFailed,
} from '@/client/redux/store/matches.slice';
import { IActionWithPayload, IRejectJobMatch } from '@/client/redux/types';
import { IAlgorithmMatchScoreParamsProps } from '@/client/types';
import { matchesService } from '@/client/services';
import { handleRequestError } from '@/client/utils';
import { ICandidateMatchJobDto, IAlgorithmMatchScoreParamsDto, IMatchChangesDto } from '@/DTO';
import { ICandidateMatchShortInfoResponse, ISuccessResponse } from '@/responses';
import toast from 'react-hot-toast';
import { all, call, select, put, takeLatest } from 'redux-saga/effects';
import { candidateMatchesShortInfoListQuerySelector } from '@/client/redux/selectors';
import { IBasePaginationQuery } from '@/requests';

function* loadMatchesForCandidateSaga() {
  try {
    yield put(startLoading());

    const matchesForCandidate: ICandidateMatchJobDto[] = yield call(
      matchesService.getMatchesForCandidate,
    );

    yield put(storeMatchesForCandidate(matchesForCandidate));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(stopLoading());
  }
}

function* rejectMatchForJobSaga(action: IActionWithPayload<IRejectJobMatch>) {
  try {
    const response: ISuccessResponse = yield call(matchesService.rejectMatchForJob, action.payload);

    toast.success(response.message);
    action.payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  }
}

function* rejectMatchForCandidateSaga(action: IActionWithPayload<string>) {
  try {
    const response: ISuccessResponse = yield call(
      matchesService.rejectMatchForCandidate,
      action.payload,
    );

    yield put(loadMatchesForCandidate());
    toast.success(response.message);
  } catch (error) {
    handleRequestError(error);
  }
}

function* approveMatchForCandidateSaga(action: IActionWithPayload<string>) {
  try {
    yield call(matchesService.acceptMatchForCandidate, action.payload);

    yield put(loadMatchesForCandidate());
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadMatchScoreParamsSaga({
  payload,
}: IActionWithPayload<IAlgorithmMatchScoreParamsProps>) {
  try {
    const response: IAlgorithmMatchScoreParamsDto = yield call(
      matchesService.getScoreParamsForMatch,
      payload,
    );

    yield put(storeMatchScoreParams(response));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadMatchChangesSaga({ payload }: IActionWithPayload<string>) {
  try {
    yield put(startLoading());

    const response: IMatchChangesDto[] = yield call(
      matchesService.getMatchChangesForMatch,
      payload,
    );

    yield put(storeMatchChanges(response));
  } catch (error) {
    handleRequestError(error);
    yield put(storeMatchChanges([]));
  } finally {
    yield put(stopLoading());
  }
}

function* loadNextCandidateMatchesShortInfoSaga({ payload }: IActionWithPayload<string>) {
  try {
    yield put(startLoading());
    const params: IBasePaginationQuery = yield select(candidateMatchesShortInfoListQuerySelector);
    const result: ICandidateMatchShortInfoResponse = yield call(
      matchesService.getCandidateMatchesShortInfo,
      { candidateId: payload, ...params },
    );

    yield put(storeCandidateMatchesShortInfo(result));
  } catch (error) {
    yield put(loadCandidateMatchesShortInfoFailed());
    handleRequestError(error);
  }
}

export default function* matchesRootSaga() {
  yield all([
    takeLatest(loadMatchesForCandidate.type, loadMatchesForCandidateSaga),
    takeLatest(rejectMatchForJob.type, rejectMatchForJobSaga),
    takeLatest(rejectMatchForCandidate.type, rejectMatchForCandidateSaga),
    takeLatest(approveMatchForCandidate.type, approveMatchForCandidateSaga),
    takeLatest(loadMatchScoreParams.type, loadMatchScoreParamsSaga),
    takeLatest(loadMatchChanges.type, loadMatchChangesSaga),
    takeLatest(loadNextCandidateMatchesShortInfo.type, loadNextCandidateMatchesShortInfoSaga),
  ]);
}
