import { applicantsService } from '@/client/services';
import { handleRequestError } from '@/client/utils';
import { IBaseApplicantDto } from '@/DTO';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { applyToJob } from '../actions';
import { setApplicant, setLoading, setSubmitted } from '../store/apply-to-job.slice';
import { markJobApplied } from '../store/browse-job.slice';
import { IActionWithPayload, IApplyToJobPayload } from '../types';

function* applyToJobSaga(action: IActionWithPayload<IApplyToJobPayload>) {
  try {
    yield put(setLoading(true));

    const applicant: IBaseApplicantDto = yield call(applicantsService.applyToJob, action.payload);

    yield put(setApplicant(applicant));
    yield put(setSubmitted(true));
    yield put(markJobApplied());
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

export default function* applyToJobRootSaga() {
  yield all([takeLatest(applyToJob.type, applyToJobSaga)]);
}
