import { useCallback } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import { Grid, IconButton, ButtonBase } from '@material-ui/core';
import {
  ProfilePopover,
  LinkButton,
  LinkButtons,
  MobileMenu,
  CustomLink,
} from '@/client/components';
import { ButtonType, IActionButton, IControlButton, IPageButton } from '@/client/types';
import { IHeader } from './types';
import useStyles from './styles';
import useHeader from './useHeader';

export const Header = ({ actionButtons = [], className, ...props }: IHeader): JSX.Element => {
  const classes = useStyles();
  const {
    controlButtons,
    popoverButtons,
    isDesktop,
    isHeaderFixed,
    isHeaderFullWidth,
    countNewInterviews,
    isSignedIn,
    headerBackButtonHandler,
  } = useHeader({ actionButtons });

  const renderControlButton = useCallback(
    (button: IControlButton) => {
      return (
        <>
          {button.isOutsideRedirect ? (
            <CustomLink
              href={button.redirect}
              target="_blank"
              rel="noopener noreferrer"
              className={clsx(classes.controlButton, classes.link)}
            >
              {button.label}
            </CustomLink>
          ) : (
            <LinkButton
              href={button.redirect}
              buttonType={button.isCustomButton ? LinkButtons.Custom : LinkButtons.Base}
              className={clsx(
                classes.controlButton,
                button.isCustomButton && classes.customControlButton,
              )}
            >
              <>
                {button.label}
                {button.showNotification && countNewInterviews > 0 && (
                  <div className={classes.notification} />
                )}
              </>
            </LinkButton>
          )}
        </>
      );
    },
    [classes, countNewInterviews],
  );

  const renderActionButton = useCallback(
    (button: IActionButton) => {
      return (
        <ButtonBase
          onClick={button.onClick}
          className={clsx(
            classes.controlButton,
            button.isCustomButton && classes.customControlButton,
          )}
        >
          {button.label}
        </ButtonBase>
      );
    },
    [classes],
  );

  const renderButtons = useCallback(
    (button: IPageButton) => {
      switch (button.type) {
        case ButtonType.ControlButton:
          return renderControlButton(button);
        default:
          return renderActionButton(button);
      }
    },
    [renderControlButton, renderActionButton],
  );

  return (
    <header
      className={clsx(classes.root, { [classes.fixed]: isHeaderFixed }, className)}
      {...props}
    >
      <Grid
        container
        alignItems="center"
        className={clsx(classes.wrapper, { [classes.wrapperFullWidth]: isHeaderFullWidth })}
      >
        {headerBackButtonHandler ? (
          <IconButton disableRipple onClick={headerBackButtonHandler} className={classes.backBtn}>
            <Image src="/icons/chevron-left.svg" width={7} height={14} />
          </IconButton>
        ) : null}
        <Grid item>
          <Link href="/">
            <div className={classes.logo}>
              <Image
                src="/logo.svg"
                alt="Alariss logo"
                height={isDesktop ? 42 : 31}
                width={isDesktop ? 64 : 46}
              />
            </div>
          </Link>
        </Grid>
        {isSignedIn && (
          <div className={classes.controlButtonsSection}>
            {isDesktop ? (
              <>
                {controlButtons.map((button) => (
                  <div
                    key={button.label}
                    className={clsx(
                      classes.controlButtonItem,
                      button.isCustomButton && classes.customControlButtonItem,
                      button.isCurrentPage && classes.selectedControlButtonItem,
                    )}
                  >
                    {renderButtons(button)}
                  </div>
                ))}
                <div className={classes.pfofilePopover}>
                  <ProfilePopover buttons={popoverButtons} />
                </div>
              </>
            ) : (
              <div>
                <MobileMenu buttons={controlButtons} />
              </div>
            )}
          </div>
        )}
      </Grid>
    </header>
  );
};

export default Header;
