import axios from 'axios';
import {
  locationsService,
  profileService,
  candidatesService,
  adminService,
  settingsService,
} from '@/client/services';
import { ICityBase, IEducationFormData, IRegion } from '@/client/types';
import { handleRequestError } from '@/client/utils';
import { all, call, takeLatest, put } from 'redux-saga/effects';
import { ISuccessResponse, IUploadFileResponse, ICandidateBasicInfoResponse } from '@/responses';
import {
  ICandidateBaseInfoDto,
  ICandidateDto,
  ICandidateSalesQualificationDto,
  ICandidateSecondarySkillsDto,
  ICandidateSkillsDto,
  ICandidateVerticalDto,
} from '@/DTO';
import oAuth from '@DevimaSolutions/o-auth';
import { ICandidateBasicInfoRequest } from '@/requests';
import { ICandidateEducationAttributes, IVerticalAttributes } from '@/server/database';
import { ISkillsFormData } from '@/client/forms/profile/candidate/SkillsForm/types';
import { ISecondarySkillsFormData } from '@/client/forms/profile/candidate/SecondarySkillsForm/types';
import toast from 'react-hot-toast';
import { ISettingsFormData } from '@/client/forms/profile/candidate/SettingsForm/types';
import {
  loadIndustryExperience,
  loadJobFunctions,
  loadVerticals,
  loadSalesQualifications,
} from '@/client/redux/actions';
import { messages } from '@/client/constants';
import { ICandidateUrlFormData } from '@/client/forms/profile/candidate/CandidateUrlForm/types';
import { IVerticalFormData } from '@/client/forms/profile/candidate/VerticalForm/types';
import { ISalesQualificationFormData } from '@/client/forms/profile/candidate/SelectQualificationForm/types';
import {
  loadProfileCities,
  loadProfileRegions,
  loadProfileCandidate,
  loadCandidateResume,
  uploadCandidateProfileAvatar,
  updateCandidateProfileBasicInfo,
  updateCandidateProfileBio,
  updateCandidateProfileCompensation,
  updateCandidateProfileEducations,
  updateCandidateProfileSecondarySkills,
  updateCandidateProfileSkills,
  resetProfilePassword,
  updateCandidateProfileSettings,
  updateAdminProfileSettings,
  loadProfileCandidateById,
  updateCandidateProfileUrl,
  updateCandidateProfileVerticals,
  updateCandidateProfileSalesQualifications,
} from '../actions/profile.actions';
import {
  storeProfileCandidate,
  storeProfileCities,
  storeProfileRegions,
  storeCandidateProfileAvatar,
  storeCandidateResume,
  storeProfileLoading,
  storeProfileFetching,
  storeCandidateProfileBasicInfo,
  storeCandidateProfileBio,
  storeCandidateProfileCompensation,
  storeCandidateProfileEducations,
  storeCandidateProfileSecondarySkills,
  storeCandidateProfileSkills,
  storeProfileError,
  storeCandidateProfileVertical,
  storeCandidateProfileSalesQualifications,
} from '../store/profile.slice';
import {
  IActionWithPayload,
  ICandidateSettingsResponse,
  ICandidateUpdateRequest,
  ILoadCitiesData,
  ILoadRegionsData,
  IResetProfilePassword,
} from '../types';

function* loadRegionsSaga(action: IActionWithPayload<ILoadRegionsData>) {
  try {
    const regions: IRegion[] = yield call(
      locationsService.loadRegions,
      action.payload.countryCode,
      action.payload.input,
    );

    yield put(storeProfileRegions(regions));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadCitiesSaga(action: IActionWithPayload<ILoadCitiesData>) {
  try {
    const cities: ICityBase[] = yield call(
      locationsService.loadCities,
      action.payload.countryCode,
      action.payload.regionCode,
      action.payload.input,
    );

    yield put(storeProfileCities(cities));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadCandidateSaga() {
  yield put(storeProfileFetching(true));

  try {
    yield all([put(loadJobFunctions()), put(loadIndustryExperience())]);

    const candidate: ICandidateDto = yield call(profileService.loadCandidate);

    yield put(storeProfileCandidate(candidate));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileFetching(false));
  }
}

function* loadCandidateByIdSaga({ payload }: IActionWithPayload<string>) {
  yield put(storeProfileFetching(true));

  try {
    yield all([
      put(loadJobFunctions()),
      put(loadIndustryExperience()),
      put(loadVerticals()),
      put(loadSalesQualifications()),
    ]);

    const candidate: ICandidateDto = yield call(candidatesService.getCandidate, payload);

    yield put(storeProfileCandidate(candidate));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileFetching(false));
  }
}

function* loadCandidateResumeSaga() {
  try {
    const { fileUri }: IUploadFileResponse = yield call(profileService.loadCandidateResume);

    yield put(storeCandidateResume(fileUri));
  } catch (error) {
    handleRequestError(error);
  }
}

function* uploadCandidateAvatarSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<File>>) {
  try {
    const imageUri: string = payload.candidateId
      ? yield call(adminService.uploadCandidateImage, payload.data, payload.candidateId)
      : yield call(profileService.uploadProfileImage, payload.data);

    // Not upload profile if it has been changed by Admin
    if (!payload.candidateId) {
      oAuth().updateUser({
        imageUri,
      });
    }

    yield put(storeCandidateProfileAvatar(imageUri));
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateCandidateBasicInfoSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<ICandidateBasicInfoRequest>>) {
  yield put(storeProfileLoading(true));
  try {
    const basicInfo: ICandidateBasicInfoResponse = yield call(
      profileService.updateCandidateBasicInfo,
      payload.data,
      payload.candidateId,
    );

    yield put(storeCandidateProfileBasicInfo(basicInfo));

    if (!payload.candidateId) {
      oAuth().updateUser({
        firstName: basicInfo.firstName,
        lastName: basicInfo.lastName,
      });
    }
    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateProfileBioSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<string>>) {
  yield put(storeProfileLoading(true));
  try {
    const bio: string = yield call(
      profileService.updateCandidateBio,
      payload.data,
      payload.candidateId,
    );

    yield put(storeCandidateProfileBio(bio));

    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateProfileCompensationSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<number>>) {
  yield put(storeProfileLoading(true));
  try {
    const rate: number = yield call(
      profileService.updateCandidateCompensation,
      payload.data,
      payload.candidateId,
    );

    yield put(storeCandidateProfileCompensation(rate));

    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateProfileEducationsSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<IEducationFormData[]>>) {
  yield put(storeProfileLoading(true));
  try {
    const educations: ICandidateEducationAttributes[] = yield call(
      profileService.updateCandidateEducations,
      payload.data,
      payload.candidateId,
    );

    yield put(storeCandidateProfileEducations(educations));

    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateSecondarySkillsSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<ISecondarySkillsFormData>>) {
  yield put(storeProfileLoading(true));
  try {
    const secondarySkills: ICandidateSecondarySkillsDto = yield call(
      profileService.updateCandidateSecondarySkills,
      payload.data,
      payload.candidateId,
    );

    yield put(storeCandidateProfileSecondarySkills(secondarySkills));

    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateSkillsSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<ISkillsFormData>>) {
  yield put(storeProfileLoading(true));
  try {
    const skills: ICandidateSkillsDto = yield call(
      profileService.updateCandidateSkills,
      payload.data,
      payload.candidateId,
    );

    yield put(storeCandidateProfileSkills(skills));

    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* resetPasswordSaga({ payload }: IActionWithPayload<IResetProfilePassword>) {
  yield put(storeProfileLoading(true));
  try {
    const result: ISuccessResponse = yield call(profileService.resetPassword, payload.data);

    payload.onSuccess?.();
    toast.success(result.message);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(storeProfileError(error.response?.data));
    }
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateSettingsSaga({ payload }: IActionWithPayload<ISettingsFormData>) {
  yield put(storeProfileLoading(true));
  try {
    const settings: ICandidateSettingsResponse = yield call(
      profileService.updateCandidateSettings,
      payload,
    );

    if (settings.email !== payload.email) {
      oAuth().signOut();
      toast.success(messages.verificationEmailSent);
      return;
    }

    yield put(storeCandidateProfileBasicInfo(settings));
    toast.success(messages.settingsUpdated);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateUrlSaga({ payload }: IActionWithPayload<ICandidateUrlFormData>) {
  yield put(storeProfileLoading(true));
  try {
    if (!payload.candidateId) {
      return;
    }

    const { candidateId, ...payloadData } = payload;

    const data: Pick<ICandidateBaseInfoDto, 'linkedinUri' | 'resumeUri' | 'hubspotId'> = yield call(
      profileService.updateCandidateUrl,
      payloadData,
      candidateId,
    );

    yield put(storeCandidateProfileBasicInfo(data));
    toast.success(messages.urlsUpdated);
    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateAdminSettingsSaga({ payload }: IActionWithPayload<ISettingsFormData>) {
  yield put(storeProfileLoading(true));
  try {
    yield call(profileService.updateAdminSettings, payload);

    toast.success(messages.settingsUpdated);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateProfileVerticalsSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<IVerticalFormData>>) {
  yield put(storeProfileLoading(true));
  try {
    const { unknownVerticals, normalVerticals } = payload.data.verticals.reduce<{
      unknownVerticals: IVerticalAttributes[];
      normalVerticals: IVerticalAttributes[];
    }>(
      (acc, item) => {
        acc[item.id.startsWith('new-') ? 'unknownVerticals' : 'normalVerticals'].push(item);

        return acc;
      },
      { unknownVerticals: [], normalVerticals: [] },
    );

    if (unknownVerticals.length) {
      const createdVerticals: IVerticalAttributes[] = yield call(
        settingsService.createVertical,
        unknownVerticals,
      );

      const vertical: ICandidateVerticalDto = yield call(profileService.updateCandidateVertical, {
        candidateId: payload.candidateId,
        verticals: normalVerticals.concat(createdVerticals),
      });

      yield put(storeCandidateProfileVertical(vertical));
    } else {
      const vertical: ICandidateVerticalDto = yield call(profileService.updateCandidateVertical, {
        candidateId: payload.candidateId,
        verticals: normalVerticals,
      });

      yield put(storeCandidateProfileVertical(vertical));
    }
    toast.success(messages.candidateVerticalsUpdated);
    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

function* updateCandidateProfileSalesQualificationsSaga({
  payload,
}: IActionWithPayload<ICandidateUpdateRequest<ISalesQualificationFormData>>) {
  yield put(storeProfileLoading(true));
  try {
    const salesQualifications: ICandidateSalesQualificationDto = yield call(
      profileService.updateCandidateSalesQualifications,
      {
        candidateId: payload.candidateId,
        salesQualifications: payload.data.salesQualifications,
      },
    );

    yield put(storeCandidateProfileSalesQualifications(salesQualifications));

    toast.success(messages.candidateSalesQualificationsUpdated);
    payload.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeProfileLoading(false));
  }
}

export default function* profileRootSaga() {
  yield all([
    takeLatest(loadProfileRegions.type, loadRegionsSaga),
    takeLatest(loadProfileCities.type, loadCitiesSaga),
    takeLatest(loadProfileCandidate.type, loadCandidateSaga),
    takeLatest(loadProfileCandidateById.type, loadCandidateByIdSaga),
    takeLatest(loadCandidateResume.type, loadCandidateResumeSaga),
    takeLatest(uploadCandidateProfileAvatar.type, uploadCandidateAvatarSaga),
    takeLatest(updateCandidateProfileBasicInfo.type, updateCandidateBasicInfoSaga),
    takeLatest(updateCandidateProfileBio.type, updateCandidateProfileBioSaga),
    takeLatest(updateCandidateProfileCompensation.type, updateCandidateProfileCompensationSaga),
    takeLatest(updateCandidateProfileEducations.type, updateCandidateProfileEducationsSaga),
    takeLatest(updateCandidateProfileSecondarySkills.type, updateCandidateSecondarySkillsSaga),
    takeLatest(updateCandidateProfileSkills.type, updateCandidateSkillsSaga),
    takeLatest(resetProfilePassword.type, resetPasswordSaga),
    takeLatest(updateCandidateProfileUrl.type, updateCandidateUrlSaga),
    takeLatest(updateCandidateProfileSettings.type, updateCandidateSettingsSaga),
    takeLatest(updateAdminProfileSettings.type, updateAdminSettingsSaga),
    takeLatest(updateCandidateProfileVerticals.type, updateCandidateProfileVerticalsSaga),
    takeLatest(
      updateCandidateProfileSalesQualifications.type,
      updateCandidateProfileSalesQualificationsSaga,
    ),
  ]);
}
