import {
  IAlgorithmMatchCandidateDto,
  ICandidateMatchJobDto,
  IAlgorithmMatchScoreParamsDto,
  IMatchChangesDto,
} from '@/DTO';
import {
  IAllJobMatchesDataResponse,
  ICandidateMatchShortInfoResponse,
  ISuccessResponse,
} from '@/responses';
import {
  IAlgorithmMatchScoreParametersQuery,
  ICandidateMatchShortInfoQuery,
  IUpdateMatchStatusRequest,
} from '@/requests';
import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';

const getMatchesForJob = async (jobId: string) => {
  const matchesForJob = await oAuth()
    .axios.get(`/matches/${jobId}`)
    .then((res: AxiosResponse<IAlgorithmMatchCandidateDto[]>) => res.data);

  return matchesForJob;
};

const getMatchesForCandidate = async () => {
  const matchesForCandidate = await oAuth()
    .axios.get('/matches')
    .then((res: AxiosResponse<ICandidateMatchJobDto[]>) => res.data);

  return matchesForCandidate;
};

const rejectMatchForJob = async (body: IUpdateMatchStatusRequest) => {
  const response = await oAuth()
    .axios.put('/matches/reject', body)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return response;
};

const acceptMatchForCandidate = async (jobId: string) => {
  const response = await oAuth()
    .axios.put<ISuccessResponse>(`/matches/${jobId}/accept`)
    .then((res) => res.data);

  return response;
};

const rejectMatchForCandidate = async (jobId: string) => {
  const response = await oAuth()
    .axios.put<ISuccessResponse>(`/matches/${jobId}/reject`)
    .then((res) => res.data);

  return response;
};

const getScoreParamsForMatch = async (params: IAlgorithmMatchScoreParametersQuery) => {
  const response = await oAuth()
    .axios.get<IAlgorithmMatchScoreParamsDto>(`/admin/matches/score-params`, {
      params,
    })
    .then((res) => res.data);
  return response;
};

const getMatchChangesForMatch = async (matchId: string) => {
  const response = await oAuth()
    .axios.get<IMatchChangesDto[]>(`/admin/matches/status-changes/${matchId}`)
    .then((res) => res.data);

  return response;
};

const getCandidateMatchesShortInfo = ({ candidateId, ...params }: ICandidateMatchShortInfoQuery) =>
  oAuth()
    .axios.get(`/admin/candidates/${candidateId}/matches/short-info`, { params })
    .then((res: AxiosResponse<ICandidateMatchShortInfoResponse>) => res.data);

const getJobAllMatches = async (jobId: string) => {
  const matches = await oAuth()
    .axios.get<IAllJobMatchesDataResponse>(`/matches/${jobId}/all-matches`)
    .then((res) => res.data);

  return matches;
};

export default {
  getMatchesForJob,
  getMatchesForCandidate,
  acceptMatchForCandidate,
  rejectMatchForJob,
  rejectMatchForCandidate,
  getScoreParamsForMatch,
  getMatchChangesForMatch,
  getCandidateMatchesShortInfo,
  getJobAllMatches,
};
