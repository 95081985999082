import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  userName: {
    display: 'none',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.up('md')]: { display: 'block', fontSize: '14px', lineHeight: '20px' },
  },
  content: {
    width: '180px',
    margin: theme.spacing(6, 0),
  },
  button: {
    paddingLeft: theme.spacing(6),
    justifyContent: 'flex-start',
    width: '100%',
    minWidth: theme.spacing(10),
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.003em',
    color: theme.palette.text.primary,
    ':hover': {
      background: theme.palette.dropdown,
    },
  },
}));

export default useStyles;
