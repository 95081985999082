import { IOnboardingBasicInfoResponse, IOnboardingResult } from '@/responses';
import {
  ICandidateEducationAttributes,
  IIndustryExperienceAttributes,
  IJobFunctionAttributes,
} from '@/server/database';
import {
  getCountryByName,
  getFormattedState,
  getFormattedCity,
} from '@/client/utils/location.util';
import { IOnboardingBasicInfoData } from '@/client/forms/onboarding/candidate/BasicInfo/types';
import { IEducationFormData, IOption } from '@/client/types';
import { degreeOptions, experienceLevelOptions } from '../options';
import { IFormatedOnboardingResult } from '../redux/types';

export const getFormattedCandidateOnboardingBasicInfoData = (
  basicInfo: IOnboardingBasicInfoResponse,
  jobFunctions: IJobFunctionAttributes[],
  industryExperiences: IIndustryExperienceAttributes[],
): { basicInfo: IOnboardingBasicInfoData } => {
  return {
    basicInfo: {
      state: getFormattedState(basicInfo.address.state || null),
      ...basicInfo,
      experienceLevel:
        experienceLevelOptions.find((el) => basicInfo.experienceLevel === el.value) ?? null,
      roles: basicInfo.roles.map(
        (el) =>
          ({
            id: el,
            name: jobFunctions.find((opt) => opt.id === el)?.name,
            description: jobFunctions.find((opt) => opt.id === el)?.description,
          } as IJobFunctionAttributes),
      ),
      country: getCountryByName(basicInfo.address.country || null),
      city: getFormattedCity(basicInfo.address.city || null),
      industryExperience: basicInfo.industryExperience.reduce((acc: IOption<string>[], el) => {
        const label = industryExperiences.find((item) => item.id === el)?.label;
        if (!label) {
          return acc;
        }

        return [
          ...acc,
          {
            value: el,
            label,
          },
        ];
      }, []),
    },
  };
};

export const getFormattedCandidateOnboardingEducationsData = (
  educations: ICandidateEducationAttributes[],
): IEducationFormData[] => {
  return educations.map((item) => ({
    ...item,
    degree: degreeOptions.find((el) => el.value === item.degree) ?? null,
  }));
};

export const getFormattedCandidateOnboardingData = (
  onboarding: IOnboardingResult,
  jobFunctions: IJobFunctionAttributes[],
  industryExperiences: IIndustryExperienceAttributes[],
): IFormatedOnboardingResult => {
  const { basicInfo, educations } = onboarding;
  return {
    ...onboarding,
    ...getFormattedCandidateOnboardingBasicInfoData(basicInfo, jobFunctions, industryExperiences),
    educations: getFormattedCandidateOnboardingEducationsData(educations),
  };
};
