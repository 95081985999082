type StandardEnum<T> = {
  [id: string]: T | string;
};

export const isEnumKey = <T extends StandardEnum<unknown>>(
  e: T,
  s: string | number | symbol,
): s is keyof T => {
  return Object.keys(e).includes(s.toString());
};
