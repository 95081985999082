import { createSelector } from 'reselect';
import { IState } from '@/client/redux/types';

export const onboardingSelector = ({ onboarding }: IState) => onboarding;

export const activeStepSelector = ({ onboarding }: IState): number => onboarding.activeStep;

export const onboardingCitiesSelector = createSelector(onboardingSelector, (state) => state.cities);

export const onboardingRegionsSelector = createSelector(
  onboardingSelector,
  (state) => state.regions,
);

export const onboardingDataSelector = createSelector(
  onboardingSelector,
  (state) => state.onboarding,
);

export const onboardingFetchingSelector = createSelector(
  onboardingSelector,
  (state) => state.fetching,
);

export const onboardingLoadingSelector = createSelector(
  onboardingSelector,
  (state) => state.loading,
);
