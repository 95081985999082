import {
  authorizationsStateSelector,
  countNewCandidateInterviewsSelector,
  headerStateSelector,
} from '@/client/redux/selectors';
import { IPageButton } from '@/client/types';
import { getControlButtons } from '@/client/utils';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IUseHeader } from './types';

const useHeader = ({ actionButtons = [] }: IUseHeader) => {
  const countNewInterviews = useSelector(countNewCandidateInterviewsSelector);
  const { user, isSignedIn } = useSelector(authorizationsStateSelector);
  const { isHeaderFixed, isHeaderFullWidth, headerBackButtonHandler } =
    useSelector(headerStateSelector);
  const router = useRouter();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const { controlButtons, popoverButtons } = useMemo(() => {
    if (!user) {
      return { controlButtons: [], popoverButtons: [] };
    }

    const buttons: IPageButton[] = [
      ...getControlButtons(
        user.role,
        user.isOnboardingFinished,
        router.pathname,
        user.hasPostedJob,
      ),
      ...actionButtons,
    ];

    if (!isDesktop) {
      return { controlButtons: buttons, popoverButtons: [] };
    }

    return buttons.reduce<{
      controlButtons: IPageButton[];
      popoverButtons: IPageButton[];
    }>(
      (acc, button) => {
        if (button.isPopoverButton) {
          acc.popoverButtons.push(button);
        } else {
          acc.controlButtons.push(button);
        }

        return acc;
      },
      { controlButtons: [], popoverButtons: [] },
    );
  }, [user, router.pathname, actionButtons, isDesktop]);

  return {
    controlButtons,
    popoverButtons,
    isDesktop,
    isHeaderFixed,
    isHeaderFullWidth,
    countNewInterviews,
    isSignedIn,
    headerBackButtonHandler,
  };
};

export default useHeader;
