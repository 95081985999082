import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import oAuth from '@DevimaSolutions/o-auth';
import { userSelector } from '@/client/redux/selectors';

const useProfileImage = () => {
  const user = useSelector(userSelector);

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopoverHandler = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopoverHandler = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const logoutHandler = useCallback(() => {
    oAuth().signOut();
  }, []);

  const open = Boolean(anchorEl);

  return { user, open, anchorEl, openPopoverHandler, closePopoverHandler, logoutHandler };
};

export default useProfileImage;
