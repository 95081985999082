import { IEmployerShortInfoDto, IJobShortInfoDto } from '@/DTO';
import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, IRequestInterviewState } from '../types';

const initialState: IRequestInterviewState = {
  isLoading: false,
  isSubmitted: false,
  jobs: [],
  employers: [],
  selectedEmployer: null,
};

export const requestInterviewSlice = createSlice({
  name: 'requestInterview',
  initialState,
  reducers: {
    setLoading: (state, action: IActionWithPayload<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitted: (state, action: IActionWithPayload<boolean>) => {
      state.isSubmitted = action.payload;
    },
    setJobs: (state, action: IActionWithPayload<IJobShortInfoDto[]>) => {
      state.jobs = action.payload;
    },
    setEmployers: (state, action: IActionWithPayload<IEmployerShortInfoDto[]>) => {
      state.employers = action.payload;
    },
    setSelectedEmployer: (state, action: IActionWithPayload<IEmployerShortInfoDto | null>) => {
      state.selectedEmployer = action.payload;
    },
    clearRequestInterviewState: (state) => {
      state.jobs = [];
      state.employers = [];
      state.isLoading = false;
      state.isSubmitted = false;
      state.selectedEmployer = null;
    },
  },
});

export const {
  setLoading,
  setSubmitted,
  setJobs,
  setEmployers,
  setSelectedEmployer,
  clearRequestInterviewState,
} = requestInterviewSlice.actions;

export default requestInterviewSlice.reducer;
