import Link from 'next/link';
import useLinkButton from './useLinkButton';
import { ILinkButtonProps, LinkButtons } from './types';

export const LinkButton = ({
  href,
  buttonType = LinkButtons.Default,
  children,
  ...props
}: ILinkButtonProps): JSX.Element => {
  const Button = useLinkButton(buttonType);

  return (
    <Link href={href} passHref>
      <Button {...props}>{children}</Button>
    </Link>
  );
};

export default LinkButton;
