import {
  IClientMatchMessageDto,
  IInterviewDateDto,
  IInterviewDto,
  IInterviewLinkDto,
  IJobMatchDto,
  IJobMatchesCountDto,
  IMatchNoteDto,
  IScheduleLinkDto,
} from '@/DTO';
import { IJobMatchesDataResponse, ITotalMatchesCountResponse } from '@/responses';
import { createSlice } from '@reduxjs/toolkit';
import {
  IActionWithPayload,
  IMatchesJobsState,
  IUpdateJobMatchStagePayload,
  IDeleteJobMatchNoteProps,
  IJobMatchInterviewProps,
  IDeleteJobMatchNoteAttachmentProps,
} from '../types';

const initialState: IMatchesJobsState = {
  jobMatchesCount: [],
  jobMatches: [],
  jobMatchesAll: [],
  isLoading: true,
  hasMore: true,
  page: 1,
  perPage: 100,
  total: 0,
  deletingNoteId: null,
  modalMatch: null,
};

export const matchesJobsSlice = createSlice({
  name: 'matches-jobs',
  initialState,
  reducers: {
    setLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.isLoading = payload;
    },
    setJobMatchesCount: (state, { payload }: IActionWithPayload<IJobMatchesCountDto[]>) => {
      state.jobMatchesCount = payload;
      state.isLoading = false;
    },
    setJobMatchesAll(state, { payload }: IActionWithPayload<IJobMatchDto[]>) {
      state.jobMatchesAll = payload;
    },
    setJobMatches(state, action: IActionWithPayload<IJobMatchesDataResponse>) {
      state.hasMore = action.payload.hasMore;
      if (action.payload.data.length) {
        const existingMatchIdSet = new Set(state.jobMatches.map((match) => match.id));
        state.jobMatches.push(
          ...action.payload.data.filter((match) => !existingMatchIdSet.has(match.id)),
        );
        state.page += 1;
      }
    },
    setJobAlgorithmMatchesCount(
      state,
      { payload }: IActionWithPayload<ITotalMatchesCountResponse>,
    ) {
      state.total = payload.total;
    },
    setJobMatchStage(state, { payload }: IActionWithPayload<IUpdateJobMatchStagePayload>) {
      const match = state.jobMatchesAll.find(({ id }) => payload.matchId === id);

      if (match) {
        match.stage = payload.stage;
      }
    },
    setJobMatchNote(state, { payload }: IActionWithPayload<IMatchNoteDto>) {
      const match = state.jobMatchesAll.find(({ id }) => payload.matchId === id);

      if (match) {
        match.matchNotes.push(payload);
      }

      if (state.modalMatch) {
        state.modalMatch.matchNotes.push(payload);
      }
    },
    setUpdatedJobMatchNote(state, { payload }: IActionWithPayload<IMatchNoteDto>) {
      const match = state.jobMatchesAll.find(({ id }) => payload.matchId === id);

      if (match) {
        match.matchNotes = match.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }

      if (state.modalMatch) {
        state.modalMatch.matchNotes = state.modalMatch.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }
    },
    setClientMatchMessage(state, { payload }: IActionWithPayload<IClientMatchMessageDto>) {
      const match = state.jobMatchesAll.find(({ id }) => payload.matchId === id);

      if (match) {
        match.clientMessageId = payload.id;
        match.clientMatchMessage = payload;
      }
    },
    setJobMatchInterviewDate: (
      state,
      { payload: { id, interviewDate } }: IActionWithPayload<IInterviewDateDto>,
    ) => {
      const selectedInterview = state.jobMatchesAll.reduce<IInterviewDto | null>((acc, match) => {
        return match.applicant?.interviews?.find((interview) => interview.id === id) ?? acc;
      }, null);

      const selectedModalMatchInterview = state.modalMatch?.applicant?.interviews?.find(
        (interview) => interview.id === id,
      );

      if (selectedInterview) {
        selectedInterview.interviewDate = interviewDate;
      }

      if (selectedModalMatchInterview) {
        selectedModalMatchInterview.interviewDate = interviewDate;
      }
    },
    setJobMatchInterview(
      state,
      { payload: { matchId, interview } }: IActionWithPayload<IJobMatchInterviewProps>,
    ) {
      const match = state.jobMatchesAll.find(({ id }) => id === matchId);

      if (match?.applicant?.interviews) {
        match.applicant.interviews.push(interview);
      }

      if (state.modalMatch?.applicant?.interviews) {
        state.modalMatch.applicant.interviews.push(interview);
      }
    },
    setDeleting: (state, { payload }: IActionWithPayload<string | null>) => {
      state.deletingNoteId = payload;
    },
    removeJobMatchNote(state, { payload }: IActionWithPayload<IDeleteJobMatchNoteProps>) {
      const match = state.jobMatchesAll.find(({ id }) => payload.matchId === id);

      if (match) {
        match.matchNotes = match.matchNotes.filter(({ id }) => id !== payload.matchNoteId);
      }

      if (state.modalMatch) {
        state.modalMatch.matchNotes = state.modalMatch.matchNotes.filter(
          ({ id }) => id !== payload.matchNoteId,
        );
      }
    },
    setModalMatch: (state, { payload }: IActionWithPayload<{ matchId: string }>) => {
      const modalMatch = state.jobMatchesAll.find((item) => {
        return item.id === payload.matchId;
      });
      if (modalMatch) {
        state.modalMatch = modalMatch;
      }
    },
    clearMatchModal(state) {
      state.modalMatch = null;
    },
    setJobMatchesInterviewLink: (
      state,
      { payload: { id, interviewLink } }: IActionWithPayload<IInterviewLinkDto>,
    ) => {
      const selectedSchedule = state.jobMatchesAll.reduce<IInterviewDto | null>((acc, match) => {
        return match.applicant?.interviews?.find((interview) => interview.id === id) ?? acc;
      }, null);

      const selectedModalMatchSchedule = state.modalMatch?.applicant?.interviews?.find(
        (interview) => interview.id === id,
      );

      if (selectedSchedule) {
        selectedSchedule.interviewLink = interviewLink;
      }

      if (selectedModalMatchSchedule) {
        selectedModalMatchSchedule.interviewLink = interviewLink;
      }
    },
    setJobMatchesScheduleLink: (
      state,
      { payload: { id, scheduleLink } }: IActionWithPayload<IScheduleLinkDto>,
    ) => {
      const selectedSchedule = state.jobMatchesAll.reduce<IInterviewDto | null>((acc, match) => {
        return match.applicant?.interviews?.find((interview) => interview.id === id) ?? acc;
      }, null);

      const selectedModalMatchSchedule = state.modalMatch?.applicant?.interviews?.find(
        (interview) => interview.id === id,
      );

      if (selectedSchedule) {
        selectedSchedule.scheduleLink = scheduleLink;
      }

      if (selectedModalMatchSchedule) {
        selectedModalMatchSchedule.scheduleLink = scheduleLink;
      }
    },
    removeJobMatchNoteAttachment(
      state,
      { payload }: IActionWithPayload<IDeleteJobMatchNoteAttachmentProps>,
    ) {
      const matchNote = state.jobMatchesAll.reduce<IMatchNoteDto | null>((acc, match) => {
        return match.matchNotes.find(({ id }) => id === payload.matchNoteId) ?? acc;
      }, null);
      if (matchNote?.attachedFiles) {
        matchNote.attachedFiles = matchNote.attachedFiles.filter(
          (item) => item.id !== payload.attachmentId,
        );
      }
      if (state.modalMatch) {
        state.modalMatch.matchNotes = state.modalMatch.matchNotes.reduce<IMatchNoteDto[]>(
          (acc, note) => {
            note.attachedFiles =
              note.id === payload.matchNoteId && note.attachedFiles
                ? note.attachedFiles.filter((item) => item.id !== payload.attachmentId)
                : note.attachedFiles;
            return [...acc, note];
          },
          [],
        );
      }
    },
    updateMatchNote(state, { payload }: IActionWithPayload<IMatchNoteDto>) {
      const globalMatch = state.jobMatchesAll.find((match) => match.id === payload.matchId);
      if (globalMatch) {
        globalMatch.matchNotes = globalMatch.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }
      if (state.modalMatch) {
        state.modalMatch.matchNotes = state.modalMatch.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }
    },

    clearJobMatches(state) {
      state.jobMatches = [];
      state.hasMore = false;
      state.page = 1;
    },
    clearJobAlgorithmMatches(state) {
      state.jobMatches = [];
      state.hasMore = false;
      state.page = 1;
    },
  },
});

export const {
  setLoading,
  setJobMatchesCount,
  setJobMatches,
  setJobAlgorithmMatchesCount,
  setJobMatchesAll,
  clearJobMatches,
  setJobMatchStage,
  setJobMatchNote,
  setUpdatedJobMatchNote,
  setClientMatchMessage,
  setJobMatchInterviewDate,
  setJobMatchInterview,
  setDeleting,
  removeJobMatchNote,
  clearJobAlgorithmMatches,
  setModalMatch,
  clearMatchModal,
  setJobMatchesInterviewLink,
  setJobMatchesScheduleLink,
  updateMatchNote,
  removeJobMatchNoteAttachment,
} = matchesJobsSlice.actions;

export default matchesJobsSlice.reducer;
