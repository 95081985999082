export enum EnvMode {
  DEV_ENV = 'development',
  PROD_ENV = 'production',
  TEST_ENV = 'test',
}

interface IDatabaseConfigs {
  host: string;
  port: number;
  username: string;
  password: string;
  database: string;
}

export interface IEnv {
  database: IDatabaseConfigs;
  jwtSecret: string;
  emailJwtSecret: string;
  authorizationTokenDuration: string;
  refreshTokenDuration: string;
  awsBucketName: string;
  awsRegion: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  cookiesAge: number;
  cookieDomain?: string;
  linkedInClientId: string;
  linkedInSecret: string;
  hubspotAccountId: string;
  domain: string;
  otpExpiration: string;
  locationsCacheAge: number;
  apiSecret: string;
  scheduleApiUrl: string;
  emailsReminderPeriod: number;
  slackWebhookUrl: string;
  heapTrackingId?: string;
  locationServiceHost: string;
  locationServiceKey: string;
  linkedInScraperToken: string;
  slackToken: string;
  sendGridApiKey: string;
  senderEmail: string;
  feedbackRemainderStartDate: string;
}
