import { env } from '@/utils';
import { filesConstants } from '@/client/constants';

export const createFileNameFromUri = (uri: string) => {
  const parts = uri.split('/');
  const fileData = decodeURIComponent(parts[parts.length - 1]).split('.');
  const ext = fileData.pop();
  const fileNameParts = fileData.join('.').split(filesConstants.idSeparator);
  fileNameParts.pop();

  return {
    name: `${fileNameParts.join(filesConstants.idSeparator)}.${ext}`,
  };
};

export const getSmallImageUri = (fullImageUri: string) => {
  if (!fullImageUri.startsWith(`https://${env.awsBucketName}`)) {
    return fullImageUri;
  }

  const parts = fullImageUri.split('.');
  const ext = parts.pop();
  return `${parts.join('.')}-${filesConstants.smallImagePostfix}.${ext}`;
};

export const getFileName = (fileUri: string) => {
  const parts = fileUri.split('/');
  return parts.pop();
};
