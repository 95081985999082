enum MatchStage {
  SentToCandidate,
  SentToEmployer,
  Applicant,
  Round1,
  Round2,
  Round3,
  Hired,
  Rejected,
  Reserved,
}

export default MatchStage;
