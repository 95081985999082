import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { userSelector } from '@/client/redux/selectors';
import { getSmallImageUri } from '@/client/utils';
import useStyles from './styles';
import { IProfileImageProps } from './types';

export const ProfileImage = ({
  height = 44,
  width = 44,
  ...props
}: IProfileImageProps): JSX.Element => {
  const classes = useStyles();
  const user = useSelector(userSelector);

  return (
    <Grid container alignItems="center" {...props}>
      <Grid item className={classes.item} aria-label="profileImage">
        {user?.imageUri ? (
          <img
            src={getSmallImageUri(user.imageUri)}
            alt={`${user?.firstName} ${user?.lastName}`}
            height={height}
            width={width}
            className={classes.profileImg}
          />
        ) : (
          <div className={classes.defaultImageWrapper}>
            <img
              src="/icons/avatar.svg"
              alt={`${user?.firstName} ${user?.lastName}`}
              height={height}
              width={width}
            />
            <div className={classes.userInitials}>
              {user
                ? `${user.firstName[0].toLocaleUpperCase()}${user.lastName[0].toLocaleUpperCase()}`
                : ''}
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default ProfileImage;
