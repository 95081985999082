import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  code: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  title: {
    margin: theme.spacing(10, 0),
  },
  button: {
    width: '100%',
    maxWidth: theme.spacing(150),
  },
}));

export default useStyles;
