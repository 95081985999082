import { useMemo } from 'react';
import { useRouter } from 'next/router';
import routes from '@/client/routes';
import { ILayout } from '../types';
import MainLayout from '../MainLayout';

const GlobalLayout = ({ children }: ILayout): JSX.Element => {
  const router = useRouter();
  const Layout = useMemo(() => routes[router.pathname]?.layout ?? MainLayout, [router.pathname]);

  return <Layout>{children}</Layout>;
};

export default GlobalLayout;
