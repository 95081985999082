import { useCallback } from 'react';
import clsx from 'clsx';
import { Grid, IconButton, SwipeableDrawer, Typography, Button } from '@material-ui/core';
import { Divider, ProfileImage, LinkButton, LinkButtons, CustomLink } from '@/client/components';
import { ButtonType, IActionButton, IControlButton, IPageButton } from '@/client/types';
import useMobileMenu from './useMobileMenu';
import { IMobileMenuProps } from './types';
import useStyles from './styles';

export const MobileMenu = ({ buttons = [], ...props }: IMobileMenuProps): JSX.Element => {
  const classes = useStyles();
  const { isDrawerOpen, userName, showDrawerHandler, logoutHandler } = useMobileMenu();

  const renderControlButton = useCallback(
    (button: IControlButton) => {
      return (
        <>
          {button.isOutsideRedirect ? (
            <CustomLink href={button.redirect} className={classes.button}>
              {button.label}
            </CustomLink>
          ) : (
            <LinkButton
              href={button.redirect}
              buttonType={LinkButtons.Default}
              onClick={showDrawerHandler(false)}
              className={classes.button}
            >
              {button.label}
            </LinkButton>
          )}
        </>
      );
    },
    [classes, showDrawerHandler],
  );

  const renderActionButton = useCallback(
    (button: IActionButton) => {
      return (
        <Button onClick={button.onClick} className={classes.button}>
          {button.label}
        </Button>
      );
    },
    [classes],
  );

  const renderButtons = useCallback(
    (button: IPageButton) => {
      switch (button.type) {
        case ButtonType.ControlButton:
          return renderControlButton(button);
        default:
          return renderActionButton(button);
      }
    },
    [renderControlButton, renderActionButton],
  );

  return (
    <>
      <IconButton
        className={clsx(classes.menu, isDrawerOpen ? classes.close : '')}
        onClick={showDrawerHandler()}
        edge="start"
        color="inherit"
        aria-label="menu"
        {...props}
      >
        <span className={clsx(classes.line, classes.top)} />
        <span className={clsx(classes.line, classes.middle)} />
        <span className={clsx(classes.line, classes.bottom)} />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={showDrawerHandler(false)}
        onOpen={showDrawerHandler(true)}
        className={classes.drawer}
        PaperProps={{ className: classes.drawerPaper }}
      >
        <Grid container direction="column">
          <Grid container item alignItems="center" className={classes.profileImageContainer}>
            <Grid item>
              <ProfileImage height={30} width={30} />
            </Grid>
            <Grid item>
              <Typography component="span" className={classes.userName}>
                {userName}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {buttons.map((button) => (
            <Grid item key={button.label} className={classes.gridItem}>
              {renderButtons(button)}
            </Grid>
          ))}
          <Grid item>
            <Button onClick={logoutHandler} className={clsx(classes.button, classes.logout)}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
