import { UserRole } from '@/enums';
import { createAction } from '@reduxjs/toolkit';
import {
  IRegisterUserData,
  ILinkedInAuthorization,
  ILoadLinkedInUser,
  ICheckUserExists,
  ILoadLinkedInProfileData,
} from '../types';

const REGISTER_USER = 'AUTH/REGISTER_USER';
const LINKEDIN_LOGIN = 'AUTH/LINKEDIN_LOGIN';
const LINKEDIN_SIGNUP = 'AUTH/LINKEDIN_SIGNUP';
const LINKEDIN_USER = 'AUTH/LINKEDIN_USER';
const LINKEDIN_PROFILE = 'AUTH/LINKEDIN_PROFILE';
const SEND_FORGOT_EMAIL = 'AUTH/SEND_FORGOT_EMAIL';
const RESET_PASSWORD = 'AUTH/RESET_PASSWORD';
const CHECK_USER_EXISTS = 'AUTH/CHECK_USER_EXISTS';

export const loginWithLinkedIn = createAction<ILinkedInAuthorization, typeof LINKEDIN_LOGIN>(
  LINKEDIN_LOGIN,
);

export const registerUser = createAction<IRegisterUserData, typeof REGISTER_USER>(REGISTER_USER);

export const signupWithLinkedIn = createAction<
  {
    role: UserRole;
    accessToken: string;
  },
  typeof LINKEDIN_SIGNUP
>(LINKEDIN_SIGNUP);

export const loadLinkedInUser = createAction<ILoadLinkedInUser, typeof LINKEDIN_USER>(
  LINKEDIN_USER,
);

export const loadLinkedInProfileData = createAction<
  ILoadLinkedInProfileData,
  typeof LINKEDIN_PROFILE
>(LINKEDIN_PROFILE);

export const sendForgotEmail = createAction<{ email: string }, typeof SEND_FORGOT_EMAIL>(
  SEND_FORGOT_EMAIL,
);

export const resetPassword = createAction<
  { token: string; password: string },
  typeof RESET_PASSWORD
>(RESET_PASSWORD);

export const checkUserExists = createAction<ICheckUserExists, typeof CHECK_USER_EXISTS>(
  CHECK_USER_EXISTS,
);
