import { IWelcomeFormData } from '@/client/forms/onboarding/employer/WelcomeForm/types';
import { InformationChannel } from '@/enums';

export const requestEmployerOnboarding = (data: IWelcomeFormData): FormData => {
  const fd = new FormData();

  fd.append('employer[channel]', data.channel || InformationChannel.Other);

  fd.append('company[name]', data.companyName);
  fd.append('company[description]', data.description);
  fd.append('company[website]', data.website);

  fd.append('address[country]', data.country?.name || '');
  fd.append('address[city]', data.city?.name || '');
  fd.append('address[cityId]', data.city?.id.toString() || '');

  fd.append('file', data.companyLogo || '');

  return fd;
};
