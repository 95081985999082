import { ButtonType, IControlButton, IPageButton } from '@/client/types';
import { UserRole } from '@/enums';

const controlButtonsForEmployer: IControlButton[] = [
  {
    type: ButtonType.ControlButton,
    label: 'Browse Candidates',
    redirect: '/candidates',
    shouldBeShown: () => {
      return false;
    },
  },
  { type: ButtonType.ControlButton, label: 'My Jobs', redirect: '/my-jobs' },
  {
    type: ButtonType.ControlButton,
    label: 'Employer Resources',
    redirect: 'https://www.alariss.com/employer-resources',
    isOutsideRedirect: true,
  },
  {
    type: ButtonType.ControlButton,
    label: 'Post a job',
    redirect: '/post-job',
    isCustomButton: true,
    hideOnActiveRoute: true,
  },
  {
    type: ButtonType.ControlButton,
    label: 'Company Profile',
    redirect: '/profile',
    isPopoverButton: true,
  },
  {
    type: ButtonType.ControlButton,
    label: 'Settings',
    redirect: '/profile?settings',
    isPopoverButton: true,
  },
];

const defaultControlButtons: Record<UserRole, IControlButton[]> = {
  [UserRole.Candidate]: [
    {
      type: ButtonType.ControlButton,
      label: 'My Matches',
      redirect: '/',
    },
    {
      type: ButtonType.ControlButton,
      label: 'Browse Jobs',
      redirect: '/jobs',
    },
    {
      type: ButtonType.ControlButton,
      label: 'My Jobs',
      redirect: '/my-jobs',
      showNotification: true,
    },
    {
      type: ButtonType.ControlButton,
      label: 'Career Resources',
      redirect: 'https://www.alariss.com/career-resources',
      isOutsideRedirect: true,
    },
    {
      type: ButtonType.ControlButton,
      label: 'Profile',
      redirect: '/profile',
      isPopoverButton: true,
    },
    {
      type: ButtonType.ControlButton,
      label: 'Settings',
      redirect: '/profile?settings',
      isPopoverButton: true,
    },
  ],
  [UserRole.MasterEmployer]: [
    {
      type: ButtonType.ControlButton,
      label: 'Manage Company',
      redirect: '/manage-company',
    },
    ...controlButtonsForEmployer,
  ],
  [UserRole.AdminEmployer]: [
    {
      type: ButtonType.ControlButton,
      label: 'Manage Company',
      redirect: '/manage-company',
    },
    ...controlButtonsForEmployer,
  ],
  [UserRole.Employer]: controlButtonsForEmployer,
  [UserRole.Admin]: [
    {
      type: ButtonType.ControlButton,
      label: 'Imports',
      redirect: '/admin/imports',
    },
    {
      type: ButtonType.ControlButton,
      label: 'Emails',
      redirect: '/admin/email-templates',
    },
    {
      type: ButtonType.ControlButton,
      label: 'Jobs',
      redirect: '/admin/jobs',
    },
    {
      type: ButtonType.ControlButton,
      label: 'Companies',
      redirect: '/admin/companies',
    },
    {
      type: ButtonType.ControlButton,
      label: 'Employers',
      redirect: '/admin/employers',
    },
    {
      type: ButtonType.ControlButton,
      label: 'Candidates',
      redirect: '/admin/candidates',
    },
    {
      type: ButtonType.ControlButton,
      label: 'Profile',
      redirect: '/admin/profile',
      isPopoverButton: true,
    },
  ],
};

const getControlButtons = (
  role: UserRole,
  isOnboardingFinished: boolean,
  path: string,
  hasPostedJob?: boolean,
): IPageButton[] => {
  const buttons = defaultControlButtons[role].filter((button) => {
    return (
      !button.shouldBeShown ||
      button.shouldBeShown({
        role,
        isOnboardingFinished,
        path,
        hasPostedJob,
      })
    );
  });

  if (role === UserRole.Candidate && !isOnboardingFinished) {
    buttons.push({
      type: ButtonType.ControlButton,
      label: 'Finish Onboarding',
      redirect: '/onboarding/candidate',
      isCustomButton: true,
      hideOnActiveRoute: true,
    });
  }

  return buttons
    .filter((button) => {
      // Handle hiding active buttons
      const shouldHideActiveButton = button.hideOnActiveRoute && button.redirect === path;
      // Handle hiding profile links during onboarding
      const shouldHideProfileButton =
        !isOnboardingFinished &&
        button.redirect !== undefined &&
        button.redirect.startsWith('/profile');

      return !shouldHideActiveButton && !shouldHideProfileButton;
    })
    .map((button) => ({
      ...button,
      isCurrentPage:
        button.redirect === '/'
          ? path === button.redirect
          : button.redirect !== undefined && path.startsWith(button.redirect),
    }));
};

export default getControlButtons;
