import { ButtonBase, Button as DefaultButton } from '@material-ui/core';
import { Button } from '@/client/components';
import { LinkButtons } from './types';

const useLinkButton = (buttonType: LinkButtons) => {
  switch (buttonType) {
    case LinkButtons.Base:
      return ButtonBase;
    case LinkButtons.Custom:
      return Button;
    default:
      return DefaultButton;
  }
};

export default useLinkButton;
