import { IToastType } from './types';

export const toastTypes = ['success', 'error'];

export const toastIconMapping: Record<IToastType, string> = {
  success: '/icons/check-circle-fill.svg',
  error: '/icons/exclamation-circle-fill.svg',
  info: '/icons/exclamation-square-fill.svg',
  warning: '/icons/exclamation-triangle-fill.svg',
};
