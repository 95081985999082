import { createTheme } from '@material-ui/core/styles';
import soehne from './fonts';

const theme = createTheme({
  palette: {
    primary: {
      light: '#EDF5FD',
      main: '#1B70BB',
      dark: '#8998AE',
    },
    secondary: {
      light: '#E5E5E5',
      main: '#BFBFBF',
      dark: '#DDE3EF',
    },
    text: {
      primary: '#242529',
      secondary: '#83879C',
    },
    error: {
      light: '#FCEEEF',
      main: '#DA1E28',
      dark: '#F7E4E5',
    },
    success: { main: '#12B76A', light: '#E8F5EF' },
    warning: { main: '#F5A623', light: '#F9E9CF' },
    titleBlack: '#333537',
    blackMain: '#242529',
    secondaryBlack: '#83879C',
    defaultText: '#B5B7C4',
    border: '#DDE3EF',
    white: '#FFFFFF',
    darkBlue: '#30375A',
    mainBlue: '#1B70BB',
    lightBlue: '#479CEB',
    secondaryBlue: '#1A83E0',
    bg: '#EDF5FD',
    bgForFooter: '#F3F7FD',
    dropdown: '#F1F4F9',
    mainGreen: '#0FBC49',
    lightGreen: '#11D452',
    midnight: '#152144',
  },
  typography: {
    fontFamily: 'Soehne, sans-serif',
    fontSize: 14,
    fonts: {
      size: {
        xs: 12,
        sm: 14,
        md: 16,
        lg: 18,
        xl: 20,
      },
      lineHeight: {
        xs: '16px',
        sm: '18px',
        md: '22px',
        lg: '24px',
        xl: '26px',
      },
    },
  },
  spacing: 2,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': soehne,
      },
    },
  },
});

export default theme;
