import {
  ICandidateInterviewDto,
  IJobApplicantDto,
  IJobInterviewDto,
  IJobShortInfoDto,
  IJobInfoDTO,
  ICandidateApplicationDto,
} from '@/DTO';
import { ApplicantStatus, InterviewStatus } from '@/enums';
import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, IMyJobsState } from '../types';

const initialState: IMyJobsState = {
  employerJobs: [],
  selectedEmployerJob: null,
  jobApplicants: [],
  jobInterviews: [],
  candidateApplicants: [],
  candidateInterviews: [],
  countNewCandidateInterviews: 0,
  isLoading: false,
};

export const myJobsSlice = createSlice({
  name: 'myJobs',
  initialState,
  reducers: {
    storeEmployerJobList: (state, action: IActionWithPayload<IJobInfoDTO[]>) => {
      state.employerJobs = action.payload;
      state.isLoading = false;
    },
    storeJobApplicants: (state, action: IActionWithPayload<IJobApplicantDto[]>) => {
      state.jobApplicants = action.payload;
      state.isLoading = false;
    },
    storeJobInterviews: (state, action: IActionWithPayload<IJobInterviewDto[]>) => {
      state.jobInterviews = action.payload;
      state.isLoading = false;
    },
    storeCandidateApplicants: (state, action: IActionWithPayload<ICandidateApplicationDto[]>) => {
      state.candidateApplicants = action.payload;
    },
    storeCandidateInterviews: (state, action: IActionWithPayload<ICandidateInterviewDto[]>) => {
      state.candidateInterviews = action.payload;
      state.isLoading = false;
    },
    storeCountNewInterviews: (state, action: IActionWithPayload<number>) => {
      state.countNewCandidateInterviews = action.payload;
    },
    selectEmployerJob: (state, action: IActionWithPayload<IJobShortInfoDto>) => {
      state.selectedEmployerJob = action.payload;
    },
    updateCandidateInterviewStatus: (
      state,
      action: IActionWithPayload<{ interviewId: string; status: InterviewStatus }>,
    ) => {
      const updatedInterview = state.candidateInterviews.find(
        (interview) => interview.id === action.payload.interviewId,
      );
      if (updatedInterview) {
        updatedInterview.status = action.payload.status;
      }
    },
    updateJobApplicantStatus(
      state,
      action: IActionWithPayload<{ applicantId: string; status: ApplicantStatus }>,
    ) {
      const updatedApplicant = state.jobApplicants.find(
        (applicant) => applicant.applicationId === action.payload.applicantId,
      );
      if (updatedApplicant) {
        updatedApplicant.status = action.payload.status;
      }
    },
    updateJobInterviewStatus: (
      state,
      action: IActionWithPayload<{ interviewId: string; status: InterviewStatus }>,
    ) => {
      const updatedInterview = state.jobInterviews.find(
        (interview) => interview.id === action.payload.interviewId,
      );
      if (updatedInterview) {
        updatedInterview.status = action.payload.status;
      }
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    clearMyJobs: (state) => {
      state.candidateApplicants = [];
      state.candidateInterviews = [];
      state.employerJobs = [];
      state.jobApplicants = [];
      state.jobInterviews = [];
    },
    clearSelectedEmployerJob: (state) => {
      state.selectedEmployerJob = null;
    },
  },
});

export const {
  storeEmployerJobList,
  storeJobApplicants,
  storeJobInterviews,
  storeCandidateApplicants,
  storeCandidateInterviews,
  storeCountNewInterviews,
  selectEmployerJob,
  clearSelectedEmployerJob,
  updateCandidateInterviewStatus,
  startLoading,
  stopLoading,
  clearMyJobs,
  updateJobApplicantStatus,
  updateJobInterviewStatus,
} = myJobsSlice.actions;

export default myJobsSlice.reducer;
