import enUS from 'date-fns/locale/en-US';
import { differenceInDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const generateYears = (year = new Date().getFullYear() + 4, initYear = 1950) =>
  Array.from(new Array(year - initYear), (val, index) => year - index);

export const daysFromNow = (date: Date | string) => {
  const difference = differenceInDays(new Date(), new Date(date));
  return `${difference} ${difference === 1 ? 'day' : 'days'}`;
};

export const getDatePSTFormat = (date: Date | string, format: string) => {
  return formatInTimeZone(new Date(date), 'America/Los_Angeles', format, { locale: enUS });
};
