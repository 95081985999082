enum RejectionReason {
  Overqualified,
  Underqualified,
  CompensationMismatch,
  LackOfInterest,
  CultureFit,
  WeakIndustryExperience,
  Other,
}

export default RejectionReason;
