import { ICreateAdminFormData } from '@/client/components/Modals/CreateAdminModal/types';

export const requestCreateAdmin = (data: ICreateAdminFormData): FormData => {
  const fd = new FormData();
  const { profilePicture, ...rest } = data;

  if (profilePicture) {
    fd.append('file', profilePicture);
  }

  Object.entries(rest).forEach(([key, value]) => {
    fd.append(key, value);
  });

  return fd;
};
