import { JobCardType, MatchStage, RejectedBy, RejectionReason } from '@/enums';

const LIMIT_MATCHES = 10;
const DEFAULT_JOB_MATCHES_PERPAGE = 100;
const CANDIDATE_IS_VETTED_PROCESS_NAME = 'Candidate is vetted process';
const JOB_IS_VETTED_PROCESS_NAME = 'Job is vetted process';

export const adminAllTabJobCards = [
  JobCardType.RoundThird,
  JobCardType.RoundSecond,
  JobCardType.RoundFirst,
  JobCardType.SentToEmployer,
  JobCardType.SentToCandidate,
  JobCardType.Rejected,
];

export const employerAllTabJobCards = [
  JobCardType.RoundThird,
  JobCardType.RoundSecond,
  JobCardType.RoundFirst,
  JobCardType.SentToEmployer,
  JobCardType.Rejected,
  JobCardType.Finalize,
];

export const matchesStageList = [
  MatchStage.SentToCandidate,
  MatchStage.SentToEmployer,
  MatchStage.Reserved,
];

export const rejectedByMapping: Record<RejectedBy, string> = {
  [RejectedBy.Client]: 'Rejected By Client',
  [RejectedBy.Candidate]: 'Rejected By Candidate',
  [RejectedBy.Admin]: 'Rejected By Admin',
};

export const rejectionReasonMapping: { [key: number]: string } = {
  ...RejectionReason,
  [RejectionReason.CompensationMismatch]: 'Compensation Mismatch',
  [RejectionReason.LackOfInterest]: 'Lack of Interest',
  [RejectionReason.CultureFit]: 'Culture Fit',
  [RejectionReason.WeakIndustryExperience]: 'Weak Industry Experience',
};

export default {
  limitMatches: LIMIT_MATCHES,
  defaultJobMatchesPerPage: DEFAULT_JOB_MATCHES_PERPAGE,
  candidateIsVettedProcessName: CANDIDATE_IS_VETTED_PROCESS_NAME,
  jobIsVettedProcessName: JOB_IS_VETTED_PROCESS_NAME,
  adminAllTabJobCards,
  employerAllTabJobCards,
};
