enum InformationChannel {
  SearchEngine = 'Search Engine',
  LinkedIn = 'LinkedIn',
  Twitter = 'Twitter',
  Indeed = 'Indeed',
  Glassdoor = 'Glassdoor',
  Ladders = 'Ladders',
  AngelList = 'AngelList',
  Referral = 'Referral',
  AlarissJobBoard = 'Alariss Job Board',
  RecruiterOutreach = 'Recruiter Outreach',
  Other = 'Other',
}

export default InformationChannel;
