import { useMemo } from 'react';

import { initializeStore } from '@/client/redux/store';
import { IState } from '@/client/redux/types';

export default function useStore(initialState?: IState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);

  return store;
}
