import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    '#__next': {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      background: '#fff',
    },
    ':root': {
      '--ck-border-radius': '8px !important',
      '--ck-color-shadow-inner': 'none !important',
      '--ck-inner-shadow': 'none',
      '--ck-spacing-large': 'var(--ck-spacing-standard)',
      '--ck-color-focus-border': '#83879c !important',
      '--ck-color-toolbar-border': '#DDE3EF !important',
      '--ck-color-base-border': '#DDE3EF !important',
    },
    '.ck.ck-error': {
      '& .ck.ck-toolbar, & .ck.ck-content.ck-editor__editable': {
        borderColor: '#DA1E28 !important',
      },
      '& .ck.ck-content.ck-editor__editable': {
        borderTopColor: '#DDE3EF !important',
      },
    },
    '.ck.ck-editor': {
      display: 'flex',
      flexDirection: 'column',
      height: '100% !important',
      width: '100% !important',
      flexGrow: 1,
    },
    '.ck.ck-editor__main': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
    },
    '.ck.ck-content.ck-editor__editable': {
      flexGrow: 1,
      overflow: 'scroll',
      '& ul, & ol': {
        paddingInlineStart: 20,
      },
    },
    '.ck-placeholder': {
      fontSize: '14px !important',
      fontWeight: 'normal !important',
      color: '#BFBFBF !important',
    },
    '.ck.ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'absolute !important',
      boxShadow: 'none !important',
      '& > .ck.ck-toolbar.ck-toolbar_grouping': {
        borderBottomWidth: '0 !important',
      },
    },
  },
}));

const GlobalStyles = (): null => {
  useStyles();

  return null;
};

export default GlobalStyles;
