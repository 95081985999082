import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    maxWidth: '1440px',
    width: '100%',
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(10, 10, 20),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 16, 30, 16),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(25, 10, 40, 10),
    },
  },
  fullWidthContent: {
    maxWidth: '100%',
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
}));

export default useStyles;
