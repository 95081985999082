import { createSlice } from '@reduxjs/toolkit';
import { ICityBase, IRegion } from '@/client/types';
import { IActionWithPayload, IFormatedOnboardingResult, IOnboardingState } from '../types';

const initialState: IOnboardingState = {
  activeStep: 0,
  regions: [],
  cities: [],
  onboarding: null,
  fetching: false,
  loading: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setActiveStep: (state, action: IActionWithPayload<number>) => {
      state.activeStep = action.payload;
    },
    handleNext: (state) => {
      state.activeStep += 1;
    },
    handleBack: (state) => {
      state.activeStep -= 1;
    },
    storeOnboardingRegions: (state, action: IActionWithPayload<IRegion[]>) => {
      state.regions = action.payload;
    },
    storeOnboardingCities: (state, action: IActionWithPayload<ICityBase[]>) => {
      state.cities = action.payload;
    },
    storeOnboardingData: (state, action: IActionWithPayload<IFormatedOnboardingResult | null>) => {
      state.onboarding = action.payload;
    },
    storeOnboardingCandidateData: (
      state,
      { payload }: IActionWithPayload<Partial<IFormatedOnboardingResult>>,
    ) => {
      state.onboarding = { ...state.onboarding, ...payload };
    },
    storeOnboardingFetching: (state, { payload }: IActionWithPayload<boolean>) => {
      state.fetching = payload;
    },
    storeOnboardingLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.loading = payload;
    },
    clearOnboardingData: (state) => {
      state.activeStep = 0;
      state.regions = [];
      state.cities = [];
      state.onboarding = null;
    },
  },
});

export const {
  setActiveStep,
  handleNext,
  handleBack,
  storeOnboardingRegions,
  storeOnboardingCities,
  storeOnboardingData,
  storeOnboardingLoading,
  storeOnboardingCandidateData,
  storeOnboardingFetching,
  clearOnboardingData,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
