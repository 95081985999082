import { IApplyToJobPayload } from '@/client/redux/types';

export const requestApplyToJob = (data: IApplyToJobPayload): FormData => {
  const fd = new FormData();

  fd.append('jobId', data.jobId);
  fd.append('message', data.message);
  if (data.file) {
    fd.append('file', data.file);
  }

  return fd;
};
