import {
  IIndustryExperienceAttributes,
  IJobFunctionAttributes,
  IVerticalAttributes,
  ISalesQualificationAttributes,
} from '@/server/database';
import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';

const getJobFunctions = async () => {
  const functions = await oAuth()
    .axios.get('/job-functions')
    .then((res: AxiosResponse<IJobFunctionAttributes[]>) => res.data);

  return functions;
};

const getIndustryExperience = () =>
  oAuth()
    .axios.get('/industry-experience')
    .then((res: AxiosResponse<IIndustryExperienceAttributes[]>) => res.data);

const getVerticals = () =>
  oAuth()
    .axios.get('/verticals')
    .then((res: AxiosResponse<IVerticalAttributes[]>) => res.data);

const createVertical = (verticals: IVerticalAttributes[]) => {
  const data = {
    verticals: verticals.map((item) => item.value),
  };

  return oAuth()
    .axios.put('/admin/verticals/create', data)
    .then((res: AxiosResponse<IVerticalAttributes[]>) => res.data);
};

const getSalesQualifications = () =>
  oAuth()
    .axios.get('/sales-qualifications')
    .then((res: AxiosResponse<ISalesQualificationAttributes[]>) => res.data);

export default {
  getJobFunctions,
  getIndustryExperience,
  getVerticals,
  createVertical,
  getSalesQualifications,
};
