import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F3F7FD',
  },
  content: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(23, 0, 8, 0),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(25),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '1150px',
      flexDirection: 'row',
      padding: theme.spacing(32, 10, 32, 10),
    },
  },
  title: {
    marginBottom: theme.spacing(12),
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '30px',
    },
  },
  link: {
    marginBottom: theme.spacing(8),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  text: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.text.secondary,
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fonts.size.sm,
      fontWeight: 300,
      lineHeight: theme.typography.fonts.lineHeight.sm,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6),
      fontSize: theme.typography.fonts.size.md,
      lineHeight: theme.typography.fonts.lineHeight.md,
    },
  },
  icon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      width: '32px',
      height: '32px',
    },
  },
  logo: {
    width: '50px',
    height: '34px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      width: '64px',
      height: '42px',
      marginBottom: theme.spacing(20),
    },
  },
  linksBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: theme.spacing(0, 10),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 'auto'),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      margin: theme.spacing(0),
    },
  },
  listBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(15),
    '&:last-child': {
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '160px',
      marginRight: theme.spacing(15),
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(30),
      marginBottom: theme.spacing(0),
    },
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    margin: theme.spacing(0, 5),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(0),
      padding: theme.spacing(0, 20),
      borderTop: `1px solid ${theme.palette.secondary.dark}`,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '350px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: theme.spacing(0),
      borderTop: 'none',
    },
  },
  socialBlock: {
    width: '234px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      width: '312px',
      marginTop: theme.spacing(20),
    },
  },
  copyrightBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '284px',
    padding: theme.spacing(8, 5, 0, 5),
    borderTop: `1px solid ${theme.palette.secondary.dark}`,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      borderTop: 'none',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(0),
    },
  },
  rightsReserved: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
