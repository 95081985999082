import { messages } from '@/client/constants';
import { applicantsService, interviewsService, jobsService } from '@/client/services';
import { handleRequestError } from '@/client/utils';
import {
  ICandidateApplicationDto,
  ICandidateInterviewDto,
  IJobApplicantDto,
  IJobInfoDTO,
  IJobShortInfoDto,
} from '@/DTO';
import { IApplicantAttributes, IInterviewAttributes } from '@/server/database';
import toast from 'react-hot-toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  archiveJob,
  candidateDeclineInterview,
  employerDeclineApplication,
  employerDeclineInterview,
  loadCandidateApplicants,
  loadCandidateInterviews,
  loadCountNewCandateInterviews,
  loadEmployerJobs,
  loadJobApplicants,
  loadSelectedEmployerJob,
} from '../actions';
import {
  clearMyJobs,
  selectEmployerJob,
  startLoading,
  stopLoading,
  storeCandidateApplicants,
  storeCandidateInterviews,
  storeCountNewInterviews,
  storeEmployerJobList,
  storeJobApplicants,
  updateCandidateInterviewStatus,
  updateJobApplicantStatus,
  updateJobInterviewStatus,
} from '../store/my-jobs.slice';
import { IActionWithPayload, IDeclineInterviewProps } from '../types';

function* loadEmployerJobsSaga() {
  try {
    yield put(startLoading());
    const jobs: IJobInfoDTO[] = yield call(jobsService.getEmployerJobs);

    yield put(storeEmployerJobList(jobs));
  } catch (error) {
    yield put(stopLoading());
    handleRequestError(error);
  }
}

function* loadJobApplicantsSaga(action: IActionWithPayload<string>) {
  try {
    yield put(startLoading());

    const applicants: IJobApplicantDto[] = yield call(
      applicantsService.getEmployerApplicants,
      action.payload,
    );

    yield put(storeJobApplicants(applicants));
  } catch (error) {
    yield put(stopLoading());
    handleRequestError(error);
  }
}

function* loadSelectedEmployerJobSaga(action: IActionWithPayload<string>) {
  try {
    const selectedJob: IJobShortInfoDto = yield call(jobsService.getEmployerJob, action.payload);

    yield put(selectEmployerJob(selectedJob));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadCandidateApplicantsSaga() {
  try {
    yield put(startLoading());

    const applicants: ICandidateApplicationDto[] = yield call(
      applicantsService.getCandidateApplicants,
    );

    yield put(storeCandidateApplicants(applicants));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(stopLoading());
  }
}

function* loadCandidateInterviewsSaga() {
  try {
    yield put(startLoading());

    const interviews: ICandidateInterviewDto[] = yield call(
      interviewsService.getCandidateInterviews,
    );

    yield put(storeCandidateInterviews(interviews));
  } catch (error) {
    yield put(stopLoading());
    handleRequestError(error);
  }
}

function* loadCountNewCandateInterviewsSaga() {
  try {
    const countNewInterviews: number = yield call(interviewsService.getCountNewCandidateInterviews);

    yield put(storeCountNewInterviews(countNewInterviews));
  } catch (error) {
    handleRequestError(error);
  }
}

function* declineInterviewSaga({ payload }: IActionWithPayload<IDeclineInterviewProps>) {
  try {
    const declinedInterview: IInterviewAttributes = yield call(
      interviewsService.declineInterview,
      payload.interviewId,
    );
    yield put(
      updateCandidateInterviewStatus({
        interviewId: declinedInterview.id,
        status: declinedInterview.status,
      }),
    );
    yield put(
      updateJobInterviewStatus({
        interviewId: declinedInterview.id,
        status: declinedInterview.status,
      }),
    );
    payload.onSuccess?.();
    toast.success(messages.interviewDeclined);
  } catch (error) {
    handleRequestError(error);
  }
}
function* employerDeclineApplicationSaga(action: IActionWithPayload<string>) {
  try {
    const { id, status }: IApplicantAttributes = yield call(
      applicantsService.declineApplication,
      action.payload,
    );
    yield put(updateJobApplicantStatus({ applicantId: id, status }));
    toast.success(messages.applicationDeclined);
  } catch (error) {
    handleRequestError(error);
  }
}
function* archiveJobSaga(action: IActionWithPayload<string>) {
  try {
    yield call(jobsService.archiveJob, action.payload);
    yield put(clearMyJobs());
    toast.success(messages.jobArchived);
    yield put(loadEmployerJobs());
  } catch (error) {
    handleRequestError(error);
  }
}

export default function* postJobRootSaga() {
  yield all([
    takeLatest(loadEmployerJobs.type, loadEmployerJobsSaga),
    takeLatest(loadJobApplicants.type, loadJobApplicantsSaga),
    takeLatest(loadSelectedEmployerJob.type, loadSelectedEmployerJobSaga),
    takeLatest(loadCandidateApplicants.type, loadCandidateApplicantsSaga),
    takeLatest(loadCandidateInterviews.type, loadCandidateInterviewsSaga),
    takeLatest(loadCountNewCandateInterviews.type, loadCountNewCandateInterviewsSaga),
    takeLatest(employerDeclineApplication.type, employerDeclineApplicationSaga),
    takeLatest(candidateDeclineInterview.type, declineInterviewSaga),
    takeLatest(employerDeclineInterview.type, declineInterviewSaga),
    takeLatest(archiveJob.type, archiveJobSaga),
  ]);
}
