import {
  IIndustryExperienceAttributes,
  IJobFunctionAttributes,
  IVerticalAttributes,
  ISalesQualificationAttributes,
} from '@/server/database';
import { createSlice } from '@reduxjs/toolkit';
import { IRegion } from '@/client/types';
import {
  IActionWithPayload,
  ISetHeaderCenteredLayoutPayload,
  ISetFixedCenteredHeaderPayload,
  ISettingsState,
} from '../types';

const initialState: ISettingsState = {
  jobFunctions: [],
  industryExperience: [],
  verticals: [],
  salesQualifications: [],
  isHeaderFixed: false,
  isHeaderFullWidth: false,
  headerBackButtonHandler: null,
  regions: [],
};
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    storeJobFunctions: (state, action: IActionWithPayload<IJobFunctionAttributes[]>) => {
      state.jobFunctions = action.payload;
    },
    storeIndustryExperience: (
      state,
      action: IActionWithPayload<IIndustryExperienceAttributes[]>,
    ) => {
      state.industryExperience = action.payload;
    },
    storeVerticals: (state, action: IActionWithPayload<IVerticalAttributes[]>) => {
      state.verticals = action.payload;
    },
    storeSalesQualifications: (
      state,
      action: IActionWithPayload<ISalesQualificationAttributes[]>,
    ) => {
      state.salesQualifications = action.payload;
    },
    setHeaderCenteredLayout: (
      state,
      action: IActionWithPayload<ISetHeaderCenteredLayoutPayload>,
    ) => {
      state.headerBackButtonHandler = action.payload.onBack ?? null;
    },
    setHeaderFixed: (state, action: IActionWithPayload<boolean>) => {
      state.isHeaderFixed = action.payload;
    },
    setHeaderFixedCenteredLayout: (
      state,
      { payload }: IActionWithPayload<ISetFixedCenteredHeaderPayload>,
    ) => {
      state.isHeaderFixed = payload.isHeaderFixed;
      state.headerBackButtonHandler = payload.onBack ?? null;
    },
    storeRegions: (state, action: IActionWithPayload<IRegion[]>) => {
      state.regions = action.payload;
    },
    setHeaderFullWidth: (state, action: IActionWithPayload<boolean>) => {
      state.isHeaderFullWidth = action.payload;
    },
  },
});

export const {
  storeJobFunctions,
  storeIndustryExperience,
  storeVerticals,
  storeSalesQualifications,
  setHeaderCenteredLayout,
  setHeaderFixed,
  setHeaderFixedCenteredLayout,
  storeRegions,
  setHeaderFullWidth,
} = settingsSlice.actions;

export const settingsIgnoredPaths = ['settings.headerBackButtonHandler', 'payload.onBack'];

export default settingsSlice.reducer;
