import { env } from '@/utils';

const baseURL = '/api/geo-proxy';
const originalBaseURL = `https://${env.locationServiceHost}/v1/geo`;

const regionsUrl = (countryCode: string) => `/countries/${countryCode}/regions`;

const regionCitiesUrl = (countryCode: string, regionCode: string) =>
  `/countries/${countryCode}/regions/${regionCode}/cities`;

const countryCitiesUrl = '/cities';

export default { baseURL, originalBaseURL, regionsUrl, regionCitiesUrl, countryCitiesUrl };
