import { createAction } from '@reduxjs/toolkit';
import { IWelcomeFormData } from '@/client/forms/onboarding/employer/WelcomeForm/types';
import { ISettingsFormData } from '@/client/forms/profile/employer/SettingsForm/types';
import {
  ICreateEmployerInterviewer,
  IInviteEmployer,
  IUpdateEmployerData,
} from '@/client/redux/types';
import { IManageCompanyQuery } from '@/requests';

const EMPLOYER_ONBOARDING = 'EMPLOYER/ONBOARDING';
const EMPLOYER_PROFILE_SETTINGS = 'EMPLOYER/PROFILE_SETTINGS';
const EMPLOYER_PROFILE_DATA = 'EMPLOYER/PROFILE_DATA';
const EMPLOYER_TERMS_DATA = 'EMPLOYER/TERMS_DATA';
const EMPLOYER_PROFILE_DATA_FINISHED = 'EMPLOYER/PROFILE_DATA_FINISHED';
const EMPLOYER_PROFILE_IMAGE = 'EMPLOYER/PROFILE_IMAGE';
const EMPLOYER_INVITE = 'EMPLOYER/INVITE';
const EMPLOYER_MANAGE_COMPANY_DATA = 'EMPLOYER/MANAGE_COMPANY_DATA';
const EMPLOYER_INTERVIEWER_CREATE = 'EMPLOYER/INTERVIEWER_CREATE';

export const employerOnboarding = createAction<IWelcomeFormData, typeof EMPLOYER_ONBOARDING>(
  EMPLOYER_ONBOARDING,
);

export const updateEmployerSettingsProfile = createAction<
  IUpdateEmployerData<ISettingsFormData>,
  typeof EMPLOYER_PROFILE_SETTINGS
>(EMPLOYER_PROFILE_SETTINGS);

export const uploadEmployerProfileAvatar = createAction<
  IUpdateEmployerData<File>,
  typeof EMPLOYER_PROFILE_IMAGE
>(EMPLOYER_PROFILE_IMAGE);

export const getEmployerProfileData = createAction<string | undefined>(EMPLOYER_PROFILE_DATA);

export const getEmployerTermsData = createAction(EMPLOYER_TERMS_DATA);

export const getEmployerProfileDataFinished = createAction(EMPLOYER_PROFILE_DATA_FINISHED);

export const inviteEmployer = createAction<IInviteEmployer>(EMPLOYER_INVITE);

export const getEmployerManageCompanyData = createAction<
  IManageCompanyQuery,
  typeof EMPLOYER_MANAGE_COMPANY_DATA
>(EMPLOYER_MANAGE_COMPANY_DATA);

export const createEmployerInterviewer = createAction<
  ICreateEmployerInterviewer,
  typeof EMPLOYER_INTERVIEWER_CREATE
>(EMPLOYER_INTERVIEWER_CREATE);
