import { ICandidateMatchJobDto, IAlgorithmMatchScoreParamsDto, IMatchChangesDto } from '@/DTO';
import { ICandidateMatchShortInfoResponse } from '@/responses';
import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, IMatchesState } from '../types';

const initialState: IMatchesState = {
  candidateMatches: [],
  isLoading: false,
  matchScoreParams: null,
  matchChanges: [],
  hasMore: true,
  candidateMatchesShortInfo: [],
  page: 1,
  perPage: 10,
  total: 0,
};
const matchesSlice = createSlice({
  name: 'matches',
  initialState,
  reducers: {
    storeMatchesForCandidate(state, action: IActionWithPayload<ICandidateMatchJobDto[]>) {
      state.candidateMatches = action.payload;
      state.isLoading = false;
    },
    markJobAppliedForCandidate(state, action: IActionWithPayload<string>) {
      const candidateMatch = state.candidateMatches.find(({ id }) => id === action.payload);
      if (candidateMatch) {
        candidateMatch.isApplied = true;
      }
    },
    clearMatches: (state) => {
      state.candidateMatches = [];
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    storeMatchScoreParams: (
      state,
      action: IActionWithPayload<IAlgorithmMatchScoreParamsDto | null>,
    ) => {
      state.matchScoreParams = action.payload;
    },
    storeMatchChanges: (state, { payload }: IActionWithPayload<IMatchChangesDto[]>) => {
      state.matchChanges = payload;
    },

    storeCandidateMatchesShortInfo: (
      state,
      action: IActionWithPayload<ICandidateMatchShortInfoResponse>,
    ) => {
      state.hasMore = action.payload.hasMore;
      state.total = action.payload.total;
      state.isLoading = false;
      if (action.payload.data.length) {
        const existingMatchesShortInfoSet = new Set(
          state.candidateMatchesShortInfo.map((candidate) => candidate.id),
        );
        state.candidateMatchesShortInfo.push(
          ...action.payload.data.filter(
            (candidateShortMatch) => !existingMatchesShortInfoSet.has(candidateShortMatch.id),
          ),
        );
        state.page += 1;
      }
    },
    loadCandidateMatchesShortInfoFailed: (state) => {
      state.hasMore = false;
      state.isLoading = false;
    },
    clearMatchesShortInfoData: (state) => {
      state.isLoading = false;
      state.hasMore = true;
      state.candidateMatchesShortInfo = [];
      state.page = 1;
      state.total = 0;
    },
  },
});

export const {
  storeMatchesForCandidate,
  markJobAppliedForCandidate,
  clearMatches,
  startLoading,
  stopLoading,
  storeMatchScoreParams,
  storeMatchChanges,
  storeCandidateMatchesShortInfo,
  loadCandidateMatchesShortInfoFailed,
  clearMatchesShortInfoData,
} = matchesSlice.actions;

export default matchesSlice.reducer;
