import { IState } from '@/client/redux/types';
import { createSelector } from 'reselect';

export const authorizationsStateSelector = ({ auth }: IState) => auth;
export const userSelector = createSelector(authorizationsStateSelector, (state) => state.user);
export const userImageSelector = createSelector(userSelector, (user) => user?.imageUri);
export const userIdSelector = createSelector(userSelector, (state) => state?.id);
export const userVerifiedSelector = createSelector(
  userSelector,
  (state) => state?.isVerified && state?.isOnboardingFinished,
);
export const userOnboardingFinishedVerifiedSelector = createSelector(
  userSelector,
  (state) => state?.isOnboardingFinished,
);
export const isSignedInSelector = createSelector(
  authorizationsStateSelector,
  (state) => state.isSignedIn,
);
export const isForgotEmailSentSelector = createSelector(
  authorizationsStateSelector,
  (state) => state.isForgotEmailSent,
);
export const hasPostedJobSelector = createSelector(userSelector, (user) => user?.hasPostedJob);
export const userExistsErrorSelector = createSelector(
  authorizationsStateSelector,
  (state) => state.userExistsError,
);

export const loginRedirectSelector = createSelector(
  authorizationsStateSelector,
  (state) => state.loginRedirect,
);

export const jobIdRedirectSelector = createSelector(
  authorizationsStateSelector,
  (state) => state.jobIdRedirect,
);

export const userRoleSelector = createSelector(userSelector, (state) => state?.role);

export const authLoadingSelector = createSelector(
  authorizationsStateSelector,
  (state) => state.loading,
);
