import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, IAuthData, IAuthState, ILoginRedirect } from '../types';

const initialState: IAuthState = {
  isSignedIn: null,
  user: null,
  isForgotEmailSent: false,
  userExistsError: null,
  loginRedirect: null,
  jobIdRedirect: null,
  loading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeAuthorization: (state, action: IActionWithPayload<IAuthData>) => {
      state.isSignedIn = action.payload.isSignedIn;
      state.user = action.payload.user;
    },
    setProfileImage: (state, action: IActionWithPayload<string>) => {
      if (state.user) {
        state.user.imageUri = action.payload;
      }
    },
    setIsForgotEmailSent: (state, action: IActionWithPayload<{ isSent: boolean }>) => {
      state.isForgotEmailSent = action.payload.isSent;
    },
    setUserExistsError: (state, action: IActionWithPayload<string | null>) => {
      state.userExistsError = action.payload;
    },
    clearUserExistsError: (state) => {
      state.userExistsError = null;
    },
    setLoginRedirect: (state, { payload }: IActionWithPayload<ILoginRedirect | null>) => {
      if (!state.loginRedirect || !payload) {
        state.loginRedirect = payload;
        return;
      }

      state.loginRedirect.path = payload.path;
      state.loginRedirect.pathName = payload.pathName;
      state.loginRedirect.route = payload.route;
    },
    setjobIdRedirect: (state, { payload }: IActionWithPayload<string | null>) => {
      state.jobIdRedirect = payload;
    },
    setLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.loading = payload;
    },
  },
});

export const {
  storeAuthorization,
  setIsForgotEmailSent,
  setProfileImage,
  setUserExistsError,
  clearUserExistsError,
  setLoginRedirect,
  setjobIdRedirect,
  setLoading,
} = authSlice.actions;

export default authSlice.reducer;
