import { IState } from '@/client/redux/types';
import { createSelector } from 'reselect';

export const matchesSelector = ({ matches }: IState) => matches;

export const matchesForCandidateSelector = createSelector(
  matchesSelector,
  ({ candidateMatches, isLoading }) => ({ candidateMatches, isLoading }),
);

export const candidateMatchByIdSelector = (jobd: string | null) =>
  createSelector(
    matchesForCandidateSelector,
    ({ candidateMatches }) => candidateMatches.find(({ id }) => id === jobd) ?? null,
  );

export const matchScoreParametersSelector = createSelector(
  matchesSelector,
  (state) => state.matchScoreParams,
);

export const matchChangesSelector = createSelector(
  matchesSelector,
  ({ matchChanges, isLoading }) => ({ matchChanges, isLoading }),
);

export const candidateMatchesShortInfoSelector = createSelector(
  matchesSelector,
  ({ candidateMatchesShortInfo, isLoading, hasMore, total }) => ({
    candidateMatchesShortInfo,
    isLoading,
    hasMore,
    total,
  }),
);

export const candidateMatchesShortInfoListQuerySelector = createSelector(
  matchesSelector,
  ({ page, perPage }) => ({ page, perPage }),
);
