import { availableRoutes } from '@/client/routes';
import { IUserDto } from '@/DTO';
import { UserRole } from '@/enums';

const checkEmployerOnboarding = (user: IUserDto, path: string) => {
  if (path === '/onboarding/employer') {
    // Allow access to '/onboarding/employer' only when onboargin is in progress
    return !user.isOnboardingFinished;
  }

  // Only employer has no access to other pages before he finished an onboarding
  return user.isOnboardingFinished;
};

const checkCandidateOnboarding = (user: IUserDto, path: string) => {
  if (path === '/onboarding/candidate') {
    // Allow access to '/onboarding/candidate' only when onboarding is in progress
    return !user.isOnboardingFinished;
  }

  if (!user.isOnboardingFinished) {
    return availableRoutes.untilCandidateOnboardingFinished.includes(path);
  }

  return user.isVerified || availableRoutes.untilCandidateIsVerified.includes(path);
};

const defaultOnboardingCheck = () => {
  // Other roles don't have onboarding restrictions
  return true;
};

const onboardingRoleChecks = {
  [UserRole.MasterEmployer]: checkEmployerOnboarding,
  [UserRole.Employer]: checkEmployerOnboarding,
  [UserRole.AdminEmployer]: checkEmployerOnboarding,
  [UserRole.Candidate]: checkCandidateOnboarding,
} as Record<Partial<UserRole>, (user: IUserDto, path: string) => boolean>;

const checkUserOnboardingFinished = (user: IUserDto, path: string): boolean => {
  const handler = onboardingRoleChecks[user.role] ?? defaultOnboardingCheck;
  return handler(user, path);
};

export default checkUserOnboardingFinished;
