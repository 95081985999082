enum CandidateOnboardingStatus {
  BasicInfo,
  Headline,
  Education,
  Compensation,
  FinalDetails,
  Done,
}

export default CandidateOnboardingStatus;
