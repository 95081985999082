import { env } from '@/utils';
import axios, { AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { ICityBase, ILocationResponse, IRegion } from '@/client/types';
import { locationUrls } from './urls';

const defaultParams = {
  limit: 100,
  offset: 0,
};

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: env.locationsCacheAge,
  exclude: { query: false },
});

// Create `axios` instance passing the newly created `cache.adapter`
const axiosInstance = axios.create({
  baseURL: locationUrls.baseURL,
  adapter: cache.adapter,
  headers: {
    'x-rapidapi-host': env.locationServiceHost,
    'x-rapidapi-key': env.locationServiceKey,
  },
});

const loadRegions = async (countryCode: string, input?: string, baseURL?: string) => {
  const regions = await axiosInstance
    .get(locationUrls.regionsUrl(countryCode), {
      params: { ...defaultParams, namePrefix: input },
      baseURL,
    })
    .then((res: AxiosResponse<ILocationResponse<IRegion>>) => res.data.data);

  return regions;
};

const loadCities = async (
  countryCode: string,
  regionCode?: string,
  input?: string,
  baseURL?: string,
) => {
  const cities = await axiosInstance
    .get(
      regionCode
        ? locationUrls.regionCitiesUrl(countryCode, regionCode)
        : locationUrls.countryCitiesUrl,
      {
        params: {
          ...defaultParams,
          types: 'CITY',
          namePrefix: input,
          ...(regionCode ? undefined : { countryIds: countryCode }),
        },
        baseURL,
      },
    )
    .then((res: AxiosResponse<ILocationResponse<ICityBase>>) =>
      res.data.data.reduce<ICityBase[]>((acc, item) => {
        if (acc.find((city) => city.name === item.name)) {
          return acc;
        }
        return [...acc, item];
      }, []),
    );

  return cities;
};

export default { loadRegions, loadCities };
