import { IBrowseCandidateResponse } from '@/responses';
import { createSlice } from '@reduxjs/toolkit';
import { ICandidatesFilters } from '@/requests';
import { ICandidateDetailsDto } from '@/DTO';
import { IActionWithPayload, IBrowseCandidateState } from '../types';

const initialState: IBrowseCandidateState = {
  candidates: [],
  isLoading: true,
  hasMore: true,
  filters: {},
  page: 1,
  perPage: 10,
  detailedCandidate: null,
  isLoadingCandidateDetail: false,
};

export const browseCandidateSlice = createSlice({
  name: 'browse-candidate',
  initialState,
  reducers: {
    addCandidates: (state, action: IActionWithPayload<IBrowseCandidateResponse>) => {
      state.hasMore = action.payload.hasMore;
      state.isLoading = false;
      if (action.payload.data.length) {
        // Handle pagination offsets to prevent duplication of candidate cards
        const existingCandidateIdSet = new Set(state.candidates.map((candidate) => candidate.id));
        state.candidates.push(
          ...action.payload.data.filter((candidate) => !existingCandidateIdSet.has(candidate.id)),
        );
        state.page += 1;
      }
    },
    storeFilter: (state, { payload }: IActionWithPayload<ICandidatesFilters>) => {
      state.filters = payload;
      state.candidates = [];
      state.hasMore = true;
      state.page = 1;
      state.perPage = 10;
    },
    loadCandidatesFailed: (state) => {
      state.hasMore = false;
      state.isLoading = false;
    },
    storeDetailedCandidate: (state, { payload }: IActionWithPayload<ICandidateDetailsDto>) => {
      state.detailedCandidate = payload;
      state.isLoadingCandidateDetail = false;
    },
    clearDetailedCandidate: (state) => {
      state.detailedCandidate = null;
    },
    startLoadingDetailedCandidate: (state) => {
      state.isLoadingCandidateDetail = true;
    },
    stopLoadingDetailedCandidate: (state) => {
      state.isLoadingCandidateDetail = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    clearCandidates: (state) => {
      state.candidates = [];
      state.hasMore = true;
      state.page = 1;
      state.perPage = 10;
      state.filters = {};
    },
  },
});

export const {
  addCandidates,
  loadCandidatesFailed,
  startLoadingDetailedCandidate,
  stopLoadingDetailedCandidate,
  startLoading,
  clearCandidates,
  storeFilter,
  storeDetailedCandidate,
  clearDetailedCandidate,
} = browseCandidateSlice.actions;

export default browseCandidateSlice.reducer;
