import { useCallback } from 'react';
import clsx from 'clsx';
import { Grid, Popover, Typography, Button } from '@material-ui/core';
import { LinkButton, ProfileImage } from '@/client/components';
import { ButtonType, IActionButton, IControlButton, IPageButton } from '@/client/types';
import useStyles from './styles';
import useProfilePopover from './useProfilePopover';
import { IProfilePopoverProps } from './types';

export const ProfilePopover = ({
  buttons = [],
  className,
  ...props
}: IProfilePopoverProps): JSX.Element => {
  const classes = useStyles();
  const { user, open, anchorEl, closePopoverHandler, openPopoverHandler, logoutHandler } =
    useProfilePopover();

  const renderControlButton = useCallback(
    (button: IControlButton) => {
      return (
        <LinkButton href={button.redirect} className={classes.button}>
          {button.label}
        </LinkButton>
      );
    },
    [classes],
  );

  const renderActionButton = useCallback(
    (button: IActionButton) => {
      return (
        <Button onClick={button.onClick} className={classes.button}>
          {button.label}
        </Button>
      );
    },
    [classes],
  );

  const renderButtons = useCallback(
    (button: IPageButton) => {
      switch (button.type) {
        case ButtonType.ControlButton:
          return renderControlButton(button);
        default:
          return renderActionButton(button);
      }
    },
    [renderControlButton, renderActionButton],
  );

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        onClick={openPopoverHandler}
        className={clsx(classes.root, className)}
        {...props}
      >
        <Grid item>
          <ProfileImage />
        </Grid>
        <Grid item>
          <Typography component="span" className={classes.userName}>
            {user ? `${user.firstName} ${user.lastName}` : ''}
          </Typography>
        </Grid>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopoverHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction="column" className={classes.content}>
          {buttons.map((button) => (
            <Grid item key={button.label}>
              {renderButtons(button)}
            </Grid>
          ))}
          <Grid item>
            <Button onClick={logoutHandler} className={classes.button}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default ProfilePopover;
