import { JobDBStatus, JobStatus } from '@/enums';

export const publishedJobDBStatus = [
  JobDBStatus.Published,
  JobDBStatus.Paused,
  JobDBStatus.Demo,
  JobDBStatus.Hired,
];

export const publishedStatusJobForCandidate = [
  JobDBStatus.Published,
  JobDBStatus.Paused,
  JobDBStatus.Demo,
];

export const publishedStatusJobForEmployerDbStatus = [
  JobDBStatus.Published,
  JobDBStatus.Paused,
  JobDBStatus.Hired,
  JobDBStatus.Demo,
];

export const publishedStatusJobForEmployer = [
  JobStatus.Published,
  JobStatus.Paused,
  JobStatus.Hired,
  JobStatus.Demo,
];

export const publishedStatusJobMatches = [...publishedJobDBStatus, JobDBStatus.Archived];
