import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { candidatesService } from '@/client/services';
import { handleRequestError } from '@/client/utils';
import { loadDetailedCandidate, loadNextCandidates } from '@/client/redux/actions';
import { browseCandidateListQuerySelector } from '@/client/redux/selectors';
import { IBrowseCandidateQuery } from '@/requests';
import {
  addCandidates,
  loadCandidatesFailed,
  startLoading,
  startLoadingDetailedCandidate,
  stopLoadingDetailedCandidate,
  storeDetailedCandidate,
} from '@/client/redux/store/browse-candidate.slice';
import { IBrowseCandidateResponse } from '@/responses';
import { ICandidateDetailsDto } from '@/DTO';
import { IActionWithPayload } from '../types';

function* loadNextCandidatesSaga() {
  try {
    yield put(startLoading());
    const payload: IBrowseCandidateQuery = yield select(browseCandidateListQuerySelector);
    const result: IBrowseCandidateResponse = yield call(
      candidatesService.browseCandidates,
      payload,
    );

    yield put(addCandidates(result));
  } catch (error) {
    yield put(loadCandidatesFailed());
    handleRequestError(error);
  }
}

function* loadDetailedCandidateSaga(action: IActionWithPayload<string>) {
  try {
    yield put(startLoadingDetailedCandidate());
    const candidate: ICandidateDetailsDto = yield call(
      candidatesService.getCandidate,
      action.payload,
    );

    yield put(storeDetailedCandidate(candidate));
  } catch (error) {
    yield put(stopLoadingDetailedCandidate());
    handleRequestError(error);
  }
}

export default function* browseCandidateRootSaga() {
  yield all([
    takeLatest(loadNextCandidates.type, loadNextCandidatesSaga),
    takeLatest(loadDetailedCandidate.type, loadDetailedCandidateSaga),
  ]);
}
