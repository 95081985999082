import { createAction } from '@reduxjs/toolkit';
import { ISaveCustomerOnboardingData, ILoadCitiesData, ILoadRegionsData } from '../types';

const SAVE_CUSTOMER_ONBOARDING = 'ONBOARDING/SAVE_CUSTOMER_ONBOARDING';
const SET_ACTIVE_STEP = 'ONBOARDING/SET_ACTIVE_STEP';
const HANDLE_BACK = 'ONBOARDING/HANDLE_BACK';
const HANDLE_NEXT = 'ONBOARDING/HANDLE_NEXT';

const LOAD_REGIONS = 'ONBOARDING/LOAD_REGIONS';
const LOAD_CITIES = 'ONBOARDING/LOAD_CITIES';

const LOAD_ONBOARDING_DATA = 'ONBOARDING/LOAD_ONBOARDING_DATA';

const ONBOARDING_EXIT = 'ONBOARDING/ONBOARDING_EXIT';

export const saveCustomerOnboardingData = createAction<
  ISaveCustomerOnboardingData,
  typeof SAVE_CUSTOMER_ONBOARDING
>(SAVE_CUSTOMER_ONBOARDING);

export const setActiveStep = createAction<number, typeof SET_ACTIVE_STEP>(SET_ACTIVE_STEP);

export const handleBack = createAction(HANDLE_BACK);

export const handleNext = createAction(HANDLE_NEXT);

export const loadOnboardingRegions = createAction<ILoadRegionsData, typeof LOAD_REGIONS>(
  LOAD_REGIONS,
);
export const loadOnboardingCities = createAction<ILoadCitiesData, typeof LOAD_CITIES>(LOAD_CITIES);

export const loadOnboardingData = createAction(LOAD_ONBOARDING_DATA);

export const onboardingExit = createAction(ONBOARDING_EXIT);
