import {
  IAdminCandidateMatchesDto,
  IAdminDto,
  IInterviewDateDto,
  IInterviewDto,
  IJobMatchDto,
  IMatchNoteDto,
  IRejectedMatchDto,
  IInterviewLinkDto,
  IScheduleLinkDto,
  IAdminMatchMessageDto,
  IJobAttributesDto,
} from '@/DTO';
import {
  IAdminJobsResponse,
  IAdminEmployersResponse,
  IAdminImportsResponse,
  IAdminEmailTemplatesResponse,
  IAdminCandidatesResponse,
  IAdminCompaniesResponse,
} from '@/responses';
import { IAdminUpdateMatchStatusRequest } from '@/requests';
import { createSlice } from '@reduxjs/toolkit';
import {
  IActionWithPayload,
  IAdminAssignToJob,
  IBuildAdminCandidateFiltersQuery,
  IAdminEmailTemplateUpdate,
  IAdminState,
  IAdminUpdateCompanyTermsUriPayload,
  IAdminUpdateHubspotId,
  IUpdateEmployerFeedbackProps,
  IDeleteJobMatchNoteProps,
  IUpdateInterviewStatusPayload,
  IAdminUpdateMatchStagePayload,
  IAdminAssignToMatchPayload,
  IAdminUpdateMatchNotesPayload,
  IUpdateIsVettedStatus,
  IDeleteJobMatchNoteAttachmentProps,
} from '../types';

const initialState: IAdminState = {
  admins: [],
  employers: [],
  companies: [],
  candidates: [],
  jobs: [],
  emailTemplates: [],
  imports: [],
  adminCandidateMatches: {
    activeMatches: { sentToCandidate: [], sentToEmployer: [], reserved: [] },
    algorithmMatches: [],
  },
  adminJobMatches: [],
  total: 0,
  isLoading: false,
  hasMore: true,
  filters: {},
  page: 1,
  perPage: 10,
  deletingNoteId: null,
  modalMatch: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminId(state, action: IActionWithPayload<IAdminAssignToJob>) {
      const jobs = state.jobs.find(({ id }) => id === action.payload.jobId);
      if (jobs) {
        jobs.adminId = action.payload.adminId;
      }
    },
    setAdmins(state, action: IActionWithPayload<IAdminDto[]>) {
      state.admins = action.payload;
    },
    setAdminCandidateMatches(state, action: IActionWithPayload<IAdminCandidateMatchesDto | null>) {
      state.adminCandidateMatches = action.payload ?? {
        activeMatches: { sentToCandidate: [], sentToEmployer: [], reserved: [] },
        algorithmMatches: [],
      };
      state.isLoading = false;
    },
    setAdminJobMatches(state, action: IActionWithPayload<IJobMatchDto[]>) {
      state.adminJobMatches = action.payload;
      state.isLoading = false;
    },
    setJobs: (state, action: IActionWithPayload<IAdminJobsResponse>) => {
      state.jobs = action.payload.data;
      state.total = action.payload.total;
    },
    updateJobStatus: (state, { payload }: IActionWithPayload<IJobAttributesDto>) => {
      state.jobs = state.jobs.map((job) => {
        if (job.id === payload.id) {
          job.status = payload.status;
          job.isVetted = payload.isVetted;
        }

        return job;
      });
    },
    updateJobIsVetted: (
      state,
      { payload }: IActionWithPayload<Omit<IUpdateIsVettedStatus, 'onSuccess'>>,
    ) => {
      const selectedJob = state.jobs.find((job) => job.id === payload.id);
      if (selectedJob) {
        selectedJob.isVetted = payload.isVetted;
      }
    },
    setEmployers: (state, action: IActionWithPayload<IAdminEmployersResponse>) => {
      state.employers = action.payload.data;
      state.total = action.payload.total;
    },
    setCompanies: (state, action: IActionWithPayload<IAdminCompaniesResponse>) => {
      state.companies = action.payload.data;
      state.total = action.payload.total;
    },
    setEmailTemplates: (state, action: IActionWithPayload<IAdminEmailTemplatesResponse>) => {
      state.emailTemplates = action.payload.data;
      state.total = action.payload.total;
    },
    setTemplateId: (state, action: IActionWithPayload<IAdminEmailTemplateUpdate>) => {
      const templateId = state.emailTemplates.find(({ type }) => type === action.payload.type);
      if (templateId) {
        templateId.templateId = action.payload.templateId;
      }
    },
    setImports: (state, action: IActionWithPayload<IAdminImportsResponse>) => {
      state.imports = action.payload.data;
      state.total = action.payload.total;
    },
    setEmployerTermsUri: (
      state,
      action: IActionWithPayload<IAdminUpdateCompanyTermsUriPayload>,
    ) => {
      const company = state.companies.find(({ id }) => id === action.payload.companyId);

      if (company) {
        company.termsUri = action.payload.termsUri;
      }
    },
    setEmployerHubspotId: (state, action: IActionWithPayload<IAdminUpdateHubspotId>) => {
      const employer = state.employers.find(({ id }) => id === action.payload.entityId);

      if (employer) {
        employer.hubspotId = action.payload.hubspotId;
      }
    },
    setCandidateHubspotId: (state, { payload }: IActionWithPayload<IAdminUpdateHubspotId>) => {
      const candidate = state.candidates.find(({ id }) => id === payload.entityId);

      if (candidate) {
        candidate.hubspotId = payload.hubspotId;
      }
    },
    setLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.isLoading = payload;
    },
    addBrowseCandidates: (state, action: IActionWithPayload<IAdminCandidatesResponse>) => {
      state.hasMore = action.payload.hasMore;
      state.total = action.payload.total;
      state.isLoading = false;
      if (action.payload.data.length) {
        const existingCandidateIdSet = new Set(state.candidates.map((candidate) => candidate.id));
        state.candidates.push(
          ...action.payload.data.filter((candidate) => !existingCandidateIdSet.has(candidate.id)),
        );
        state.page += 1;
      }
    },
    storeBrowseCandidateFilter: (
      state,
      { payload }: IActionWithPayload<IBuildAdminCandidateFiltersQuery>,
    ) => {
      state.filters = payload;
      state.candidates = [];
      state.hasMore = true;
      state.page = 1;
      state.perPage = 10;
    },
    loadAdminBrowseCandidatesFailed: (state) => {
      state.hasMore = false;
      state.isLoading = false;
    },
    updateCandidateMatchStatus: (
      state,
      { payload }: IActionWithPayload<IAdminUpdateMatchStatusRequest>,
    ) => {
      state.adminCandidateMatches.activeMatches.sentToCandidate =
        state.adminCandidateMatches.activeMatches.sentToCandidate.map((job) => {
          if (job.id === payload.jobId) {
            job.modifyStatus = payload.modifyStatus;
          }

          return job;
        });

      state.adminCandidateMatches.activeMatches.sentToEmployer =
        state.adminCandidateMatches.activeMatches.sentToEmployer.map((job) => {
          if (job.id === payload.jobId) {
            job.modifyStatus = payload.modifyStatus;
          }

          return job;
        });
    },
    updateJobMatchStage: (
      state,
      { payload }: IActionWithPayload<IAdminUpdateMatchStagePayload>,
    ) => {
      const jobMatch = state.adminJobMatches.find((item) => item.id === payload.matchId);

      if (jobMatch) {
        jobMatch.stage = payload.stage;
      }
    },
    setDeleting: (state, { payload }: IActionWithPayload<string | null>) => {
      state.deletingNoteId = payload;
    },
    setModalMatch: (state, { payload }: IActionWithPayload<{ matchId: string }>) => {
      const modalMatch = state.adminJobMatches.find((item) => {
        return item.id === payload.matchId;
      });
      if (modalMatch) {
        state.modalMatch = modalMatch;
      }
    },
    storeCreatedNote(state, action: IActionWithPayload<IMatchNoteDto>) {
      const globalMatch = state.adminJobMatches.find(
        (match) => match.id === action.payload.matchId,
      );
      if (globalMatch) {
        globalMatch.matchNotes.push(action.payload);
      }
      if (state.modalMatch) {
        state.modalMatch.matchNotes.push(action.payload);
      }
    },
    storeUpdatedNote(state, { payload }: IActionWithPayload<IMatchNoteDto>) {
      const globalMatch = state.adminJobMatches.find((match) => match.id === payload.matchId);
      if (globalMatch) {
        globalMatch.matchNotes = globalMatch.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }
      if (state.modalMatch) {
        state.modalMatch.matchNotes = state.modalMatch.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }
    },
    deleteNote(state, action: IActionWithPayload<IDeleteJobMatchNoteProps>) {
      const globalMatch = state.adminJobMatches.find(
        (match) => match.id === action.payload.matchId,
      );
      if (globalMatch) {
        const changedNotes = globalMatch.matchNotes.filter(
          (item) => item.id !== action.payload.matchNoteId,
        );
        globalMatch.matchNotes = changedNotes;
      }
      if (state.modalMatch) {
        const filteredNotes = state.modalMatch.matchNotes.filter(
          (item) => item.id !== action.payload.matchNoteId,
        );
        state.modalMatch.matchNotes = filteredNotes;
      }
    },
    storeJobMatchFeedback(state, action: IActionWithPayload<IUpdateEmployerFeedbackProps>) {
      const newModalMatch = state.adminJobMatches.find(
        (modalMatch) => modalMatch.id === action.payload.matchId,
      );
      if (newModalMatch && newModalMatch.algorithmMatch) {
        newModalMatch.algorithmMatch.employerFeedback = action.payload.employerFeedback;
      }
      if (state.modalMatch && state.modalMatch.algorithmMatch) {
        state.modalMatch.algorithmMatch.employerFeedback = action.payload.employerFeedback;
      }
    },
    storeAdminMatchMessage(state, { payload }: IActionWithPayload<IAdminMatchMessageDto>) {
      const globalMatch = state.adminJobMatches.find((match) => match.id === payload.matchId);
      if (globalMatch) {
        globalMatch.adminMessageId = payload.id;
        globalMatch.adminMatchMessage = payload;
      }
      if (state.modalMatch) {
        state.modalMatch.adminMessageId = payload.id;
        state.modalMatch.adminMatchMessage = payload;
      }
    },
    clearMatchModal(state) {
      state.deletingNoteId = null;
      state.modalMatch = null;
    },
    setJobMatchesInterviewStatus: (
      state,
      { payload: { interviewId, status } }: IActionWithPayload<IUpdateInterviewStatusPayload>,
    ) => {
      const selectedCandidate = state.adminJobMatches.find((candidate) =>
        candidate.applicant?.interviews?.some((interview) => interview.id === interviewId),
      );

      const selectedsInterview = selectedCandidate?.applicant?.interviews?.find(
        (interview) => interview.id === interviewId,
      );

      if (selectedsInterview) {
        selectedsInterview.status = status;
      }

      const selectedModalMatchInterview = state.modalMatch?.applicant?.interviews?.find(
        (interview) => interview.id === interviewId,
      );
      if (selectedModalMatchInterview) {
        selectedModalMatchInterview.status = status;
      }
    },
    setJobMatchesInterviewDate: (
      state,
      { payload: { id, interviewDate } }: IActionWithPayload<IInterviewDateDto>,
    ) => {
      const selectedInterview = state.adminJobMatches.reduce<IInterviewDto | null>((acc, match) => {
        return match.applicant?.interviews?.find((interview) => interview.id === id) ?? acc;
      }, null);

      const selectedModalMatchInterview = state.modalMatch?.applicant?.interviews?.find(
        (interview) => interview.id === id,
      );

      if (selectedInterview) {
        selectedInterview.interviewDate = interviewDate;
      }

      if (selectedModalMatchInterview) {
        selectedModalMatchInterview.interviewDate = interviewDate;
      }
    },
    setAssignedAdmin: (state, { payload }: IActionWithPayload<IAdminAssignToMatchPayload>) => {
      const globalMatch = state.adminJobMatches.find((match) => match.id === payload.matchId);

      if (globalMatch) {
        globalMatch.assignedAdmin = payload.assignedAdmin;
      }
    },
    updateMatchNote(state, { payload }: IActionWithPayload<IMatchNoteDto>) {
      const globalMatch = state.adminJobMatches.find((match) => match.id === payload.matchId);
      if (globalMatch) {
        globalMatch.matchNotes = globalMatch.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }
      if (state.modalMatch) {
        state.modalMatch.matchNotes = state.modalMatch.matchNotes.map((note) =>
          note.id === payload.id ? payload : note,
        );
      }
    },
    updateMatchNotes(state, { payload }: IActionWithPayload<IAdminUpdateMatchNotesPayload>) {
      const globalMatch = state.adminJobMatches.find((match) => match.id === payload.matchId);
      if (globalMatch) {
        globalMatch.matchNotes = payload.matchNotes;
      }
      if (state.modalMatch) {
        state.modalMatch.matchNotes = payload.matchNotes;
      }
    },
    setRejectedMatch(state, { payload }: IActionWithPayload<IRejectedMatchDto>) {
      const jobMatch = state.adminJobMatches.find(({ id }) => id === payload.matchId);

      if (jobMatch) {
        jobMatch.rejectedMatch = payload;
      }

      if (state.modalMatch) {
        state.modalMatch.rejectedMatch = payload;
      }
    },
    clearData: (state) => {
      state.admins = [];
      state.employers = [];
      state.candidates = [];
      state.jobs = [];
      state.candidates = [];
      state.emailTemplates = [];
      state.total = 0;
      state.page = 1;
      state.perPage = 10;
      state.hasMore = true;
      state.isLoading = false;
      state.deletingNoteId = null;
      state.modalMatch = null;
      state.adminCandidateMatches = {
        activeMatches: { sentToCandidate: [], sentToEmployer: [], reserved: [] },
        algorithmMatches: [],
      };
      state.adminJobMatches = [];
    },

    setJobMatchesInterviewLink: (
      state,
      { payload: { id, interviewLink } }: IActionWithPayload<IInterviewLinkDto>,
    ) => {
      const selectedSchedule = state.adminJobMatches.reduce<IInterviewDto | null>((acc, match) => {
        return match.applicant?.interviews?.find((interview) => interview.id === id) ?? acc;
      }, null);

      const selectedModalMatchSchedule = state.modalMatch?.applicant?.interviews?.find(
        (interview) => interview.id === id,
      );

      if (selectedSchedule) {
        selectedSchedule.interviewLink = interviewLink;
      }

      if (selectedModalMatchSchedule) {
        selectedModalMatchSchedule.interviewLink = interviewLink;
      }
    },

    setJobMatchesScheduleLink: (
      state,
      { payload: { id, scheduleLink } }: IActionWithPayload<IScheduleLinkDto>,
    ) => {
      const selectedSchedule = state.adminJobMatches.reduce<IInterviewDto | null>((acc, match) => {
        return match.applicant?.interviews?.find((interview) => interview.id === id) ?? acc;
      }, null);

      const selectedModalMatchSchedule = state.modalMatch?.applicant?.interviews?.find(
        (interview) => interview.id === id,
      );

      if (selectedSchedule) {
        selectedSchedule.scheduleLink = scheduleLink;
      }

      if (selectedModalMatchSchedule) {
        selectedModalMatchSchedule.scheduleLink = scheduleLink;
      }
    },
    updateJobMatch: (state, { payload }: IActionWithPayload<IJobMatchDto>) => {
      if (state.adminJobMatches.find((item) => item.id === payload.id)) {
        state.adminJobMatches = state.adminJobMatches.reduce<IJobMatchDto[]>((acc, match) => {
          return match.id === payload.id ? [...acc, payload] : [...acc, match];
        }, []);
      }
    },
    removeJobMatchNoteAttachment(
      state,
      { payload }: IActionWithPayload<IDeleteJobMatchNoteAttachmentProps>,
    ) {
      const matchNote = state.adminJobMatches.reduce<IMatchNoteDto | null>((acc, match) => {
        return match.matchNotes.find(({ id }) => id === payload.matchNoteId) ?? acc;
      }, null);
      if (matchNote?.attachedFiles) {
        matchNote.attachedFiles = matchNote.attachedFiles.filter(
          (item) => item.id !== payload.attachmentId,
        );
      }
      if (state.modalMatch) {
        state.modalMatch.matchNotes = state.modalMatch.matchNotes.reduce<IMatchNoteDto[]>(
          (acc, note) => {
            note.attachedFiles =
              note.id === payload.matchNoteId && note.attachedFiles
                ? note.attachedFiles.filter((item) => item.id !== payload.attachmentId)
                : note.attachedFiles;
            return [...acc, note];
          },
          [],
        );
      }
    },
  },
});

export const {
  setAdminId,
  setAdmins,
  setJobs,
  setCompanies,
  setEmployers,
  setEmployerTermsUri,
  setEmailTemplates,
  setImports,
  setLoading,
  clearData,
  setAdminCandidateMatches,
  setAdminJobMatches,
  setTemplateId,
  setEmployerHubspotId,
  setCandidateHubspotId,
  addBrowseCandidates,
  storeBrowseCandidateFilter,
  loadAdminBrowseCandidatesFailed,
  updateJobStatus,
  updateCandidateMatchStatus,
  updateJobMatchStage,
  setDeleting,
  setModalMatch,
  storeCreatedNote,
  storeUpdatedNote,
  deleteNote,
  storeJobMatchFeedback,
  clearMatchModal,
  setJobMatchesInterviewStatus,
  setJobMatchesInterviewDate,
  storeAdminMatchMessage,
  setAssignedAdmin,
  updateMatchNote,
  updateMatchNotes,
  setRejectedMatch,
  updateJobIsVetted,
  setJobMatchesInterviewLink,
  setJobMatchesScheduleLink,
  updateJobMatch,
  removeJobMatchNoteAttachment,
} = adminSlice.actions;

export default adminSlice.reducer;
