import { IAuthChannelData } from '@/client/redux/types';
import { env } from '@/utils';

const setIdentity = (id: string) => {
  window?.heap?.identify(id);
};
const clearIdentity = () => {
  window?.heap?.resetIdentity();
};
const handleAuthChange = (authData: IAuthChannelData) => {
  if (env.heapTrackingId) {
    if (authData.isSignedIn && authData.user) {
      setIdentity(authData.user.id);
    } else {
      clearIdentity();
    }
  }
};

export default {
  handleAuthChange,
};
