import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getRequestErrorMessage, handleRequestError } from '@/client/utils';
import {
  setEmployers,
  setJobs,
  setLoading,
  setImports,
  setAdminCandidateMatches,
  setAdmins,
  setAdminId,
  setEmployerTermsUri,
  setEmailTemplates,
  setTemplateId,
  setEmployerHubspotId,
  setCandidateHubspotId,
  addBrowseCandidates,
  loadAdminBrowseCandidatesFailed,
  setCompanies,
  storeCreatedNote,
  deleteNote,
  storeJobMatchFeedback,
  setDeleting,
  setAdminJobMatches,
  updateJobMatchStage,
  storeAdminMatchMessage,
  setAssignedAdmin,
  storeUpdatedNote,
  setJobMatchesInterviewDate,
  updateMatchNote,
  updateMatchNotes,
  setRejectedMatch,
  updateJobIsVetted,
  setJobMatchesInterviewLink,
  setJobMatchesScheduleLink,
  updateJobMatch,
  removeJobMatchNoteAttachment,
  updateJobStatus,
} from '@/client/redux/store/admin.slice';
import {
  setEmployers as interviewSetEmployers,
  setJobs as interviewSetJobs,
  setLoading as interviewSetLoading,
} from '@/client/redux/store/request-interview.slice';
import { adminBrowseCandidateListQuerySelector } from '@/client/redux/selectors';
import { adminMatchesJobService, adminService } from '@/client/services';
import {
  IAdminEmployersResponse,
  IAdminEmailTemplatesResponse,
  IAdminImportsResponse,
  IAdminJobsResponse,
  IAdminResponse,
  ISuccessResponse,
  IAdminCandidatesResponse,
  IAdminCompaniesResponse,
  IAllJobMatchesDataResponse,
} from '@/responses';
import {
  IAdminCompanyQuery,
  IAdminEmployerQuery,
  IAdminEmailTemplatesQuery,
  IAdminImportQuery,
  IAdminJobQuery,
  IMatchDataQuery,
} from '@/requests';
import toast from 'react-hot-toast';
import { messages } from '@/client/constants';
import axios from 'axios';
import {
  IAdminCandidateMatchesDto,
  IAdminRequestInterviewDto,
  IEmployerFeedbackDTO,
  IEmployerShortInfoDto,
  IInterviewDateDto,
  IMatchNoteDto,
  IRejectedMatchDto,
  IInterviewLinkDto,
  IScheduleLinkDto,
  IAdminMatchMessageDto,
  IJobMatchDto,
  IJobAttributesDto,
  ICompanyTermsDTO,
} from '@/DTO';
import { MatchStage } from '@/enums';
import {
  loadEmployers,
  loadJobs,
  setCandidateIsVettedStatus,
  setCandidateOpenToOffersStatus,
  setJobIsVettedStatus,
  setMatchStatus,
  setUserStatus,
  importData,
  sendConfirmationEmail,
  deleteEntity,
  loadImports,
  setJobPriority,
  setJobStatus,
  loadAdminCandidateMatches,
  loadAdmins,
  assignAdminToJob,
  updateCompanyTermsUri,
  updateCompanyTermsSignedStatus,
  loadEmailTemplates,
  updateTemplate,
  updateEmployerHubspotId,
  updateCandidateHubspotId,
  loadNextAdminBrowseCandidates,
  loadCompanies,
  adminInviteEmployer,
  addNewMatch,
  updateCandidateReservedStatus,
  adminUpdateMatchStage,
  loadAdminInterviewData,
  adminCreateInterviewer,
  rejectMatch,
  loadAdminJobMatches,
  adminCreateJobMatchNote,
  adminDeleteJobMatchNote,
  editJobMatchFeedback,
  updateInterviewStatus,
  updateAdminMatchMessage,
  assignAdminToMatch,
  adminUpdateJobMatchNote,
  updateInterviewDate,
  clientVisibilityJobMatchNote,
  loadAdminMatchNotes,
  updateInterviewLink,
  updateScheduleLink,
  loadJobMatch,
  deleteJobMatchNoteAttachment,
  updateJobMatchNoteAttachments,
  createCandidate,
  updateRejectedMatch,
  createAdmin,
} from '../actions/admin.actions';
import {
  IActionWithPayload,
  IDeleteEntity,
  IImportData,
  IProcessEntity,
  IUpdateIsVettedStatus,
  IUpdateOpenToOffersStatus,
  IUpdatePriority,
  IUpdateUserStatus,
  IAdminUpdateMatchStatusPayload,
  IAdminAssignToJob,
  IAdminUpdateCompanyTermsUriPayload,
  IAdminUpdateCompanyTermsSignedPayload,
  IUpdateJobStatus,
  IAdminEmailTemplateUpdate,
  IAdminCandidateLoadMatchesProps,
  IAdminUpdateHubspotId,
  IAdminBrowseCandidatesRequest,
  IAdminInviteEmployer,
  IAdminAddNewMatch,
  IUpdateReservedStatus,
  IAdminUpdateMatchStagePayload,
  ILoadInterviewDataAdminPayload,
  IAdminCreateInterviewer,
  IAdminRejectMatchPayload,
  ICreateJobMatchNoteProps,
  IUpdateEmployerFeedbackProps,
  IAdminJobLoadMatchesProps,
  IDeleteJobMatchNoteProps,
  IUpdateInterviewStatusPayload,
  IUpdateAdminMatchMessageProps,
  IAdminAssignToMatchProps,
  IUpdateJobMatchNoteProps,
  IUpdateInterviewDatePayload,
  IUpdateMatchNoteClientVisibilityProps,
  IUpdateInterviewLinkPayload,
  IUpdateScheduleLinkPayload,
  ILoadJobMatchProps,
  IDeleteJobMatchNoteAttachmentProps,
  IUpdateNoteAttachmentsProps,
  ICreateCandidateProps,
  IAdminUpdateRejectedMatchPayload,
  ICreateAdminProps,
} from '../types';
import { storeEmployerLoading, storeEmployerProfileData } from '../store/employer.slice';
import { storeCompanyProfileData } from '../store/companies.slice';

function* loadAdminsSaga() {
  try {
    const result: IAdminResponse[] = yield call(adminService.getAdmins);

    yield put(setAdmins(result));
  } catch (error) {
    yield put(setAdmins([]));
    handleRequestError(error);
  }
}

function* loadJobsSaga(action: IActionWithPayload<IAdminJobQuery>) {
  try {
    yield put(setLoading(true));

    const result: IAdminJobsResponse = yield call(adminService.getJobs, action.payload);

    yield put(setJobs(result));
  } catch (error) {
    yield put(setJobs({ data: [], total: 0 }));
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadEmployersSaga(action: IActionWithPayload<IAdminEmployerQuery>) {
  try {
    yield put(setLoading(true));

    const result: IAdminEmployersResponse = yield call(adminService.getEmployers, action.payload);

    yield put(setEmployers(result));
  } catch (error) {
    yield put(setEmployers({ data: [], total: 0 }));
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadCompaniesSaga({ payload }: IActionWithPayload<IAdminCompanyQuery>) {
  try {
    yield put(setLoading(true));

    const companies: IAdminCompaniesResponse = yield call(adminService.getAdminCompanies, payload);

    yield put(setCompanies(companies));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadNextAdminBrowseCandidatesSaga() {
  try {
    yield put(setLoading(true));

    const payload: IAdminBrowseCandidatesRequest = yield select(
      adminBrowseCandidateListQuerySelector,
    );
    const result: IAdminCandidatesResponse = yield call(
      adminService.adminBrowseCandidates,
      payload,
    );

    yield put(addBrowseCandidates(result));
  } catch (error) {
    yield put(loadAdminBrowseCandidatesFailed());
    handleRequestError(error);
  }
}

function* setMatchStatusSaga(action: IActionWithPayload<IAdminUpdateMatchStatusPayload>) {
  try {
    yield call(adminService.getMatchStatus, action.payload);
    action.payload?.onSuccess?.();
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      window.open(
        `/admin/candidates/${action.payload.candidateId}?message=${getRequestErrorMessage(error)}`,
      );
    } else {
      handleRequestError(error);
    }
    action.payload?.onError?.();
  }
}

function* setJobIsVettedStatusSaga({ payload }: IActionWithPayload<IUpdateIsVettedStatus>) {
  try {
    yield call(adminService.setJobIsVettedStatus, payload);

    yield put(updateJobIsVetted(payload));
    payload.onSuccess?.();
    toast.success('Vetted status updated!');
  } catch (error) {
    handleRequestError(error);
  }
}

function* setJobPrioritySaga({ payload }: IActionWithPayload<IUpdatePriority>) {
  try {
    yield call(adminService.setJobPriority, payload);

    toast.success('Priority status updated!');
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* setJobStatusSaga({ payload }: IActionWithPayload<IUpdateJobStatus>) {
  try {
    const result: IJobAttributesDto = yield call(adminService.setJobStatus, payload);
    yield put(updateJobStatus(result));
    payload.onSuccess?.();
    toast.success(messages.jobStatusUpdated);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* setCandidateIsVettedStatusSaga({ payload }: IActionWithPayload<IUpdateIsVettedStatus>) {
  try {
    yield put(setLoading(true));

    yield call(adminService.setCandidateIsVettedStatus, payload);

    payload.onSuccess?.();
    toast.success('Vetted status updated!');
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      window.open(`/admin/candidates/${payload.id}?message=${getRequestErrorMessage(error)}`);
    } else {
      handleRequestError(error);
    }
    payload.onError?.();
  } finally {
    yield put(setLoading(false));
  }
}

function* setCandidateOpenToOffersStatusSaga({
  payload,
}: IActionWithPayload<IUpdateOpenToOffersStatus>) {
  try {
    yield call(adminService.setCandidateOpenToOffersStatus, payload);

    payload.onSuccess?.();
    toast.success(messages.openToOffersUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* setUserStatusSaga({ payload }: IActionWithPayload<IUpdateUserStatus>) {
  try {
    yield put(setLoading(true));

    yield call(adminService.setUserStatus, payload);

    payload.onSuccess?.();
    toast.success('Status updated!');
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* importDataSaga(action: IActionWithPayload<IImportData>) {
  try {
    yield put(setLoading(true));

    yield call(adminService.importData, action.payload);

    toast.success(messages.dataImportStarted);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* sendConfirmationEmailSaga(action: IActionWithPayload<IProcessEntity>) {
  try {
    const result: ISuccessResponse = yield call(adminService.sendConfirmationEmail, action.payload);

    toast.success(result.message);
  } catch (error) {
    handleRequestError(error);
  }
}

function* deleteEntitySaga({ payload }: IActionWithPayload<IDeleteEntity>) {
  try {
    const result: ISuccessResponse = yield call(adminService.deleteEntity, payload);

    payload.onSuccess?.();
    toast.success(result.message);
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadImportsSaga(action: IActionWithPayload<IAdminImportQuery>) {
  try {
    yield put(setLoading(true));

    const result: IAdminImportsResponse = yield call(adminService.getImports, action.payload);

    yield put(setImports(result));
  } catch (error) {
    yield put(setImports({ data: [], total: 0 }));
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadAdminCandidateMatchesSaga({
  payload,
}: IActionWithPayload<IAdminCandidateLoadMatchesProps>) {
  try {
    yield put(setLoading(true));

    const adminMatchesCandidate: IAdminCandidateMatchesDto = yield call(
      adminService.getAdminCandidateMatches,
      payload.candidateId,
    );

    yield put(setAdminCandidateMatches(adminMatchesCandidate));
    payload?.onSuccess?.();
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadAdminJobMatchesSaga({ payload }: IActionWithPayload<IAdminJobLoadMatchesProps>) {
  try {
    yield put(setLoading(true));
    const adminJobMatches: IAllJobMatchesDataResponse = yield call(
      adminMatchesJobService.getJobAllMatches,
      payload.jobId,
    );

    yield put(setAdminJobMatches(adminJobMatches));
    payload.onSuccess?.();
  } catch (error) {
    payload.onError?.();
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* assignAdminToJobSaga({ payload }: IActionWithPayload<IAdminAssignToJob>) {
  try {
    const result: ISuccessResponse = yield call(adminService.assignAdminToJob, payload);

    yield put(setAdminId(payload));
    toast.success(result.message);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateCompanyTermsUriSaga({
  payload,
}: IActionWithPayload<IAdminUpdateCompanyTermsUriPayload>) {
  try {
    const updatedCompanyTerms: ICompanyTermsDTO = yield call(
      adminService.updateCompanyTermsUri,
      payload,
    );

    yield put(
      setEmployerTermsUri({ companyId: payload.companyId, termsUri: updatedCompanyTerms.termsUri }),
    );
    payload.onSuccess?.();
    toast.success(messages.termsUriUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateCompanyTermsSignedSaga({
  payload,
}: IActionWithPayload<IAdminUpdateCompanyTermsSignedPayload>) {
  try {
    yield call(adminService.updateCompanyTermsSignedStatus, payload);

    toast.success(messages.termsSignedStatusUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadEmailTemplatesSaga({ payload }: IActionWithPayload<IAdminEmailTemplatesQuery>) {
  try {
    yield put(setLoading(true));
    const result: IAdminEmailTemplatesResponse = yield call(
      adminService.getEmailTemplates,
      payload,
    );

    yield put(setEmailTemplates(result));
  } catch (error) {
    yield put(setEmailTemplates({ data: [], total: 0 }));
    handleRequestError(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateEmailTemplateIdSaga({ payload }: IActionWithPayload<IAdminEmailTemplateUpdate>) {
  try {
    yield call(adminService.updateEmailTemplateId, payload);
    yield put(setTemplateId(payload));

    payload.onSuccess?.();
    toast.success(messages.emailTemplateUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateEmployerHubspotIdSaga({ payload }: IActionWithPayload<IAdminUpdateHubspotId>) {
  try {
    yield call(adminService.updateEmployerHubspotId, payload);

    yield put(setEmployerHubspotId(payload));
    payload.onSuccess?.();
    toast.success(messages.hubspotIdUpdated);
  } catch (error) {
    payload.onError?.();
    handleRequestError(error);
  }
}

function* updateCandidateHubspotIdSaga({ payload }: IActionWithPayload<IAdminUpdateHubspotId>) {
  try {
    yield call(adminService.updateCandidateHubspotId, payload);

    yield put(setCandidateHubspotId(payload));
    payload.onSuccess?.();
    toast.success(messages.hubspotIdUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* inviteEmployerSaga({ payload }: IActionWithPayload<IAdminInviteEmployer>) {
  try {
    const { onSuccess, ...request } = payload;
    yield call(adminService.inviteEmployer, request);

    onSuccess?.();
    toast.success(messages.invitationEmailSent);
  } catch (error) {
    handleRequestError(error);
  }
}

function* addNewMatchSaga({
  payload: { onSuccess, onError, ...request },
}: IActionWithPayload<IAdminAddNewMatch>) {
  try {
    yield call(adminService.addNewMatch, request);
    onSuccess?.();
    toast.success(messages.matchAdded);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      window.open(
        `/admin/candidates/${request.candidateId}?message=${encodeURIComponent(
          getRequestErrorMessage(error),
        )}`,
      );
    } else {
      handleRequestError(error);
    }
    onError?.();
  }
}

function* updateCandidateReservedStatusSaga({
  payload,
}: IActionWithPayload<IUpdateReservedStatus>) {
  try {
    yield call(adminService.updateCandidateReservedStatus, payload);

    payload.onSuccess?.();
    toast.success(messages.reservedUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateMatchStageSaga({
  payload: { onSuccess, onError, ...request },
}: IActionWithPayload<IAdminUpdateMatchStagePayload>) {
  try {
    yield put(updateJobMatchStage(request));
    yield call(adminService.updateMatchStage, request);
    onSuccess?.();
    toast.success(messages.matchStageUpdated);
  } catch (error) {
    if (!axios.isAxiosError(error) || error?.response?.status !== 404) {
      handleRequestError(error);
    }
    onError?.();
  }
}

/**
 * @function - set up interview data for admin in store
 * @param payload ILoadInterviewFromAdminPayload
 */
function* loadInterviewDataAdminSaga({
  payload,
}: IActionWithPayload<ILoadInterviewDataAdminPayload>) {
  try {
    yield put(interviewSetLoading(true));
    const res: IAdminRequestInterviewDto = yield call(adminService.getInterviewData, payload);

    yield put(interviewSetEmployers(res.employersShortInfo));
    yield put(interviewSetJobs(res.jobs));
    yield put(storeEmployerProfileData(res.employer));
    yield put(storeCompanyProfileData(res.company));
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(interviewSetLoading(false));
  }
}

function* createInterviewerSaga({ payload }: IActionWithPayload<IAdminCreateInterviewer>) {
  try {
    yield put(storeEmployerLoading(true));

    const { onSuccess, ...rest } = payload;
    const employer: IEmployerShortInfoDto = yield call(adminService.createInterviewer, rest);

    onSuccess(employer);
    toast.success(messages.interviewerAdded);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeEmployerLoading(false));
  }
}

function* rejectMatchSaga({
  payload: { matchId, onSuccess, onError, ...request },
}: IActionWithPayload<IAdminRejectMatchPayload>) {
  try {
    const rejectedMatch: IRejectedMatchDto = yield call(adminService.rejectMatch, request);

    yield put(
      updateJobMatchStage({
        matchId,
        stage: MatchStage.Rejected,
      }),
    );

    yield put(setRejectedMatch(rejectedMatch));

    onSuccess?.();
    toast.success(messages.matchStageUpdated);
  } catch (error) {
    handleRequestError(error);
    onError?.();
  }
}

function* createJobMatchNoteSaga({ payload }: IActionWithPayload<ICreateJobMatchNoteProps>) {
  try {
    const createdNote: IMatchNoteDto = yield call(adminService.createJobMatchNote, payload);

    yield put(storeCreatedNote(createdNote));
    payload.onSuccess?.();
    toast.success(messages.jobMatchNoteCreated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateJobMatchNoteSaga({
  payload: { onSuccess, onError, ...rest },
}: IActionWithPayload<IUpdateJobMatchNoteProps>) {
  try {
    const updatedNote: IMatchNoteDto = yield call(adminService.updateJobMatchNote, rest);

    yield put(storeUpdatedNote(updatedNote));
    onSuccess?.();
    toast.success(messages.jobMatchNoteUpdated);
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* deleteJobMatchNoteSaga({ payload }: IActionWithPayload<IDeleteJobMatchNoteProps>) {
  try {
    yield put(setDeleting(payload.matchNoteId));

    yield call(adminService.deleteJobMatchNote, payload.matchNoteId);
    yield put(deleteNote(payload));
    toast.success(messages.jobMatchNoteDeleted);
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(setDeleting(null));
  }
}

function* editJobMatchFeedbackSaga({
  payload: { matchId, employerFeedback, onSuccess },
}: IActionWithPayload<IUpdateEmployerFeedbackProps>) {
  try {
    const editedFeedback: IEmployerFeedbackDTO = yield call(
      adminService.editJobMatchFeedback,
      matchId,
      employerFeedback,
    );

    yield put(storeJobMatchFeedback({ employerFeedback: editedFeedback, matchId }));
    onSuccess?.();
    toast.success(messages.jobMatchClientFeedbackEdited);
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateInterviewStatusSaga({
  payload: { interviewId, status, onSuccess, onError },
}: IActionWithPayload<IUpdateInterviewStatusPayload>) {
  try {
    yield call(adminService.updateInterviewStatus, interviewId, status);
    toast.success(messages.interviewStatusUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* updateInterviewDateSaga({
  payload: { interviewId, date, onSuccess, onError },
}: IActionWithPayload<IUpdateInterviewDatePayload>) {
  try {
    const result: IInterviewDateDto = yield call(
      adminService.updateInterviewDate,
      interviewId,
      date,
    );

    yield put(setJobMatchesInterviewDate(result));
    toast.success(messages.interviewDateUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* updateAdminMatchMessageSaga({
  payload: { matchId, message, onSuccess, onError },
}: IActionWithPayload<IUpdateAdminMatchMessageProps>) {
  try {
    const updatedMessage: IAdminMatchMessageDto = yield call(
      adminService.updateAdminMatchMessage,
      matchId,
      message,
    );
    yield put(storeAdminMatchMessage(updatedMessage));
    onSuccess?.();
    toast.success(messages.jobMatchAdminMessageEdited);
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* assignAdminToMatchSaga({
  payload: { onSuccess, onError, ...rest },
}: IActionWithPayload<IAdminAssignToMatchProps>) {
  try {
    yield call(adminService.assignAdminToMatch, rest.matchId, rest.assignedAdmin?.id ?? null);

    yield put(setAssignedAdmin(rest));
    onSuccess?.();
    toast.success(messages.matchAdminAssigned);
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* clientVisibilityJobMatchNoteSaga({
  payload,
}: IActionWithPayload<IUpdateMatchNoteClientVisibilityProps>) {
  try {
    const updatedMatchNote: IMatchNoteDto = yield call(
      adminService.updateJobMatchNoteClientVisibility,
      payload,
    );
    yield put(updateMatchNote(updatedMatchNote));
    toast.success(messages.jobMatchNoteClientVisibilityUpdated);
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadAdminMatchNotesSaga({ payload: { matchId } }: IActionWithPayload<IMatchDataQuery>) {
  try {
    const matchNotes: IMatchNoteDto[] = yield call(adminService.loadMatchNotes, matchId);
    yield put(updateMatchNotes({ matchNotes, matchId }));
  } catch (error) {
    handleRequestError(error);
  }
}

function* updateInterviewLinkSaga({
  payload: { interviewId, interviewLink, onSuccess, onError },
}: IActionWithPayload<IUpdateInterviewLinkPayload>) {
  try {
    const result: IInterviewLinkDto = yield call(
      adminService.updateInterviewLink,
      interviewId,
      interviewLink,
    );

    yield put(setJobMatchesInterviewLink(result));
    toast.success(messages.interviewLinkUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* updateScheduleLinkSaga({
  payload: { interviewId, scheduleLink, onSuccess, onError },
}: IActionWithPayload<IUpdateScheduleLinkPayload>) {
  try {
    const result: IScheduleLinkDto = yield call(
      adminService.updateScheduleLink,
      interviewId,
      scheduleLink,
    );

    yield put(setJobMatchesScheduleLink(result));
    toast.success(messages.scheduleLinkUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* loadJobMatchSaga({
  payload: { onSuccess, onError, matchId },
}: IActionWithPayload<ILoadJobMatchProps>) {
  try {
    const jobMatch: IJobMatchDto = yield call(adminService.loadJobMatch, matchId);
    yield put(updateJobMatch(jobMatch));
    onSuccess?.();
  } catch (error) {
    if (!axios.isAxiosError(error) || error?.response?.status !== 404) {
      handleRequestError(error);
    }
    onError?.();
  }
}

function* deleteJobMatchNoteAttachmentSaga({
  payload: { attachmentId, matchNoteId, matchId, onSuccess, onError },
}: IActionWithPayload<IDeleteJobMatchNoteAttachmentProps>) {
  try {
    yield call(adminService.deleteJobMatchNoteAttachment, { attachmentId, matchNoteId });
    yield put(removeJobMatchNoteAttachment({ attachmentId, matchNoteId, matchId }));
    toast.success(messages.jobMatchNoteAttachmentDeleted);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* updateJobMatchNoteAttachmentsSaga({
  payload: { onSuccess, onError, ...rest },
}: IActionWithPayload<IUpdateNoteAttachmentsProps>) {
  try {
    const updatedNote: IMatchNoteDto = yield call(adminService.updateJobMatchNoteAttachments, rest);
    yield put(updateMatchNote(updatedNote));
    toast.success(messages.jobMatchNoteAttachmentsUpdated);
    onSuccess?.();
  } catch (error) {
    onError?.();
    handleRequestError(error);
  }
}

function* createCandidateSaga({
  payload: { onSuccess, onError, ...rest },
}: IActionWithPayload<ICreateCandidateProps>) {
  try {
    yield call(adminService.createCandidate, rest);

    toast.success(messages.newCandidateAddedSuccessfully);
    onSuccess?.();
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 424) {
      toast.error(messages.linkedinParseError);
    } else {
      handleRequestError(error);
    }
    onError?.();
  }
}

function* updateRejectedMatchSaga({
  payload: { onSuccess, onError, ...request },
}: IActionWithPayload<IAdminUpdateRejectedMatchPayload>) {
  try {
    const updatedRejectedMatch: IRejectedMatchDto = yield call(
      adminService.updateRejectedMatch,
      request,
    );

    yield put(setRejectedMatch(updatedRejectedMatch));

    onSuccess?.();
    toast.success(messages.rejectedFeedbackUpdated);
  } catch (error) {
    handleRequestError(error);
    onError?.();
  }
}

function* createAdminSaga({
  payload: { onSuccess, onError, ...request },
}: IActionWithPayload<ICreateAdminProps>) {
  try {
    yield call(adminService.createAdmin, request);

    onSuccess?.();
    toast.success(messages.adminCreatedSuccessfully);
  } catch (error) {
    handleRequestError(error);
    onError?.();
  }
}

export default function* browseCandidateRootSaga() {
  yield all([
    takeLatest(loadAdmins.type, loadAdminsSaga),
    takeLatest(loadJobs.type, loadJobsSaga),
    takeLatest(loadEmployers.type, loadEmployersSaga),
    takeLatest(loadCompanies.type, loadCompaniesSaga),
    takeLatest(loadImports.type, loadImportsSaga),
    takeLatest(assignAdminToJob.type, assignAdminToJobSaga),
    takeLatest(setMatchStatus.type, setMatchStatusSaga),
    takeLatest(setJobIsVettedStatus.type, setJobIsVettedStatusSaga),
    takeLatest(setJobPriority.type, setJobPrioritySaga),
    takeLatest(setJobStatus.type, setJobStatusSaga),
    takeLatest(setCandidateIsVettedStatus.type, setCandidateIsVettedStatusSaga),
    takeLatest(setUserStatus.type, setUserStatusSaga),
    takeLatest(importData.type, importDataSaga),
    takeLatest(sendConfirmationEmail.type, sendConfirmationEmailSaga),
    takeLatest(deleteEntity.type, deleteEntitySaga),
    takeLatest(setCandidateOpenToOffersStatus.type, setCandidateOpenToOffersStatusSaga),
    takeLatest(loadAdminCandidateMatches.type, loadAdminCandidateMatchesSaga),
    takeLatest(loadAdminJobMatches.type, loadAdminJobMatchesSaga),
    takeLatest(updateCompanyTermsUri.type, updateCompanyTermsUriSaga),
    takeLatest(updateCompanyTermsSignedStatus.type, updateCompanyTermsSignedSaga),
    takeLatest(loadEmailTemplates.type, loadEmailTemplatesSaga),
    takeLatest(updateTemplate.type, updateEmailTemplateIdSaga),
    takeLatest(updateEmployerHubspotId.type, updateEmployerHubspotIdSaga),
    takeLatest(updateCandidateHubspotId.type, updateCandidateHubspotIdSaga),
    takeLatest(loadNextAdminBrowseCandidates.type, loadNextAdminBrowseCandidatesSaga),
    takeLatest(adminInviteEmployer.type, inviteEmployerSaga),
    takeLatest(addNewMatch.type, addNewMatchSaga),
    takeLatest(updateCandidateReservedStatus.type, updateCandidateReservedStatusSaga),
    takeLatest(adminUpdateMatchStage.type, updateMatchStageSaga),
    takeLatest(loadAdminInterviewData.type, loadInterviewDataAdminSaga),
    takeLatest(adminCreateInterviewer.type, createInterviewerSaga),
    takeLatest(rejectMatch.type, rejectMatchSaga),
    takeLatest(adminCreateJobMatchNote.type, createJobMatchNoteSaga),
    takeLatest(adminUpdateJobMatchNote.type, updateJobMatchNoteSaga),
    takeLatest(adminDeleteJobMatchNote.type, deleteJobMatchNoteSaga),
    takeLatest(editJobMatchFeedback.type, editJobMatchFeedbackSaga),
    takeLatest(updateInterviewStatus.type, updateInterviewStatusSaga),
    takeLatest(updateInterviewDate.type, updateInterviewDateSaga),
    takeLatest(updateAdminMatchMessage.type, updateAdminMatchMessageSaga),
    takeLatest(assignAdminToMatch.type, assignAdminToMatchSaga),
    takeLatest(clientVisibilityJobMatchNote.type, clientVisibilityJobMatchNoteSaga),
    takeLatest(loadAdminMatchNotes.type, loadAdminMatchNotesSaga),
    takeLatest(updateInterviewLink.type, updateInterviewLinkSaga),
    takeLatest(updateScheduleLink.type, updateScheduleLinkSaga),
    takeLatest(loadJobMatch.type, loadJobMatchSaga),
    takeLatest(deleteJobMatchNoteAttachment.type, deleteJobMatchNoteAttachmentSaga),
    takeLatest(updateJobMatchNoteAttachments.type, updateJobMatchNoteAttachmentsSaga),
    takeLatest(createCandidate.type, createCandidateSaga),
    takeLatest(updateRejectedMatch.type, updateRejectedMatchSaga),
    takeLatest(createAdmin.type, createAdminSaga),
  ]);
}
