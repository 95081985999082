import { IRoute } from '@/client/types';
import { IUserDto } from '@/DTO';
import {
  checkUserHasPostedJob,
  checkUserOnboardingFinished,
  checkUserPermissions,
} from '@/client/utils/auth';

const isUserAuthenticatedForRoute = (
  isSignedIn: boolean,
  user: IUserDto | null,
  routeConfig: IRoute,
  path: string,
) =>
  // This route is private
  routeConfig.isAuthorized === true &&
  // The user is signed in
  isSignedIn &&
  // We have user information
  user &&
  // The user has a corresponding role
  checkUserPermissions(user.role, routeConfig.permissions) &&
  // The user has access to this route based on his onboarding status
  checkUserOnboardingFinished(user, path) &&
  // The employer has access to this route based on his job posting status
  checkUserHasPostedJob(user, path);

const isRouteAvailable = (
  isSignedIn: boolean,
  user: IUserDto | null,
  routeConfig: IRoute,
  path: string,
) => {
  // This is a route for unauthorized users only and current user is unauthorized
  const isUnauthorizedRoute = isSignedIn === false && routeConfig.isAuthorized === false;
  // This is a route available for any user
  const isPublicRoute = routeConfig.isAuthorized === null;

  return (
    isUnauthorizedRoute ||
    isPublicRoute ||
    isUserAuthenticatedForRoute(isSignedIn, user, routeConfig, path)
  );
};

export default isRouteAvailable;
