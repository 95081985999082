import { all, call, takeLatest, put, select, takeLeading } from 'redux-saga/effects';
import { locationsService, settingsService } from '@/client/services';
import { handleRequestError } from '@/client/utils';
import {
  IIndustryExperienceAttributes,
  IJobFunctionAttributes,
  ISalesQualificationAttributes,
  IVerticalAttributes,
} from '@/server/database';
import { IActionWithPayload, ILoadRegionsData } from '@/client/redux/types';
import { IRegion } from '@/client/types';
import {
  loadIndustryExperience,
  loadIndustryExperienceFinished,
  loadJobFunctions,
  loadRegions,
  loadVerticals,
  loadSalesQualifications,
} from '../actions/settings.actions';
import {
  storeIndustryExperience,
  storeJobFunctions,
  storeRegions,
  storeVerticals,
  storeSalesQualifications,
} from '../store/settings.slice';
import {
  industryExperienceSelector,
  jobFunctionsSelector,
  salesQualificationsSelector,
} from '../selectors';

function* loadJobFunctionsSaga() {
  try {
    const storedFunctions: IJobFunctionAttributes[] = yield select(jobFunctionsSelector);

    if (storedFunctions.length === 0) {
      const functions: IJobFunctionAttributes[] = yield call(settingsService.getJobFunctions);

      yield put(storeJobFunctions(functions));
    }
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadIndustryExperienceSaga() {
  try {
    const storedExperience: IIndustryExperienceAttributes[] = yield select(
      industryExperienceSelector,
    );

    if (storedExperience.length === 0) {
      const experience: IIndustryExperienceAttributes[] = yield call(
        settingsService.getIndustryExperience,
      );

      yield put(storeIndustryExperience(experience));
    }
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(loadIndustryExperienceFinished());
  }
}

function* loadVerticalsSaga() {
  try {
    const verticals: IVerticalAttributes[] = yield call(settingsService.getVerticals);

    yield put(storeVerticals(verticals));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadSalesQualificationsSaga() {
  try {
    const storedQualifications: ISalesQualificationAttributes[] = yield select(
      salesQualificationsSelector,
    );

    if (!storedQualifications.length) {
      const data: ISalesQualificationAttributes[] = yield call(
        settingsService.getSalesQualifications,
      );

      yield put(storeSalesQualifications(data));
    }
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadRegionsSaga(action: IActionWithPayload<ILoadRegionsData>) {
  try {
    const regions: IRegion[] = yield call(
      locationsService.loadRegions,
      action.payload.countryCode,
      action.payload.input,
    );

    yield put(storeRegions(regions));
  } catch (error) {
    handleRequestError(error);
  }
}

export default function* profileRootSaga() {
  yield all([
    takeLeading(loadJobFunctions.type, loadJobFunctionsSaga),
    takeLeading(loadIndustryExperience.type, loadIndustryExperienceSaga),
    takeLeading(loadVerticals.type, loadVerticalsSaga),
    takeLeading(loadSalesQualifications.type, loadSalesQualificationsSaga),
    takeLatest(loadRegions.type, loadRegionsSaga),
  ]);
}
