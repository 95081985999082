import { createAction } from '@reduxjs/toolkit';
import { ICandidateBasicInfoRequest } from '@/requests';
import { IEducationFormData } from '@/client/types';
import { ISkillsFormData } from '@/client/forms/profile/candidate/SkillsForm/types';
import { ISecondarySkillsFormData } from '@/client/forms/profile/candidate/SecondarySkillsForm/types';
import { ISettingsFormData } from '@/client/forms/profile/candidate/SettingsForm/types';
import { ISettingsFormData as IAdminSettingsFormData } from '@/client/forms/profile/admin/SettingsForm/types';
import { ICandidateUrlFormData } from '@/client/forms/profile/candidate/CandidateUrlForm/types';
import { IVerticalFormData } from '@/client/forms/profile/candidate/VerticalForm/types';
import { ISalesQualificationFormData } from '@/client/forms/profile/candidate/SelectQualificationForm/types';
import {
  ILoadCitiesData,
  ILoadRegionsData,
  ICandidateUpdateRequest,
  IResetProfilePassword,
} from '../types';

const LOAD_REGIONS = 'PROFILE/LOAD_REGIONS';
const LOAD_CITIES = 'PROFILE/LOAD_CITIES';
const LOAD_CANDIDATE = 'PROFILE/LOAD_CANDIDATE';
const LOAD_CANDIDATE_BY_ID = 'PROFILE/LOAD_CANDIDATE_BY_ID';
const LOAD_CANDIDATE_RESUME = 'PROFILE/LOAD_CANDIDATE_RESUME';
const UPLOAD_CANDIDATE_AVATAR = 'PROFILE/UPLOAD_PROFILE_AVATAR';
const UPDATE_CANDIDATE_BASIC_INFO = 'PROFILE/UPDATE_CANDIDATE_BASIC_INFO';
const UPDATE_CANDIDATE_BIO = 'PROFILE/UPDATE_CANDIDATE_BIO';
const UPDATE_CANDIDATE_COMPENSATION = 'PROFILE/UPDATE_CANDIDATE_COMPENSATION';
const UPDATE_CANDIDATE_EDUCATIONS = 'PROFILE/UPDATE_CANDIDATE_EDUCATIONS';
const UPDATE_CANDIDATE_SECONDARY_SKILLS = 'PROFILE/UPDATE_CANDIDATE_SECONDARY_SKILLS';
const UPDATE_CANDIDATE_SKILLS = 'PROFILE/UPDATE_CANDIDATE_SKILLS';
const RESET_PASSWORD = 'PROFILE/RESET_PASSWORD';
const UPDATE_CANDIDATE_URL = 'PROFILE/UPDATE_CANDIDATE_URL';
const UPDATE_CANDIDATE_SETTINGS = 'PROFILE/UPDATE_CANDIDATE_SETTINGS';
const UPDATE_ADMIN_SETTINGS = 'PROFILE/UPDATE_ADMIN_SETTINGS';
const UPDATE_CANDIDATE_VERTICALS = 'PROFILE/UPDATE_CANDIDATE_VERTICALS';
const UPDATE_CANDIDATE_SALES_QUALIFICATIONS = 'PROFILE/UPDATE_CANDIDATE_SALES_QUALIFICATIONS';

export const loadProfileRegions = createAction<ILoadRegionsData, typeof LOAD_REGIONS>(LOAD_REGIONS);
export const loadProfileCities = createAction<ILoadCitiesData, typeof LOAD_CITIES>(LOAD_CITIES);
export const loadProfileCandidate = createAction(LOAD_CANDIDATE);
export const loadProfileCandidateById = createAction<string, typeof LOAD_CANDIDATE_BY_ID>(
  LOAD_CANDIDATE_BY_ID,
);
export const loadCandidateResume = createAction(LOAD_CANDIDATE_RESUME);

export const uploadCandidateProfileAvatar = createAction<
  ICandidateUpdateRequest<File>,
  typeof UPLOAD_CANDIDATE_AVATAR
>(UPLOAD_CANDIDATE_AVATAR);

export const updateCandidateProfileBasicInfo = createAction<
  ICandidateUpdateRequest<ICandidateBasicInfoRequest>,
  typeof UPDATE_CANDIDATE_BASIC_INFO
>(UPDATE_CANDIDATE_BASIC_INFO);

export const updateCandidateProfileBio = createAction<
  ICandidateUpdateRequest<string>,
  typeof UPDATE_CANDIDATE_BIO
>(UPDATE_CANDIDATE_BIO);

export const updateCandidateProfileCompensation = createAction<
  ICandidateUpdateRequest<number>,
  typeof UPDATE_CANDIDATE_COMPENSATION
>(UPDATE_CANDIDATE_COMPENSATION);

export const updateCandidateProfileEducations = createAction<
  ICandidateUpdateRequest<IEducationFormData[]>,
  typeof UPDATE_CANDIDATE_EDUCATIONS
>(UPDATE_CANDIDATE_EDUCATIONS);

export const updateCandidateProfileSecondarySkills = createAction<
  ICandidateUpdateRequest<ISecondarySkillsFormData>,
  typeof UPDATE_CANDIDATE_SECONDARY_SKILLS
>(UPDATE_CANDIDATE_SECONDARY_SKILLS);

export const updateCandidateProfileSkills = createAction<
  ICandidateUpdateRequest<ISkillsFormData>,
  typeof UPDATE_CANDIDATE_SKILLS
>(UPDATE_CANDIDATE_SKILLS);

export const resetProfilePassword = createAction<IResetProfilePassword, typeof RESET_PASSWORD>(
  RESET_PASSWORD,
);

export const updateCandidateProfileUrl = createAction<
  ICandidateUrlFormData,
  typeof UPDATE_CANDIDATE_URL
>(UPDATE_CANDIDATE_URL);

export const updateCandidateProfileSettings = createAction<
  ICandidateUpdateRequest<ISettingsFormData>,
  typeof UPDATE_CANDIDATE_SETTINGS
>(UPDATE_CANDIDATE_SETTINGS);

export const updateAdminProfileSettings = createAction<
  ICandidateUpdateRequest<IAdminSettingsFormData>,
  typeof UPDATE_ADMIN_SETTINGS
>(UPDATE_ADMIN_SETTINGS);

export const updateCandidateProfileVerticals = createAction<
  ICandidateUpdateRequest<IVerticalFormData>,
  typeof UPDATE_CANDIDATE_VERTICALS
>(UPDATE_CANDIDATE_VERTICALS);

export const updateCandidateProfileSalesQualifications = createAction<
  ICandidateUpdateRequest<ISalesQualificationFormData>,
  typeof UPDATE_CANDIDATE_SALES_QUALIFICATIONS
>(UPDATE_CANDIDATE_SALES_QUALIFICATIONS);
