import { createSelector } from 'reselect';
import { IState } from '@/client/redux/types';

export const adminSelector = ({ admin }: IState) => admin;

export const adminsSelector = createSelector(adminSelector, ({ admins }) => admins);

export const adminLoadingSelector = createSelector(adminSelector, ({ isLoading }) => ({
  isLoading,
}));

export const adminJobsSelector = createSelector(adminSelector, ({ isLoading, total, jobs }) => ({
  isLoading,
  total,
  jobs,
}));

export const adminEmployersSelector = createSelector(
  adminSelector,
  ({ isLoading, total, employers }) => ({
    isLoading,
    total,
    employers,
  }),
);

export const adminTotalSelector = createSelector(adminSelector, ({ total }) => ({
  total,
}));

export const adminEmailTemplatesSelector = createSelector(
  adminSelector,
  ({ emailTemplates, isLoading, total }) => ({
    emailTemplates,
    isLoading,
    total,
  }),
);

export const adminImportsSelector = createSelector(
  adminSelector,
  ({ isLoading, total, imports }) => ({
    isLoading,
    total,
    imports,
  }),
);

export const adminCandidateSelector = createSelector(
  adminSelector,
  ({ isLoading, adminCandidateMatches }) => ({
    isLoading,
    adminCandidateMatches,
  }),
);

export const adminBrowseCandidateListSelector = createSelector(
  adminSelector,
  ({ candidates, isLoading, hasMore }) => ({
    candidates,
    isLoading,
    hasMore,
  }),
);

export const adminBrowseCandidateListQuerySelector = createSelector(
  adminSelector,
  ({ page, perPage, filters }) => ({ page, perPage, ...filters }),
);

export const adminBrowseCandidatesFiltersSelector = createSelector(
  adminSelector,
  ({ filters }) => filters,
);

export const adminCompaniesSelector = createSelector(
  adminSelector,
  ({ companies, isLoading, total }) => ({
    companies,
    isLoading,
    total,
  }),
);

export const adminJobMatchesSelector = createSelector(
  adminSelector,
  ({ isLoading, adminJobMatches }) => ({ isLoading, adminJobMatches }),
);

export const adminDeletingNoteIdSelector = createSelector(
  adminSelector,
  ({ deletingNoteId }) => deletingNoteId,
);

export const modalMatchSelector = createSelector(adminSelector, ({ modalMatch }) => ({
  modalMatch,
}));
