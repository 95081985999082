import { createAction } from '@reduxjs/toolkit';
import { IDeclineInterviewProps } from '../types';

const LOAD_EMPLOYER_JOBS = 'MY_JOBS/LOAD_JOBS';
const LOAD_JOB_APPLICANTS = 'MY_JOBS/LOAD_JOB_APPLICANTS';
const LOAD_SELECTED_EMPLOYER_JOB = 'MY_JOBS/LOAD_SELECTED_EMPLOYER_JOB';
const LOAD_CANDIDATE_APPLICANTS = 'MY_JOBS/LOAD_CANDIDATE_APPLICANTS';
const LOAD_CANDIDATE_INTERVIEWS = 'MY_JOBS/LOAD_CANDIDATE_INTERVIEWS';
const LOAD_COUNT_NEW_CANDIDATE_INTERVIEWS = 'MY_JOBS/LOAD_COUNT_NEW_CANDIDATE_INTERVIEWS';
const CANDIDATE_DECLINE_INTERVIEW = 'MY_JOBS/CANDIDATE_DECLINE_INTERVIEW';
const EMPLOYER_DECLINE_INTERVIEW = 'MY_JOBS/EMPLOYER_DECLINE_INTERVIEW';
const EMPLOYER_DECLINE_APPLICATION = 'MY_JOBS/EMPLOYER_DECLINE_APPLICATION';
const ARCHIVE_JOB = 'MY_JOBS/ARCHIVE_JOB';

export const loadEmployerJobs = createAction(LOAD_EMPLOYER_JOBS);

export const loadJobApplicants = createAction<string, typeof LOAD_JOB_APPLICANTS>(
  LOAD_JOB_APPLICANTS,
);

export const loadSelectedEmployerJob = createAction<string, typeof LOAD_SELECTED_EMPLOYER_JOB>(
  LOAD_SELECTED_EMPLOYER_JOB,
);

export const loadCandidateApplicants = createAction(LOAD_CANDIDATE_APPLICANTS);

export const loadCandidateInterviews = createAction(LOAD_CANDIDATE_INTERVIEWS);

export const loadCountNewCandateInterviews = createAction(LOAD_COUNT_NEW_CANDIDATE_INTERVIEWS);

export const candidateDeclineInterview = createAction<
  IDeclineInterviewProps,
  typeof CANDIDATE_DECLINE_INTERVIEW
>(CANDIDATE_DECLINE_INTERVIEW);

export const employerDeclineInterview = createAction<
  IDeclineInterviewProps,
  typeof EMPLOYER_DECLINE_INTERVIEW
>(EMPLOYER_DECLINE_INTERVIEW);

export const employerDeclineApplication = createAction<string, typeof EMPLOYER_DECLINE_APPLICATION>(
  EMPLOYER_DECLINE_APPLICATION,
);

export const archiveJob = createAction<string, typeof ARCHIVE_JOB>(ARCHIVE_JOB);
