import countries from '@/data/countries.json';
import { ICityBase, IDropdownItem, IRegion } from '@/client/types';
import { IAddressDto, ICityDto, IStateDto } from '@/DTO';

export const getCountryByName = (name?: string | null): IDropdownItem | null => {
  return countries.find((item) => item.name === name) || null;
};

export const getFormattedCity = (city?: ICityDto | null): ICityBase | null => {
  return city
    ? {
        id: city.id,
        name: city.name,
      }
    : null;
};

export const getFormattedState = (state?: IStateDto | null): IRegion | null => {
  return state
    ? {
        isoCode: state.code,
        name: state.name,
      }
    : null;
};

export const getLocationFromAddress = (address?: IAddressDto | null) =>
  [address?.city?.name, address?.state?.name, address?.country]
    .filter((item) => item !== undefined)
    .join(', ');

export const getLocationWithoutCountry = (address?: IAddressDto | null) =>
  [address?.city?.name, address?.state?.name].filter((item) => item !== undefined).join(', ');
