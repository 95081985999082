import { createSlice } from '@reduxjs/toolkit';
import { ICityBase, IRegion } from '@/client/types';
import { IActionWithPayload, IPostJobResult, IPostJobState } from '../types';

const initialState: IPostJobState = {
  regions: [],
  cities: [],
  data: undefined,
  isFetching: false,
  isLoading: false,
};

export const postJobSlice = createSlice({
  name: 'post-job',
  initialState,
  reducers: {
    storePostJobData: (state, action: IActionWithPayload<IPostJobResult>) => {
      state.data = { ...state.data, ...action.payload };
    },
    storePostJobRegions: (state, action: IActionWithPayload<IRegion[]>) => {
      state.regions = action.payload;
    },
    storePostJobCities: (state, action: IActionWithPayload<ICityBase[]>) => {
      state.cities = action.payload;
    },
    storePostJobFetching: (state, { payload }: IActionWithPayload<boolean>) => {
      state.isFetching = payload;
    },
    storePostLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.isLoading = payload;
    },
    clearPostJobData: (state) => {
      state.data = undefined;
    },
  },
});

export const {
  storePostJobData,
  storePostJobRegions,
  storePostJobCities,
  clearPostJobData,
  storePostJobFetching,
  storePostLoading,
} = postJobSlice.actions;

export const postJobIgnoredPaths = ['payload.companyInfo.logo', 'postJob.data.companyInfo.logo'];

export default postJobSlice.reducer;
