import { ICandidateInterviewDto, IInterviewDateDto, IInterviewDto, IJobInterviewDto } from '@/DTO';
import { IDefaultInterviewRequest } from '@/requests';
import { ISuccessResponse } from '@/responses';
import { IInterviewAttributes } from '@/server/database';
import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';

const getEmployerInterviews = async (jobId: string) => {
  const interviews = await oAuth()
    .axios.get(`/interviews/${jobId}`)
    .then((res: AxiosResponse<IJobInterviewDto[]>) => res.data);

  return interviews;
};

const getCandidateInterviews = async () => {
  const interviews = await oAuth()
    .axios.get('/interviews')
    .then((res: AxiosResponse<ICandidateInterviewDto[]>) => res.data);
  return interviews;
};

const declineInterview = async (interviewId: string) => {
  const interview = await oAuth()
    .axios.put('/interviews/decline', null, {
      params: {
        interviewId,
      },
    })
    .then((res: AxiosResponse<IInterviewAttributes>) => res.data);
  return interview;
};

const getCountNewCandidateInterviews = async () => {
  const interviews = await oAuth()
    .axios.get('/interviews/new-interviews')
    .then((res: AxiosResponse<{ count: number }>) => res.data);
  return interviews.count;
};

const resendInterviewEmail = async (interviewId: string) =>
  oAuth()
    .axios.post(`/interviews/request/${interviewId}/resend-email`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

const updateInterviewDate = async (interviewId: string, date: Date) => {
  const response = await oAuth()
    .axios.put<IInterviewDateDto>(`/interviews/date/${interviewId}`, { date })
    .then((res) => res.data);

  return response;
};

const requestDefaultInterview = async (payload: IDefaultInterviewRequest) => {
  const interview = await oAuth()
    .axios.post('/interviews/request/default', payload)
    .then((res: AxiosResponse<IInterviewDto>) => res.data);

  return interview;
};

export default {
  getEmployerInterviews,
  getCandidateInterviews,
  declineInterview,
  getCountNewCandidateInterviews,
  resendInterviewEmail,
  updateInterviewDate,
  requestDefaultInterview,
};
