import { IAuthOptions } from '@DevimaSolutions/o-auth';
import axios from 'axios';

const createAuthOptions = () => {
  const axiosInstance = axios.create({
    baseURL: '/api',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const oAuthOptions: IAuthOptions = {
    axiosInstance,
    useSocketManager: false,
    signIn: async (email: string, password: string) =>
      axiosInstance.post('/auth/login', {
        email,
        password,
      }),
    signOut: async () => Promise.resolve(),
    refreshToken: async (refresh_token: string) =>
      axiosInstance.post('/auth/token', {
        refresh_token,
      }),
    getUser: async (authToken: string) =>
      axiosInstance.get('/auth/user', {
        headers: {
          authorization: authToken,
        },
      }),
  };

  return oAuthOptions;
};

export default createAuthOptions;
