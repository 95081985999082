import { IEmployerDTO, IEmployerSettingsDTO, ICompanyTermsDTO } from '@/DTO';
import { IManageCompanyResponse } from '@/responses';
import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, IEmployerState } from '../types';

const initialState: IEmployerState = {
  employer: undefined,
  employerSettings: undefined,
  employerTermsData: null,
  manageCompanyData: [],
  total: 0,
  isFetching: false,
  isLoading: false,
};

export const employerSlice = createSlice({
  name: 'employer',
  initialState,
  reducers: {
    storeEmployerProfileData: (state, { payload }: IActionWithPayload<IEmployerDTO>) => {
      state.employer = payload.employer;
      state.employerSettings = payload.employerSettings;
    },
    storeEmployerSettingsProfileData: (state, action: IActionWithPayload<IEmployerSettingsDTO>) => {
      state.employerSettings = action.payload;
    },
    storeEmployerProfileAvatar: (state, { payload }: IActionWithPayload<string>) => {
      if (!state.employer) {
        return;
      }

      state.employer.imageUri = payload;
    },
    storeEmployerFetching: (state, { payload }: IActionWithPayload<boolean>) => {
      state.isFetching = payload;
    },
    storeEmployerLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.isLoading = payload;
    },
    storeEmployerTermsData: (state, { payload }: IActionWithPayload<ICompanyTermsDTO | null>) => {
      state.employerTermsData = payload;
    },
    storeEmployerManageCompanyData: (
      state,
      { payload }: IActionWithPayload<IManageCompanyResponse>,
    ) => {
      state.manageCompanyData = payload.data;
      state.total = payload.total;
    },
    clearEmployerProfileData: (state) => {
      state.employer = undefined;
      state.employerSettings = undefined;
      state.isLoading = false;
      state.manageCompanyData = [];
      state.total = 0;
    },
  },
});

export const {
  storeEmployerProfileData,
  storeEmployerSettingsProfileData,
  storeEmployerProfileAvatar,
  clearEmployerProfileData,
  storeEmployerFetching,
  storeEmployerLoading,
  storeEmployerTermsData,
  storeEmployerManageCompanyData,
} = employerSlice.actions;

export default employerSlice.reducer;
