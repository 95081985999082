import { createSelector } from 'reselect';
import { IState } from '../types';

export const settingsSelector = ({ settings }: IState) => settings;
export const jobFunctionsSelector = createSelector(settingsSelector, (state) => state.jobFunctions);
export const industryExperienceSelector = createSelector(
  settingsSelector,
  (state) => state.industryExperience,
);
export const verticalsSelector = createSelector(settingsSelector, (state) => state.verticals);
export const verticalsWithNullOptionSelector = createSelector(settingsSelector, (state) => [
  { id: 'null', value: 'No verticals' },
  ...state.verticals,
]);

export const salesQualificationsSelector = createSelector(
  settingsSelector,
  (state) => state.salesQualifications,
);
export const salesQualificationsWithNullOptionSelector = createSelector(
  settingsSelector,
  (state) => [{ id: 'null', label: 'No sales qualifications' }, ...state.salesQualifications],
);

export const headerStateSelector = createSelector(
  settingsSelector,
  ({ isHeaderFixed, isHeaderFullWidth, headerBackButtonHandler }) => ({
    isHeaderFixed,
    isHeaderFullWidth,
    headerBackButtonHandler,
  }),
);

export const postRegionsSelector = createSelector(settingsSelector, (state) => state.regions);
