import { ICandidateDataQuery } from '@/requests';
import { createAction } from '@reduxjs/toolkit';
import { IRequestDefaultInterviewPayload, IRequestInterviewPayload } from '../types';

const REQUEST_INTERVIEW = 'INTERVIEW/REQUEST_INTERVIEW';
const RESEND_INTERVIEW_EMAIL = 'INTERVIEW/RESEND_INTERVIEW_EMAIL';
const LOAD_CANDIDATE_AVAILABLE_JOBS = 'INTERVIEW/LOAD_CANDIDATE_AVAILABLE_JOBS';
const LOAD_INTERVIEW_AVAILABLE_EMPLOYERS = 'INTERVIEW/LOAD_INTERVIEW_AVAILABLE_EMPLOYERS';
const REQUEST_DEFAULT_INTERVIEW = 'INTERVIEW/REQUEST_DEFAULT_INTERVIEW';

export const requestInterview = createAction<IRequestInterviewPayload, typeof REQUEST_INTERVIEW>(
  REQUEST_INTERVIEW,
);

export const resendInterviewEmail = createAction<string, typeof RESEND_INTERVIEW_EMAIL>(
  RESEND_INTERVIEW_EMAIL,
);

export const loadCandidateAvailableJobs = createAction<
  ICandidateDataQuery,
  typeof LOAD_CANDIDATE_AVAILABLE_JOBS
>(LOAD_CANDIDATE_AVAILABLE_JOBS);

export const loadInterviewAvailableEmployers = createAction(LOAD_INTERVIEW_AVAILABLE_EMPLOYERS);

export const requestDefaultInterview = createAction<
  IRequestDefaultInterviewPayload,
  typeof REQUEST_DEFAULT_INTERVIEW
>(REQUEST_DEFAULT_INTERVIEW);
