import { ICompanyProfileFormData } from '@/client/forms/profile/employer/CompanyProfileForm/types';
import { createAction } from '@reduxjs/toolkit';
import { ILoadCitiesData, IUpdateCompanyProfileData } from '../types';

const COMPANIES_PROFILE_DATA = 'COMPANIES/PROFILE_DATA';
const COMPANIES_PROFILE_DATA_FINISHED = 'COMPANIES/PROFILE_DATA_FINISHED';
const COMPANIES_PROFILE_LOGO = 'COMPANIES/PROFILE_LOGO';
const COMPANIES_PROFILE_UPDATE = 'COMPANIES/PROFILE_UPDATE';
const LOAD_CITIES = 'COMPANIES/LOAD_CITIES';

export const getCompanyProfileData = createAction<string | undefined>(COMPANIES_PROFILE_DATA);

export const getCompanyProfileDataFinished = createAction(COMPANIES_PROFILE_DATA_FINISHED);

export const loadCompanyCities = createAction<ILoadCitiesData, typeof LOAD_CITIES>(LOAD_CITIES);

export const uploadCompanyProfileLogo = createAction<
  IUpdateCompanyProfileData<File>,
  typeof COMPANIES_PROFILE_LOGO
>(COMPANIES_PROFILE_LOGO);

export const updateCompanyProfile = createAction<
  IUpdateCompanyProfileData<ICompanyProfileFormData>,
  typeof COMPANIES_PROFILE_UPDATE
>(COMPANIES_PROFILE_UPDATE);
