const footerLinkBlocks = [
  {
    label: 'Company',
    items: [
      { label: 'About Us', href: 'https://alariss.com/about/' },
      { label: 'Careers', href: 'https://alariss.com/careers/' },
      { label: 'Contact Us', href: 'https://alariss.com/contact-us/' },
    ],
  },
  {
    label: 'Insights',
    items: [
      { label: 'Blog', href: 'https://alariss.com/blog/' },
      {
        label: 'Global Best Practices',
        href: 'https://alariss.com/top-6-mistakes-of-recruiting-talent-in-the-united-states/',
      },
      {
        label: 'Technology for Good',
        href: 'https://alariss.com/technology-for-social-good-karen-tay-and-sonam-velani/',
      },
      {
        label: 'Talent Macrotrends',
        href: 'https://alariss.com/three-macrotrends-in-the-globalization-of-talent/',
      },
      {
        label: 'Harvard Perspectives',
        href: 'https://alariss.com/harvard-student-international-career-amidst-covid/',
      },
    ],
  },
  {
    label: 'Support',
    items: [
      { label: 'Help center', href: 'mailto:help@alariss.com', target: '_blank' },
      { label: 'Terms of Service', href: '/terms-of-service', navigationLink: true },
      { label: 'Privacy Policy', href: 'https://alariss.com/privacy-policy/' },
    ],
  },
];

export default footerLinkBlocks;
