import NextLink from 'next/link';
import { Link } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../styles';
import { BaseLinkProps } from '../types';
import useCustomLinks from '../useCustomLinks';

export const NavigationLink = ({
  href,
  children,
  className,
  stopPropagation,
  disabled,
  ...props
}: BaseLinkProps) => {
  const { customLink } = useStyles();
  const { handlePropagation } = useCustomLinks(stopPropagation, disabled);

  return (
    <NextLink href={href} passHref>
      <Link
        onClick={handlePropagation}
        className={clsx({ [customLink]: !className }, className)}
        {...props}
      >
        {children}
      </Link>
    </NextLink>
  );
};

export default NavigationLink;
