import { IAlgorithmMatchScoreParamsProps } from '@/client/types';
import { createAction } from '@reduxjs/toolkit';
import { IRejectJobMatch } from '../types';

const LOAD_MATCHES_FOR_CANDIDATE = 'MATCHES/LOAD_MATCHES_FOR_CANDIDATE';
const REJECT_MATCH_FOR_JOB = 'MATCHES/REJECT_MATCH_FOR_JOB';
const REJECT_MATCH_FOR_CANDIDATE = 'MATCHES/REJECT_MATCH_FOR_CANDIDATE';
const APPROVE_MATCH_FOR_CANDIDATE = 'MATCHES/APPROVE_MATCH_FOR_CANDIDATE';
const LOAD_MATCH_SCORE_PARAMS = 'MATCHES/LOAD_MATCH_SCORE_PARAMS';
const LOAD_MATCH_CHANGES = 'MATCHES/LOAD_MATCH_CHANGES';
const LOAD_NEXT_CANDIDATE_SHORT_MATCHES = 'MATCHES/LOAD_NEXT_CANDIDATE_SHORT_MATCHES';

export const loadNextCandidateMatchesShortInfo = createAction<
  string,
  typeof LOAD_NEXT_CANDIDATE_SHORT_MATCHES
>(LOAD_NEXT_CANDIDATE_SHORT_MATCHES);

export const loadMatchesForCandidate = createAction(LOAD_MATCHES_FOR_CANDIDATE);

export const rejectMatchForJob = createAction<IRejectJobMatch, typeof REJECT_MATCH_FOR_JOB>(
  REJECT_MATCH_FOR_JOB,
);

export const rejectMatchForCandidate = createAction<string, typeof REJECT_MATCH_FOR_CANDIDATE>(
  REJECT_MATCH_FOR_CANDIDATE,
);

export const approveMatchForCandidate = createAction<string, typeof APPROVE_MATCH_FOR_CANDIDATE>(
  APPROVE_MATCH_FOR_CANDIDATE,
);

export const loadMatchScoreParams = createAction<
  IAlgorithmMatchScoreParamsProps,
  typeof LOAD_MATCH_SCORE_PARAMS
>(LOAD_MATCH_SCORE_PARAMS);

export const loadMatchChanges = createAction<string, typeof LOAD_MATCH_CHANGES>(LOAD_MATCH_CHANGES);
