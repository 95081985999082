import { createAction } from '@reduxjs/toolkit';
import { ILoadRegionsData } from '@/client/redux/types';

const LOAD_JOB_FUNCTIONS = 'SETTINGS/LOAD_JOB_FUNCTIONS';
const LOAD_INDUSTRY_EXPERIENCE = 'SETTINGS/LOAD_INDUSTRY_EXPERIENCE';
const LOAD_SALES_QUALIFICATIONS = 'SETTINGS/LOAD_SALES_QUALIFICATIONS';
const LOAD_INDUSTRY_EXPERIENCE_FINISHED = 'SETTINGS/LOAD_INDUSTRY_EXPERIENCE_FINISHED';
const LOAD_REGIONS = 'SETTINGS/LOAD_REGIONS';
const LOAD_VERTICALS = 'SETTINGS/LOAD_VERTICALS';

export const loadJobFunctions = createAction(LOAD_JOB_FUNCTIONS);
export const loadIndustryExperience = createAction(LOAD_INDUSTRY_EXPERIENCE);
export const loadSalesQualifications = createAction(LOAD_SALES_QUALIFICATIONS);
export const loadIndustryExperienceFinished = createAction(LOAD_INDUSTRY_EXPERIENCE_FINISHED);
export const loadRegions = createAction<ILoadRegionsData, typeof LOAD_REGIONS>(LOAD_REGIONS);
export const loadVerticals = createAction(LOAD_VERTICALS);
