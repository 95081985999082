import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';
import { IEmployerAttributes } from '@/server/database';
import { IOnboardingCandidateResponse, IOnboardingResult, IUploadFileResponse } from '@/responses';
import { ISaveCustomerOnboardingData } from '../redux/types';

const saveData = ({ data, type }: Omit<ISaveCustomerOnboardingData, 'step'>) =>
  oAuth()
    .axios.post(`/onboarding/candidate/${type}`, data)
    .then((res: AxiosResponse<IOnboardingCandidateResponse>) => res.data);

const updateData = async ({ data, type }: Omit<ISaveCustomerOnboardingData, 'step'>) => {
  const response = await oAuth()
    .axios.put(`/onboarding/candidate/${type}`, data)
    .then((res: AxiosResponse<IEmployerAttributes>) => res.data);

  return response;
};

const getOnboardingData = async () => {
  const response = await oAuth()
    .axios.get('/onboarding/candidate/status')
    .then((res: AxiosResponse<IOnboardingResult>) => res.data);

  return response;
};

const uploadProfileResume = async (payload: File) => {
  const formData = new FormData();
  formData.append('file', payload);

  const resume = await oAuth()
    .axios.post('/profile/candidate/resume', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: AxiosResponse<IUploadFileResponse>) => res.data);

  return resume.fileUri;
};

export default {
  saveData,
  updateData,
  getOnboardingData,
  uploadProfileResume,
};
