import { IPostJobResult } from '@/client/redux/types';
import { IJobDetailsDto } from '@/DTO';
import { getCountryByName, getFormattedState, isEnumKey } from '@/client/utils';
import { generatePayment } from '@/utils';
import { remoteOptions, availabilitiesOptions, experienceLevelOptions } from '@/client/options';
import { IIndustryExperienceAttributes } from '@/server/database';
import { CURRENT_SALARY_RANGE, POST_JOB_MIN_SALARY_RANGE } from '@/client/constants';
import { IUpdateBasicInfoRequest } from '@/requests';
import { CandidateAvailabilities, CandidateExperienceLevel } from '@/enums';
import { languagesConstants } from '@/constants';

/**
 * @function - Post job request data formatter
 *
 * @param data Pick<IPostJobResult, 'basicInfo'>
 * @param companyId optional string UUID v4
 * @param jobId optional string UUID v4
 * @returns IUpdateBasicInfoRequest
 * */
export const requestBasicInfoJob = (
  data: Pick<IPostJobResult, 'basicInfo'>,
  companyId?: string,
  jobId?: string,
): IUpdateBasicInfoRequest => ({
  jobData: {
    title: data.basicInfo?.title || '',
    jobFunctionId: data.basicInfo?.jobFunction?.id || '',
    experience: isEnumKey(
      CandidateExperienceLevel,
      data.basicInfo?.experienceLevel?.value ?? CandidateExperienceLevel.Intermediate,
    )
      ? data.basicInfo?.experienceLevel?.value ?? CandidateExperienceLevel.Intermediate
      : CandidateExperienceLevel.Intermediate,
    employmentType: isEnumKey(
      CandidateAvailabilities,
      data.basicInfo?.employmentType?.value ?? CandidateAvailabilities.FullTime,
    )
      ? data.basicInfo?.employmentType?.value ?? CandidateAvailabilities.FullTime
      : CandidateAvailabilities.FullTime,
    isRemote: data.basicInfo?.remote?.value === 'true',
    companyId: companyId || '',
  },
  preferredAddress: {
    country: data.basicInfo?.country?.name || '',
    state: data.basicInfo?.state?.name || '',
    stateCode: data.basicInfo?.state?.isoCode || '',
  },
  jobId: jobId || '',
});

export const requestCreateJob = (
  data: IPostJobResult,
  isDraft: boolean,
  employerUserId?: string,
) => {
  const basicInfo = requestBasicInfoJob(data, employerUserId);

  return {
    jobData: {
      ...basicInfo.jobData,
      aboutCompany: data.jobDescription?.opportunity?.toString() || '',
      aboutJob: data.jobDescription?.responsibilities?.toString() || '',
      salaryFrom: data.compensation?.salary?.from.toString() || '',
      salaryTo: data.compensation?.salary?.to.toString() || '',
      salaryCommission: data.compensation?.commission || '',
    },
    industryExperiences: data.desiredSkills?.industryExperience.length
      ? data.desiredSkills?.industryExperience.map((item) => item.id)
      : undefined,
    languages: data.desiredSkills?.languages.length ? data.desiredSkills?.languages : undefined,
    isDraft: String(isDraft),
    employerUserId,
    preferredAddress: {
      country: data.basicInfo?.country?.name || '',
      state: data.basicInfo?.state?.name || '',
      stateCode: data.basicInfo?.state?.isoCode || '',
    },
  };
};

export const parseJobResponse = (
  data: IJobDetailsDto,
  industryExperiences: IIndustryExperienceAttributes[],
): IPostJobResult => {
  return {
    basicInfo: {
      title: data.title,
      jobFunction: data.jobFunction,
      experienceLevel:
        experienceLevelOptions.find((item) => item.value === data.experience) || null,
      remote: remoteOptions.find((item) => item.value === String(data.isRemote)) || null,
      employmentType:
        availabilitiesOptions.find((item) => item.value === data.employmentType) || null,
      country: data.preferredAddress?.country
        ? getCountryByName(data.preferredAddress?.country)
        : null,
      state: data.preferredAddress?.state ? getFormattedState(data.preferredAddress?.state) : null,
    },
    desiredSkills: {
      industryExperience: industryExperiences.filter((item) =>
        data.industryExperience.includes(item.id),
      ),
      languages: data.languages.length ? data.languages : [languagesConstants.english.name],
      linkedinUrls: data.perfectCandidates,
    },
    jobDescription: {
      opportunity: data.aboutCompany,
      responsibilities: data.aboutJob,
    },
    compensation: {
      salary: {
        name: generatePayment(
          data.salaryFrom || POST_JOB_MIN_SALARY_RANGE,
          data.salaryTo || CURRENT_SALARY_RANGE,
        ),
        from: data.salaryFrom || POST_JOB_MIN_SALARY_RANGE,
        to: data.salaryTo || CURRENT_SALARY_RANGE,
      },
      commission: data.salaryCommission?.toString() || '',
      showCompensation: data.showCompensation,
    },
    internalInfo: {
      hubspotDealId: data.hubspotDealId,
    },
    status: data.status,
    verifiedByUser: data.verifiedByUser,
    employerUserId: data.employerUserId ?? undefined,
    companyName: data.companyInfo.name,
  };
};
