import oAuth from '@DevimaSolutions/o-auth';
import { IApplyToJobPayload } from '@/client/redux/types';
import { requestApplyToJob } from '@/client/utils';
import { AxiosResponse } from 'axios';
import { IApplicantAttributes } from '@/server/database';
import { IBaseApplicantDto, ICandidateApplicationDto, IJobApplicantDto } from '@/DTO';

const applyToJob = (data: IApplyToJobPayload) => {
  const fd = requestApplyToJob(data);
  return oAuth()
    .axios.post<IBaseApplicantDto>('/applicants/apply', fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data);
};

const getCandidateApplicants = () =>
  oAuth()
    .axios.get<ICandidateApplicationDto[]>('/applicants')
    .then((res) => res.data);

const getEmployerApplicants = async (jobId: string) => {
  const applicants = await oAuth()
    .axios.get(`/applicants/${jobId}`)
    .then((res: AxiosResponse<IJobApplicantDto[]>) => res.data);

  return applicants;
};

const declineApplication = async (applicantId: string) => {
  const result = await oAuth()
    .axios.put('/applicants/decline', { applicantId })
    .then((res: AxiosResponse<IApplicantAttributes>) => res.data);
  return result;
};

export default {
  getCandidateApplicants,
  getEmployerApplicants,
  applyToJob,
  declineApplication,
};
