import { ICityBase, IRegion } from '@/client/types';
import { createSlice } from '@reduxjs/toolkit';
import {
  ICandidateBaseInfoDto,
  ICandidateDto,
  ICandidateSkillsDto,
  ICandidateSecondarySkillsDto,
  ICandidateVerticalDto,
  ICandidateSalesQualificationDto,
} from '@/DTO';
import { ICandidateEducationAttributes } from '@/server/database';
import { IAdminUpdateUserStatusRequest } from '@/requests';
import { IActionWithPayload, IProfileState } from '../types';

const initialState: IProfileState = {
  candidate: null,
  regions: [],
  cities: [],
  resumeUri: '',
  logoUri: '',
  loading: false,
  fetching: false,
  errors: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    storeProfileRegions: (state, action: IActionWithPayload<IRegion[]>) => {
      state.regions = action.payload;
    },
    storeProfileCities: (state, action: IActionWithPayload<ICityBase[]>) => {
      state.cities = action.payload;
    },
    storeProfileCandidate: (state, action: IActionWithPayload<ICandidateDto>) => {
      state.candidate = action.payload;
    },
    storeCandidateResume: (state, action: IActionWithPayload<string>) => {
      state.resumeUri = action.payload;
    },
    storeCandidateProfileAvatar: (state, { payload }: IActionWithPayload<string>) => {
      if (state.candidate) {
        state.candidate = {
          ...state.candidate,
          baseInfo: { ...state.candidate.baseInfo, imageUri: payload },
        };
      }
    },
    storeCandidateProfileBasicInfo: (
      state,
      { payload }: IActionWithPayload<Partial<ICandidateBaseInfoDto>>,
    ) => {
      if (state.candidate) {
        state.candidate = {
          ...state.candidate,
          baseInfo: { ...state.candidate.baseInfo, ...payload },
        };
      }
    },
    storeCandidateProfileBio: (state, { payload }: IActionWithPayload<string>) => {
      if (state.candidate) {
        state.candidate = {
          ...state.candidate,
          baseInfo: { ...state.candidate.baseInfo, bio: payload },
        };
      }
    },
    storeCandidateProfileCompensation: (state, { payload }: IActionWithPayload<number>) => {
      if (state.candidate) {
        state.candidate = {
          ...state.candidate,
          baseInfo: { ...state.candidate.baseInfo, annualRate: payload },
        };
      }
    },
    storeCandidateProfileEducations: (
      state,
      { payload }: IActionWithPayload<ICandidateEducationAttributes[]>,
    ) => {
      if (state.candidate) {
        state.candidate = {
          ...state.candidate,
          educations: payload,
        };
      }
    },
    storeCandidateProfileSecondarySkills: (
      state,
      { payload }: IActionWithPayload<ICandidateSecondarySkillsDto>,
    ) => {
      if (state.candidate) {
        state.candidate = {
          ...state.candidate,
          ...payload,
        };
      }
    },
    storeCandidateProfileSkills: (state, { payload }: IActionWithPayload<ICandidateSkillsDto>) => {
      const { experienceLevel, ...candidateInfo } = payload;
      if (state.candidate) {
        state.candidate = {
          ...state.candidate,
          ...candidateInfo,
        };

        state.candidate.baseInfo = {
          ...state.candidate.baseInfo,
          experienceLevel,
        };
      }
    },
    storeCandidateProfileStatus: (
      state,
      { payload }: IActionWithPayload<IAdminUpdateUserStatusRequest>,
    ) => {
      if (state.candidate) {
        state.candidate.baseInfo.status = payload.status;
      }
    },
    storeProfileLoading: (state, { payload }: IActionWithPayload<boolean>) => {
      state.loading = payload;
    },
    storeProfileFetching: (state, { payload }: IActionWithPayload<boolean>) => {
      state.fetching = payload;
    },
    clearProfileLocations: (state) => {
      state.cities = [];
      state.regions = [];
    },
    storeProfileError: (
      state,
      action: IActionWithPayload<Record<string, string | null> | null>,
    ) => {
      state.errors = action.payload;
    },
    storeCandidateProfileVertical: (
      state,
      { payload }: IActionWithPayload<ICandidateVerticalDto>,
    ) => {
      if (state.candidate) {
        state.candidate.verticals = payload.verticals;
      }
    },
    storeCandidateProfileSalesQualifications: (
      state,
      { payload }: IActionWithPayload<ICandidateSalesQualificationDto>,
    ) => {
      if (state.candidate) {
        state.candidate.salesQualifications = payload.salesQualifications;
      }
    },
  },
});

export const {
  storeProfileRegions,
  storeProfileCities,
  storeProfileCandidate,
  storeCandidateResume,
  storeCandidateProfileAvatar,
  storeProfileLoading,
  storeCandidateProfileBasicInfo,
  storeCandidateProfileBio,
  storeCandidateProfileCompensation,
  storeCandidateProfileEducations,
  storeCandidateProfileSecondarySkills,
  storeCandidateProfileSkills,
  storeCandidateProfileStatus,
  clearProfileLocations,
  storeProfileError,
  storeProfileFetching,
  storeCandidateProfileVertical,
  storeCandidateProfileSalesQualifications,
} = profileSlice.actions;

export default profileSlice.reducer;
