import axios from 'axios';
import toast from 'react-hot-toast';
import { messages } from '@/client/constants';

/**
 * @function getErrorStringFromObj finding a string inside object keys
 *
 * @param obj Record<string, unknown>
 * @returns string
 */
function getErrorStringFromObj(obj: Record<string, unknown> | {}): string {
  const [[, value]] = Object.entries(obj);

  if (typeof value === 'string') {
    return value;
  }

  if (value && typeof value === 'object') {
    return getErrorStringFromObj(value);
  }

  return messages.somethingWentWrong;
}

export function getRequestErrorMessage(error: unknown) {
  if (axios.isAxiosError(error)) {
    if (typeof error?.response?.data === 'object' && !error.response?.data?.message) {
      return getErrorStringFromObj(error?.response?.data);
    }

    return error.response?.data?.message || error.message || messages.somethingWentWrong;
  }

  return messages.somethingWentWrong;
}

export function handleRequestError(error: unknown) {
  toast.error(getRequestErrorMessage(error));
}
