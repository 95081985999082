import { makeStyles } from '@material-ui/core';
import { headerHeight } from '../Header/styles';

const useStyles = makeStyles((theme) => ({
  menu: {
    width: 14.5,
    height: 14.5,
    border: 'none',
    position: 'relative',
    backgroundColor: 'transparent',
    padding: theme.spacing(4, 8, 0, 8),
    color: theme.palette.blackMain,
    display: ' block',
    '&:focus, &:active, &:hover': {
      outline: 'none',
    },

    '& $line': {
      position: 'absolute',
      height: '0.125rem',
      width: 18,
      background: theme.palette.blackMain,
      borderRadius: '0.5rem',
      left: '50%',
      transform: 'translateX(-50%)',
      transition: 'cubic-bezier(0.26, 0.1, 0.27, 1.55) 0.35s',
    },

    '& $top': {
      top: '15%',
    },
    '& $middle': {
      top: '50%',
    },
    '& $bottom': {
      top: '85%',
    },

    '&$close': {
      '& $line': {
        background: theme.palette.blackMain,
      },

      '& $top': {
        top: '50%',
        transform: 'translateX(-50%) rotate(45deg)',
      },

      '& $middle': { top: '50%', transform: 'translateX(-50%) rotate(-45deg)' },
      '& $bottom': {
        top: '50%',
        transform: 'translateX(-50%) rotate(-45deg)',
      },
    },
  },
  close: {},
  top: {},
  line: {},
  middle: {},
  bottom: {},
  drawer: {
    '& > div': {
      top: headerHeight.mobile,
      [theme.breakpoints.up('sm')]: {
        top: headerHeight.tablet,
      },
      [theme.breakpoints.up('lg')]: {
        top: headerHeight.desktop,
      },
    },
  },
  profileImageContainer: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(10),
  },
  userName: {
    fontWeight: 500,
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    marginLeft: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
  },
  drawerPaper: {
    boxShadow: 'none',
    minWidth: '240px',
  },
  button: {
    width: '100%',
    justifyContent: 'start',
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    fontWeight: 500,
    textTransform: 'none',
    padding: theme.spacing(8, 10),
    color: theme.palette.blackMain,
    '&:hover': { textDecoration: 'none' },
  },
  logout: {
    color: theme.palette.mainBlue,
  },
  gridItem: { display: 'flex' },
}));

export default useStyles;
