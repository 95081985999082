import { ICompanyProfileFormData } from '@/client/forms/profile/employer/CompanyProfileForm/types';
import { IEditComplanyProfileRequest } from '@/requests';

export const requestCompanyProfileData = (
  data: ICompanyProfileFormData,
): IEditComplanyProfileRequest => ({
  companyData: {
    name: data.companyName,
    website: data.website,
    description: data.description,
  },
  targetMarkets: data.targetMarkets,
  address: {
    country: data.country?.name || '',
    city: data.city?.name || null,
    cityId: data.city?.id || null,
  },
});
