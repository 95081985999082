import { all } from 'redux-saga/effects';
import adminRootSaga from './admin.saga';
import applyToJobRootSaga from './apply-to-job.saga';
import authSaga from './authorization.saga';
import browseCandidateSaga from './browse-candidate.saga';
import browseJobSaga from './browse-job.saga';
import employerSaga from './employer.saga';
import matchesRootSaga from './matches.saga';
import myJobsSaga from './my-jobs.saga';
import onboardingSaga from './onboarding.saga';
import postJobSaga from './post-job.saga';
import profileSaga from './profile.saga';
import requestInterviewRootSaga from './request-interview.saga';
import settingsSaga from './settings.saga';
import companiesSaga from './companies.saga';
import matchesJobsSaga from './matches-jobs.saga';

function* rootSaga() {
  yield all([
    authSaga(),
    employerSaga(),
    onboardingSaga(),
    profileSaga(),
    settingsSaga(),
    postJobSaga(),
    myJobsSaga(),
    browseJobSaga(),
    browseCandidateSaga(),
    applyToJobRootSaga(),
    requestInterviewRootSaga(),
    adminRootSaga(),
    matchesRootSaga(),
    companiesSaga(),
    matchesJobsSaga(),
  ]);
}

export default rootSaga;
