import { CustomLink, NavigationLink } from '@/client/components';
import { ButtonBase, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Image from 'next/image';
import footerLinkBlocks from './footerLinkBlocks';
import socialLinks from './socialLinks';
import useStyles from './styles';

export const Footer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.infoBlock}>
          <div className={classes.copyrightBlock}>
            <ButtonBase
              href="http://www.alariss.com"
              target="_blank"
              rel="noreferrer noopener"
              disableRipple
            >
              <img className={classes.logo} src="/logo.svg" alt="Alariss" />
            </ButtonBase>
            <Typography className={classes.text} variant="body1">
              Copyright © 2023 Alariss Global, Inc.
            </Typography>
            <Typography className={clsx(classes.text, classes.rightsReserved)} variant="body1">
              All rights reserved
            </Typography>
          </div>
          <div className={classes.socialBlock}>
            {socialLinks.map(({ iconSrc, iconAlt, ...item }) => (
              <CustomLink
                rel="noopener noreferrer"
                target="_blank"
                key={iconAlt}
                className={classes.icon}
                {...item}
              >
                <Image src={iconSrc} alt={iconAlt} width="32" height="32" />
              </CustomLink>
            ))}
          </div>
        </div>
        <div className={classes.linksBlock}>
          {footerLinkBlocks.map((block) => (
            <div key={block.label} className={classes.listBlock}>
              <Typography className={classes.title} variant="h2">
                {block.label}
              </Typography>
              {block.items.map(({ label, navigationLink, ...item }) => {
                const LinkComponent = navigationLink ? NavigationLink : CustomLink;

                return (
                  <LinkComponent key={label} className={classes.link} {...item}>
                    {label}
                  </LinkComponent>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
