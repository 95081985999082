import oAuth from '@DevimaSolutions/o-auth';
import { ISuccessResponse } from '@/responses';
import { IUpdateMatchTextNoteProps, IUpdateNoteAttachmentsProps } from '@/client/redux/types';
import { IClientMatchMessageDto, IJobMatchesCountDto, IMatchNoteDto } from '@/DTO';
import {
  ICreateMatchTextNoteRequest,
  IMatchStageRequest,
  IMatchTextNoteAttachmentDataQuery,
} from '@/requests';
import { MatchStage } from '@/enums';

const getJobMatchesCount = async (jobId: string) => {
  const response = await oAuth()
    .axios.get<IJobMatchesCountDto[]>(`/matches/${jobId}/count`)
    .then((res) => res.data);

  return response;
};

const updateMatchStage = async (matchId: string, data: IMatchStageRequest) =>
  oAuth().axios.put(`/matches/job/${matchId}/stage`, data);

const createJobMatchNote = async ({ matchId, ...rest }: ICreateMatchTextNoteRequest) => {
  const formData = new FormData();

  rest.attachments?.map((attachment) => formData.append('files', attachment));

  formData.append('noteText', rest.noteText);

  const response = await oAuth()
    .axios.post<IMatchNoteDto>(`/matches/job/${matchId}/notes/text-note`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data);

  return response;
};

const updateJobMatchNote = async ({ matchNoteId, text }: IUpdateMatchTextNoteProps) =>
  oAuth()
    .axios.put<IMatchNoteDto>(`/matches/notes/${matchNoteId}/text-note`, { text })
    .then((res) => res.data);

const deleteJobMatchNote = async (matchNoteId: string) =>
  oAuth()
    .axios.delete<ISuccessResponse>(`/matches/notes/${matchNoteId}/text-note`)
    .then((res) => res.data);

const updateJobMatchClientMessage = (matchId: string, message: string | null, stage: MatchStage) =>
  oAuth()
    .axios.put<IClientMatchMessageDto>('/matches/client-message', {
      matchId,
      message,
      stage,
    })
    .then((res) => res.data);

const deleteJobMatchNoteAttachment = async ({
  attachmentId,
  matchNoteId,
}: IMatchTextNoteAttachmentDataQuery) =>
  oAuth()
    .axios.delete<ISuccessResponse>(`/matches/notes/${matchNoteId}/${attachmentId}`)
    .then((res) => res.data);

const updateJobMatchNoteAttachments = async ({
  matchNoteId,
  attachments,
}: IUpdateNoteAttachmentsProps) => {
  const formData = new FormData();

  attachments.map((attachment) => formData.append('files', attachment));

  formData.append('matchNoteId', matchNoteId);

  const response = oAuth()
    .axios.post<IMatchNoteDto>(`/matches/notes/${matchNoteId}/attachments`, formData)
    .then((res) => res.data);
  return response;
};

export default {
  getJobMatchesCount,
  updateMatchStage,
  createJobMatchNote,
  updateJobMatchNote,
  deleteJobMatchNote,
  updateJobMatchClientMessage,
  deleteJobMatchNoteAttachment,
  updateJobMatchNoteAttachments,
};
