import { string } from 'yup';
import { ICandidateDataType } from '@/client/types';
import { linkedinConstants } from '@/constants';

export const validURL = (uri: string) => {
  const startWith = ['https', 'http'];
  const internalUrl = startWith.some((item) => uri.startsWith(item)) || !uri ? uri : `//${uri}`;

  return string().url().isValid(internalUrl);
};

export const validLinkedInURL = (uri: string, isOptional?: boolean) => {
  if (isOptional && !uri) {
    return true;
  }

  const internalUrl = uri.startsWith(linkedinConstants.accountBaseUri) ? uri : null;

  return string().url().isValid(internalUrl);
};

export const validLinkedInURLSync = (uri: string) => {
  const internalUrl = uri.startsWith(linkedinConstants.accountBaseUri) ? uri : null;

  return string().url().isValidSync(internalUrl);
};

export const urlWithSchema = (url: string) => {
  const startWith = ['https', 'http'];

  return startWith.some((item) => url.startsWith(item)) ? url : `//${url}`;
};

export const candidateProfileEditUrl = (dataType: ICandidateDataType, candidateId?: string) =>
  candidateId
    ? `admin/candidates/${candidateId}/edit/${dataType}`
    : `profile/candidate/${dataType}`;
