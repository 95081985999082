import { all, call, select, takeLatest, put, take } from 'redux-saga/effects';
import { locationsService, onboardingService, profileService } from '@/client/services';
import { ICityBase, IRegion } from '@/client/types';
import oAuth from '@DevimaSolutions/o-auth';
import {
  getFormattedCandidateOnboardingBasicInfoData,
  getFormattedCandidateOnboardingData,
  getFormattedCandidateOnboardingEducationsData,
  handleRequestError,
  hasNumberValue,
} from '@/client/utils';
import {
  IOnboardingBasicInfoResponse,
  IOnboardingCandidateResponse,
  IOnboardingCompensationResponse,
  IOnboardingFinalResponse,
  IOnboardingHeadlineResponse,
  IOnboardingResult,
} from '@/responses';
import {
  ICandidateEducationAttributes,
  IIndustryExperienceAttributes,
  IJobFunctionAttributes,
} from '@/server/database';
import { IOnboardingFinalForm } from '@/client/forms/onboarding/candidate/FinalDetails/types';
import axios from 'axios';
import { CandidateOnboardingStatus } from '@/enums';
import {
  IActionWithPayload,
  ISaveCustomerOnboardingData,
  ILoadCitiesData,
  ILoadRegionsData,
  IFormatedOnboardingResult,
} from '../types';

import {
  loadOnboardingRegions,
  saveCustomerOnboardingData,
  loadOnboardingCities,
  loadOnboardingData,
  onboardingExit,
  loadJobFunctions,
  loadIndustryExperience,
  loadIndustryExperienceFinished,
} from '../actions';
import {
  industryExperienceSelector,
  jobFunctionsSelector,
  onboardingDataSelector,
  userImageSelector,
} from '../selectors';
import {
  storeOnboardingRegions,
  storeOnboardingCities,
  handleNext,
  storeOnboardingData,
  storeOnboardingLoading,
  storeOnboardingCandidateData,
  setActiveStep,
  storeOnboardingFetching,
} from '../store/onboarding.slice';
import { setProfileImage } from '../store/authorization.slice';

function* updateOnboardingCandidateDataSaga(payload: {
  data: IOnboardingCandidateResponse;
  type: string;
}) {
  const jobFunctions: IJobFunctionAttributes[] = yield select(jobFunctionsSelector);
  yield put(loadIndustryExperience());
  yield take(loadIndustryExperienceFinished);

  const industryExperiences: IIndustryExperienceAttributes[] = yield select(
    industryExperienceSelector,
  );

  const { data, type } = payload;

  let storeData: Partial<IFormatedOnboardingResult> | undefined;

  switch (type) {
    case 'basic-info':
      storeData = getFormattedCandidateOnboardingBasicInfoData(
        data as IOnboardingBasicInfoResponse,
        jobFunctions,
        industryExperiences,
      );
      break;
    case 'headline':
      storeData = { headline: data as IOnboardingHeadlineResponse };
      break;
    case 'education':
      storeData = {
        educations: getFormattedCandidateOnboardingEducationsData(
          data as ICandidateEducationAttributes[],
        ),
      };
      break;
    case 'compensation':
      storeData = { compensation: data as IOnboardingCompensationResponse };
      break;
    case 'final':
      storeData = { final: data as IOnboardingFinalResponse };
      break;
    default:
      break;
  }

  if (storeData) {
    yield put(storeOnboardingCandidateData(storeData));
  }
}

function* saveCustomerOnboardingSaga(action: IActionWithPayload<ISaveCustomerOnboardingData>) {
  yield put(storeOnboardingLoading(true));
  try {
    const { data, step, type } = action.payload;

    if (step === CandidateOnboardingStatus.FinalDetails) {
      const { profilePhoto, resume } = data as IOnboardingFinalForm;

      if (resume) {
        const resumeUri: string = yield call(onboardingService.uploadProfileResume, resume);
        yield put(storeOnboardingCandidateData({ resumeUri }));
      }

      if (profilePhoto) {
        const imageUrl: string = yield call(profileService.uploadProfileImage, profilePhoto);
        yield put(setProfileImage(imageUrl));
        yield put(storeOnboardingCandidateData({ imageUri: imageUrl }));
      }
    }
    const onboardingData: Partial<IFormatedOnboardingResult> | null = yield select(
      onboardingDataSelector,
    );

    const response: IOnboardingCandidateResponse = yield call(onboardingService.saveData, {
      data,
      type,
    });

    yield call(updateOnboardingCandidateDataSaga, { data: response, type });

    yield put(handleNext());
    if (
      onboardingData?.onboardingStatus === undefined ||
      !hasNumberValue(onboardingData?.onboardingStatus)
    ) {
      yield put(
        storeOnboardingCandidateData({
          onboardingStatus: CandidateOnboardingStatus.Headline,
        }),
      );
    } else {
      yield put(
        storeOnboardingCandidateData({
          ...(onboardingData.onboardingStatus <= step ? { onboardingStatus: step + 1 } : {}),
        }),
      );
    }
  } catch (error) {
    handleRequestError(error);
  } finally {
    yield put(storeOnboardingLoading(false));
  }
}

function* loadRegionsSaga(action: IActionWithPayload<ILoadRegionsData>) {
  try {
    const regions: IRegion[] = yield call(
      locationsService.loadRegions,
      action.payload.countryCode,
      action.payload.input,
    );

    yield put(storeOnboardingRegions(regions));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadCitiesSaga(action: IActionWithPayload<ILoadCitiesData>) {
  try {
    const cities: ICityBase[] = yield call(
      locationsService.loadCities,
      action.payload.countryCode,
      action.payload.regionCode,
      action.payload.input,
    );

    yield put(storeOnboardingCities(cities));
  } catch (error) {
    handleRequestError(error);
  }
}

function* loadOnboardingDataSaga() {
  yield put(storeOnboardingFetching(true));

  try {
    yield all([put(loadJobFunctions()), put(loadIndustryExperience())]);
    yield take(loadIndustryExperienceFinished);
    const onboarding: IOnboardingResult = yield call(onboardingService.getOnboardingData);

    const [jobFunctions, industryExperiences]: [
      IJobFunctionAttributes[],
      IIndustryExperienceAttributes[],
    ] = yield all([select(jobFunctionsSelector), select(industryExperienceSelector)]);

    yield put(
      storeOnboardingData(
        getFormattedCandidateOnboardingData(onboarding, jobFunctions, industryExperiences),
      ),
    );

    if (hasNumberValue(onboarding?.onboardingStatus)) {
      yield put(setActiveStep(onboarding.onboardingStatus));
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      yield put(storeOnboardingData(null));
    } else {
      handleRequestError(error);
    }
  } finally {
    yield put(storeOnboardingFetching(false));
  }
}

function* onOnboardingExitSaga() {
  // Update profile image that we set after onboarding to display it on a thank you page
  const imageUri: string | undefined = yield select(userImageSelector);
  yield oAuth().updateUser({
    isOnboardingFinished: true,
    imageUri,
  });
}

export default function* onboardingRootSaga() {
  yield all([
    takeLatest(saveCustomerOnboardingData.type, saveCustomerOnboardingSaga),
    takeLatest(loadOnboardingRegions.type, loadRegionsSaga),
    takeLatest(loadOnboardingCities.type, loadCitiesSaga),
    takeLatest(loadOnboardingData.type, loadOnboardingDataSaga),
    takeLatest(onboardingExit.type, onOnboardingExitSaga),
  ]);
}
