import { createAction } from '@reduxjs/toolkit';
import {
  ILoadAdminPostJobData,
  ILoadCitiesData,
  ILoadJobData,
  ILoadRegionsData,
  IPostJobData,
  ISavePostJobData,
  IUpdateJobData,
} from '../types';

const LOAD_REGIONS = 'POST_JOB/LOAD_REGIONS';
const LOAD_CITIES = 'POST_JOB/LOAD_CITIES';
const LOAD_DATA = 'POST_JOB/LOAD_DATA';
const ADMIN_LOAD_DATA = 'POST_JOB/ADMIN_LOAD_DATA';
const LOAD_JOB_DATA = 'POST_JOB/LOAD_JOB_DATA';
const SAVE_DATA = 'POST_JOB/SAVE_DATA';
const UPDATE_DATA = 'POST_JOB/UPDATE_DATA';
const POST_JOB = 'POST_JOB/POST_JOB';

export const loadPostJobRegions = createAction<ILoadRegionsData, typeof LOAD_REGIONS>(LOAD_REGIONS);
export const loadPostJobCities = createAction<ILoadCitiesData, typeof LOAD_CITIES>(LOAD_CITIES);
export const loadPostJobData = createAction(LOAD_DATA);
export const loadAdminPostJobData = createAction<ILoadAdminPostJobData, typeof ADMIN_LOAD_DATA>(
  ADMIN_LOAD_DATA,
);
export const loadJobData = createAction<ILoadJobData, typeof LOAD_JOB_DATA>(LOAD_JOB_DATA);

export const savePostJobData = createAction<ISavePostJobData, typeof SAVE_DATA>(SAVE_DATA);
export const updateJobData = createAction<IUpdateJobData, typeof UPDATE_DATA>(UPDATE_DATA);

export const postJob = createAction<IPostJobData, typeof POST_JOB>(POST_JOB);
