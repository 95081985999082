import { useCallback, MouseEvent } from 'react';

const useCustomLinks = (stopPropagation?: boolean, disabled?: boolean) => {
  const handlePropagation = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (stopPropagation) {
        event.stopPropagation();
      }

      if (disabled) {
        event.preventDefault();
      }
    },
    [disabled, stopPropagation],
  );

  return {
    handlePropagation,
  };
};

export default useCustomLinks;
