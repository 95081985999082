import { Footer, Header } from '@/client/components';
import { ILayout } from '../types';
import useMainLayout from './useMainLayout';

const MainLayout = ({ children }: ILayout): JSX.Element => {
  useMainLayout();

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default MainLayout;
