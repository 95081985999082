import oAuth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';
import qs from 'qs';

import {
  IPostJobData,
  IPostJobRequestData,
  IPostJobResponse,
  IPostJobResult,
} from '@/client/redux/types';
import { requestCreateJob } from '@/client/utils';
import { IBrowseJobDTO, IJobDetailsDto, IJobInfoDTO, IJobShortInfoDto } from '@/DTO';
import { PostJobRequests } from '@/enums';
import { ISuccessResponse } from '@/responses';
import { IInfiniteScrollPaginationResponse } from '@/responses/pagination.response';
import { IJobAttributes } from '@/server/database';
import {
  IAdminBasicInfoRequest,
  IAdminMatchesAvailableJobsQuery,
  IBasicInfoRequest,
  IBrowseJobQuery,
  ICandidateDataQuery,
  ICompensationRequest,
  IDesiredSkillsRequest,
  IInternalInfoRequest,
  IJobDescriptionRequest,
  IUpdateBasicInfoRequest,
} from '@/requests';

const getEmployerJobs = async () => {
  const jobs = await oAuth()
    .axios.get('/my-jobs')
    .then((res: AxiosResponse<IJobInfoDTO[]>) => res.data);
  return jobs;
};
const getEmployerJob = async (jobId: string) => {
  const job = await oAuth()
    .axios.get(`/job/short-info/${jobId}`)
    .then((res: AxiosResponse<IJobDetailsDto>) => res.data);
  return job;
};

const browseJobs = (params: IBrowseJobQuery) => {
  const query = qs.stringify(params, {
    arrayFormat: 'repeat',
  });

  return oAuth()
    .axios.get(`/job/browse${query ? `?${query}` : query}`)
    .then((res: AxiosResponse<IInfiniteScrollPaginationResponse<IBrowseJobDTO>>) => res.data);
};

const getCandidateAvailableJobs = (params: ICandidateDataQuery) => {
  const jobs = oAuth()
    .axios.get('/job/candidate-available', { params })
    .then((res: AxiosResponse<IJobShortInfoDto[]>) => res.data);

  return jobs;
};

const getJob = (jobId: string) =>
  oAuth()
    .axios.get(`/job/${jobId}`)
    .then((res: AxiosResponse<IJobDetailsDto>) => res.data);

const updateData = ({
  id,
  data,
  isDraft,
  employerUserId,
}: {
  id: string;
  data: IPostJobResult;
  isDraft: boolean;
  employerUserId?: string;
}) => {
  const requestData = requestCreateJob(data, isDraft, employerUserId);
  return oAuth().axios.put(`/job/${id}`, requestData);
};

/**
 * Fetch available jobs for matches
 *
 * @param params IAdminMatchesAvailableQuery
 * @returns IBrowseJobDTO[]
 */
const getAdminJobsForMatches = (params: IAdminMatchesAvailableJobsQuery) => {
  const jobs = oAuth()
    .axios.get('/admin/matches/available-jobs', { params })
    .then((res: AxiosResponse<IBrowseJobDTO[]>) => res.data);

  return jobs;
};

const archiveJob = (jobId: string) => {
  const jobs = oAuth()
    .axios.put(`/job/${jobId}/archive`)
    .then((res: AxiosResponse<ISuccessResponse>) => res.data);

  return jobs;
};

const postBasicInfo = (data: IBasicInfoRequest): Promise<IPostJobResponse> => {
  return oAuth()
    .axios.post('/job/post/basic-info', data)
    .then((res: AxiosResponse<IJobAttributes>) => ({
      jobId: res.data.id,
    }));
};

const updateBasicInfo = (data: IUpdateBasicInfoRequest): Promise<IPostJobResponse> => {
  return oAuth()
    .axios.put('/job/post/basic-info', data)
    .then((res: AxiosResponse<IJobAttributes>) => ({
      jobId: res.data.id,
    }));
};

const adminPostBasicInfo = (data: IAdminBasicInfoRequest): Promise<IPostJobResponse> => {
  return oAuth()
    .axios.post<IJobAttributes>('/admin/jobs/post/basic-info', data)
    .then((res) => ({
      jobId: res.data.id,
    }));
};

const adminUpdateBasicInfo = (data: IUpdateBasicInfoRequest): Promise<IPostJobResponse> => {
  return oAuth()
    .axios.put<IJobAttributes>('/admin/jobs/post/basic-info', data)
    .then((res) => ({
      jobId: res.data.id,
    }));
};

const updateDesiredSkills = async (data: IDesiredSkillsRequest): Promise<IPostJobResponse> => {
  await oAuth().axios.put('/job/post/desired-skills', data);

  return {
    jobId: data.jobId,
  };
};

const updateJobDescription = (data: IJobDescriptionRequest): Promise<IPostJobResponse> => {
  return oAuth()
    .axios.put('/job/post/job-description', data)
    .then((res: AxiosResponse<IJobAttributes>) => ({
      jobId: res.data.id,
    }));
};

const updateCompensation = (data: ICompensationRequest): Promise<IPostJobResponse> => {
  return oAuth()
    .axios.put('/job/post/compensation', data)
    .then((res: AxiosResponse<IJobAttributes>) => ({
      jobId: res.data.id,
    }));
};

const updateInternalInfo = (data: IInternalInfoRequest): Promise<IPostJobResponse> => {
  return oAuth()
    .axios.put('/job/post/internal-info', data)
    .then((res: AxiosResponse<IJobAttributes>) => ({
      jobId: res.data.id,
    }));
};

const publishJob = (jobId: string) => {
  const query = qs.stringify({ jobId });

  return oAuth()
    .axios.put(`/job/post/publish?${query}`)
    .then((res: AxiosResponse<IJobAttributes>) => res.data);
};

type PostJobStepProcessor<T extends PostJobRequests> = (
  data: IPostJobRequestData<T>,
) => Promise<IPostJobResponse>;

// Implementation of "Strategy" design pattern for post job requests
const postStepsMapping = {
  [PostJobRequests.BasicInfo]: postBasicInfo,
  [PostJobRequests.UpdateBasicInfo]: updateBasicInfo,
  [PostJobRequests.AdminBasicInfo]: adminPostBasicInfo,
  [PostJobRequests.AdminUpdateBasicInfo]: adminUpdateBasicInfo,
  [PostJobRequests.DesiredSkills]: updateDesiredSkills,
  [PostJobRequests.JobDescription]: updateJobDescription,
  [PostJobRequests.Compensation]: updateCompensation,
  [PostJobRequests.InternalInfo]: updateInternalInfo,
} as const;

const postJobData = (data: IPostJobData) => {
  const processor = postStepsMapping[data.type] as PostJobStepProcessor<typeof data.type>;
  return processor(data.data);
};

export default {
  updateData,
  browseJobs,
  getEmployerJobs,
  getEmployerJob,
  getJob,
  getCandidateAvailableJobs,
  getAdminJobsForMatches,
  archiveJob,
  postJobData,
  publishJob,
};
