import { CandidateDegree } from '@/enums';

export const degreeOptions = [
  { label: 'Associate', value: CandidateDegree.Associate },
  { label: 'Bachelor', value: CandidateDegree.Bachelor },
  { label: 'MBA', value: CandidateDegree.MBA },
  { label: 'Master (non-MBA)', value: CandidateDegree.Master },
  { label: 'Doctorate', value: CandidateDegree.Doctorate },
  { label: 'Certificate', value: CandidateDegree.Certificate },
  { label: 'High School', value: CandidateDegree.HighSchool },
  { label: 'Other', value: CandidateDegree.Other },
];
