import { makeStyles } from '@material-ui/core';

export const headerHeight = {
  mobile: 64,
  tablet: 74,
  desktop: 80,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: headerHeight.mobile,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.up('sm')]: {
      height: headerHeight.tablet,
      zIndex: 'initial',
    },
    [theme.breakpoints.up('lg')]: {
      height: headerHeight.desktop,
    },
  },
  fixed: {
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      position: 'static',
    },
  },
  backBtn: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  wrapper: {
    maxWidth: '1440px',
    height: '100%',
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(0, 10),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 20),
    },
  },
  wrapperFullWidth: {
    maxWidth: '100%',
  },
  controlButtonsSection: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  controlButtonItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    opacity: 0.75,
    height: '100%',
    padding: theme.spacing(0, 5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7.5),
    },
    '&:hover': {
      opacity: 1,
      '&:after': {
        transform: 'scaleX(1)',
      },
    },
    '&:after': {
      marginTop: theme.spacing(-2),
      display: 'block',
      content: '""',
      borderBottom: `3px solid ${theme.palette.secondary.light}`,
      transform: 'scaleX(0)',
      transformOrigin: '0% 50%',
      transition: 'transform 250ms ease-in-out',
    },
  },
  customControlButtonItem: {
    '&:after': {
      border: 'none',
    },
  },
  selectedControlButtonItem: {
    color: theme.palette.mainBlue,
    opacity: 1,
    '&:after': {
      display: 'block',
      content: '""',
      borderBottom: `3px solid ${theme.palette.lightBlue}`,
      transform: 'scaleX(1)',
    },
  },
  controlButton: {
    height: '100%',
    padding: theme.spacing(0, 5),
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: 'none',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7.5),
    },
  },
  customControlButton: {
    height: 'fit-content',
    padding: theme.spacing(5, 14),
  },
  pfofilePopover: {
    display: 'flex',
    marginLeft: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(7.5),
    },
  },
  notification: {
    backgroundColor: theme.palette.mainBlue,
    borderRadius: '50%',
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: 'absolute',
    right: theme.spacing(0),
    top: `calc(50% + ${theme.spacing(-2)}px)`,
    transform: 'translateY(-50%)',
  },
  logo: {
    cursor: 'pointer',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': { textDecoration: 'none' },
    color: theme.palette.blackMain,
  },
}));

export default useStyles;
