import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  customLink: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
}));

export default useStyles;
