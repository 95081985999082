export const MAX_SALARY = 2147483646;
export const MIN_SALARY_RANGE = 0;
export const POST_JOB_MIN_SALARY_RANGE = 30;
export const CURRENT_SALARY_RANGE = 98;
export const MAX_SALARY_RANGE = 200;
export const SALARY_STEP = 5;
export const WORKING_HOURS_PER_MONTH = 168;
export const WORKING_HOURS_PER_YEAR = 2016; // 168 hours * 12 month
export const DEFAULT_COMPENSATION_HOURLY_RATE = 1;

export const DEFAULT_COMPENSATION_ANNUAL_RATE =
  WORKING_HOURS_PER_YEAR * DEFAULT_COMPENSATION_HOURLY_RATE;

export const SALARY_MARKS = [
  { value: MIN_SALARY_RANGE, label: `$${MIN_SALARY_RANGE}` },
  { value: MAX_SALARY_RANGE, label: `$${MAX_SALARY_RANGE}+` },
];

export const POST_JOB_SALARY_MARKS = [
  { value: POST_JOB_MIN_SALARY_RANGE, label: `$${POST_JOB_MIN_SALARY_RANGE}` },
  { value: MAX_SALARY_RANGE, label: `$${MAX_SALARY_RANGE}+` },
];
