import { IJobDetailsDto } from '@/DTO';
import { IJobFilters } from '@/requests';
import { createSlice } from '@reduxjs/toolkit';
import { IActionWithPayload, IBrowseJobResult, IBrowseJobState } from '../types';

const initialState: IBrowseJobState = {
  jobs: [],
  isLoading: true,
  hasMore: true,
  filters: {},
  page: 1,
  perPage: 10,
  detailedJob: null,
  isLoadingJobDetail: false,
};

export const browseJobSlice = createSlice({
  name: 'browse-job',
  initialState,
  reducers: {
    addJobs: (state, action: IActionWithPayload<IBrowseJobResult>) => {
      state.hasMore = action.payload.hasMore;
      state.isLoading = false;
      if (action.payload.data.length) {
        // Handle pagination offsets to prevent duplication of job cards
        const existingJobIdSet = new Set(state.jobs.map((job) => job.id));
        state.jobs.push(...action.payload.data.filter((job) => !existingJobIdSet.has(job.id)));
        state.page += 1;
      }
    },
    storeFilter: (state, { payload }: IActionWithPayload<IJobFilters>) => {
      state.filters = payload;
      state.jobs = [];
      state.hasMore = true;
      state.page = 1;
      state.perPage = 10;
    },
    loadJobsFailed: (state) => {
      state.hasMore = false;
      state.isLoading = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    markJobApplied: (state) => {
      if (!state.detailedJob) {
        return;
      }

      state.detailedJob.isApplied = true;
    },
    clearJobs: (state) => {
      state.jobs = [];
      state.hasMore = true;
      state.page = 1;
      state.perPage = 10;
      state.filters = {};
    },
    storeDetailedJob: (state, { payload }: IActionWithPayload<IJobDetailsDto>) => {
      state.detailedJob = payload;
      state.isLoadingJobDetail = false;
    },
    clearDetailedJob: (state) => {
      state.detailedJob = null;
    },
    startLoadingDetailedJob: (state) => {
      state.isLoadingJobDetail = true;
    },
    stopLoadingDetailedJob: (state) => {
      state.isLoadingJobDetail = false;
    },
  },
});

export const {
  addJobs,
  loadJobsFailed,
  startLoading,
  markJobApplied,
  clearJobs,
  storeFilter,
  storeDetailedJob,
  clearDetailedJob,
  startLoadingDetailedJob,
  stopLoadingDetailedJob,
} = browseJobSlice.actions;

export default browseJobSlice.reducer;
