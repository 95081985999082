import oAuth from '@DevimaSolutions/o-auth';

import { AxiosResponse } from 'axios';
import { IAdminMatchesAvailableCandidatesQuery, IBrowseJobQuery } from '@/requests';
import { IBrowseCandidateResponse } from '@/responses';
import { IBrowseCandidateDto, ICandidateDetailsDto } from '@/DTO';

const browseCandidates = (params: IBrowseJobQuery) =>
  oAuth()
    .axios.get('/candidate/browse', { params })
    .then((res: AxiosResponse<IBrowseCandidateResponse>) => res.data);

const getCandidate = (id: string) =>
  oAuth()
    .axios.get(`/candidate/${id}`)
    .then((res: AxiosResponse<ICandidateDetailsDto>) => res.data);

const getAdminCandidatessForMatches = (params: IAdminMatchesAvailableCandidatesQuery) => {
  const jobs = oAuth()
    .axios.get('/admin/matches/available-candidates', { params })
    .then((res: AxiosResponse<IBrowseCandidateDto[]>) => res.data);

  return jobs;
};

export default {
  browseCandidates,
  getCandidate,
  getAdminCandidatessForMatches,
};
